import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints";

export const BasicButton = styled.button`
  padding: 0;
  height: 60px;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 75%;
  max-width: 230px;
  border: 3px solid rgb(11, 158, 206);
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "rgb(255, 255, 255)"};
  color: rgb(11, 158, 206);
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  justify-items: center;
  align-self: center;
  justify-self: end;
  text-decoration: none;
  grid-column: span 2;
  position: relative;
  letter-spacing: 1px;
  ${(props) => props.alignRight && "grid-column-end: -1;align-self: end;"}
  ${(props) => props.alignCenter && "align-self: center;"}
	:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    ${(props) =>
      props.checked ? "background-color: rgba(125, 125, 125, 0.2);" : ""}
  }
  :hover {
    ${(props) => (props.disabled ? "" : "cursor: pointer;")}
  }
  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
    width: 100%;
    height: 54px;
    font-size: 16px;
  }

  @media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xlg}px) {
    width: 85%;
  }
`;
