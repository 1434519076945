import styled from "styled-components";

export const PopUpMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  border-radius: 22px;
  border: ${(props) => "3px solid " + props.borderColor || "none"};
  padding: 5px;
  position: absolute;
  top: 12vh;
  left: 0.5vw;
  width: 7vw;
  background-color: white;
  visibility: ${(props) => (props.visibile ? "visible" : "hidden")};

  p {
    font-size: 12px;
    margin: 0px;
  }
`;
