import { useEffect, useState } from "react";

/**
 * @component
 * @description handler for extracting checkboxInfo data for certain page; divides data into Full-time and Student arrays and enables text updates
 * @param  {array} data CheckboxInfo data for the current page; consist of multiple checkboxInfos for both Full-time and Student user types
 */
export const useCheckboxTextHandler = (data) => {
  if (!data) {
    throw new Error("No data received");
  }

  const [checkboxInfoData, setCheckboxInfoData] = useState([]);

  useEffect(() => {
    setCheckboxInfoData(prepareData());
  }, [data]);

  const handleCheckboxInfoTextChange = (text, userType, index) => {
    let newCheckboxInfoData = checkboxInfoData;
    newCheckboxInfoData[index][userType].text = text;
    setCheckboxInfoData(newCheckboxInfoData);
  };

  const prepareData = () => {
    const numOfTextFragments = new Set(
      data.map((checkbox) => checkbox.orderOnPage)
    ).size;
    const preparedData = new Array(numOfTextFragments)
      .fill(null)
      .map(() => ({ FULLTIME: "", STUDENT: "" }));
    data.forEach((checkboxInfo) => {
      preparedData[checkboxInfo.orderOnPage - 1][checkboxInfo.userType] =
        checkboxInfo;
    });
    return preparedData;
  };

  const getCheckboxInfoData = () => {
    return checkboxInfoData;
  };

  if (data) {
    return {
      getCheckboxInfoData,
      handleCheckboxInfoTextChange,
    };
  }
};
