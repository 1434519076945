import styled from "styled-components";

export const ScrollContainer = styled.div`
  overflow: hidden auto;
  max-height: ${(props) => props.maxHeight || "65vh"};
  place-self: stretch;
  padding: ${(props) => props.padding || "0 50px 0 20px"};
  margin-left: ${(props) => props.marginLeft || "none"};
  margin-top: 1em;
  margin-bottom: ${(props) => props.marginBottom || "1em"};
  ${(props) =>
    props.gridView ? "display:grid; grid-template-columns: 10fr 1fr;" : ""};
  &::-webkit-scrollbar {
    background: ${(props) =>
      props.backgroundColor || "rgba(196, 196, 196, 0.2)"};
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(196, 196, 196, 0.5);
    border: 3px solid rgba(0, 0, 0, 0.5);
    border-radius: 14px;
  }
`;
