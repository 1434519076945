import styled from "styled-components";

export const VerticalButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2vh;
  padding-top: 0;
  font-size: 1.3em;

  button {
    cursor: pointer;
  }
`;
