import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { ACCESS_TOKEN } from "../../constants/Constants";
import { UserContext } from "../../utils/context/UserContext";
import routerUrls from "../../utils/routerUrls.js";
import {
  createImageFromInitials,
  getRandomColor,
} from "../../utils/useImageCreationHandler.js";
import { StyledHeader } from "./StyledHeader";

/**
 * @component
 * @description Component renders Header with profile picture created from users initials
 */
const Header = () => {
  const userData = useContext(UserContext);

  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
  };

  return (
    <StyledHeader>
      <img
        id="preview"
        src={createImageFromInitials(100, userData.name, getRandomColor())}
        alt="profile-pic"
      />
      <Dropdown>
        <Dropdown.Toggle
          size={"lg"}
          style={{
            color: "rgb(0,0,0,0.65)",
            backgroundColor: "white",
            border: "0px",
          }}
        />
        <Dropdown.Menu>
          <Dropdown.Item
            href={routerUrls.userProfilePage.route.replace(
              ":userId",
              userData.id
            )}
          >
            User profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href={"/"} onClick={() => logout()}>
            Log out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </StyledHeader>
  );
};

export default Header;
