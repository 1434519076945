import { BaseLayout } from "../../components";
import React from "react";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper";
import PropTypes from "prop-types";
import TextContainer from "../../components/text-editor/TextContainer.js";

/**
 * @component
 * @description Component renders Miro page
 * @param  {string} to path to next page
 * @param  {string} from  path to previous page
 * @param  {Array} data CheckboxInfo data for the current page
 * @param  {Object} stepperData stepper pages data; consist of lists of page titles and page routes
 * @param  {object} userData user data
 * @param  {function} updateCheckboxInfoData send updated checkboxInfo data to BE
 */
const MiroPage = ({
  to,
  from,
  data,
  stepperData,
  userData,
  updateCheckboxInfoData,
}) => {
  return (
    <BaseLayout to={to} from={from} hasTextEditor={true} userData={userData}>
      <HorizontalLinearStepper
        stepTitles={stepperData.titles}
        stepRoutes={stepperData.routes}
        currentStep={data[0].orderOnStepper - 1}
      >
        <TextContainer
          userType={userData.userType}
          data={data}
          updateCheckboxInfoData={updateCheckboxInfoData}
        />
      </HorizontalLinearStepper>
    </BaseLayout>
  );
};

MiroPage.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string,
  data: PropTypes.array.isRequired,
  stepperData: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  updateCheckboxInfoData: PropTypes.func.isRequired,
};

export default MiroPage;
