import React, { useState } from "react";
import { StyledAddUserForm } from "./StyledAddUserForm";
import plus from "../../assests/images/plus.svg";
import PropTypes from "prop-types";

/**
 *
 * @Component
 * @description Renders add user from
 *
 * @param  {function} addUserHandler handler for adding users
 */
const AddUserForm = ({ addUserHandler }) => {
  const [emailValue, setEmailValue] = useState("");

  const inputOnChangeHandler = (event) => {
    setEmailValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addUserHandler(emailValue);
    setEmailValue("");
  };

  return (
    <StyledAddUserForm onSubmit={handleSubmit}>
      <label htmlFor="emailInput">Add new user</label>
      <div>
        <input
          type="email"
          pattern=".+@comsysto.com"
          required
          placeholder="name.surname@comsysto.com"
          name="emailInput"
          id="emailInput"
          value={emailValue}
          onChange={(event) => {
            inputOnChangeHandler(event);
          }}
        />
        <button type="submit">
          <img src={plus} alt="" />
        </button>
      </div>
    </StyledAddUserForm>
  );
};

AddUserForm.propTypes = {
  addUserHandler: PropTypes.func.isRequired,
};
export default AddUserForm;
