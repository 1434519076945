import React from "react";
import { StyledLink } from "./StyledRouteBtn";
import PropTypes from "prop-types";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";

/**
 * @component
 * @description Component used as a back or next button
 * @param  {number} dir Number used to determint if the button is a back or next button
 * @param  {string} to Path to a page
 * @param  {function} onClick callback function when clicked on button
 */
const RouteButton = ({ dir, to, onClick }) => (
  <StyledLink
    dir={dir}
    to={to}
    onClick={() => {
      onClick && onClick();
      scrollTo({ top: 0 });
    }}
  >
    {dir === 0 ? (
      <>
        <BiLeftArrowAlt />
        <span> Back</span>
      </>
    ) : (
      <>
        <span>Next </span>
        <BiRightArrowAlt />
      </>
    )}
  </StyledLink>
);

RouteButton.propTypes = {
  dir: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default RouteButton;
