import React from "react";
import { StyledErrorBoundary } from "../../utils/errorBoundary/StyledErrorBoundary";
import Link from "@material-ui/core/Link";
import routerUrls from "../../utils/routerUrls";
import cody from "../../assests/images/cody.png";

const ErrorMessage = ({ fullscreen }) => (
  <StyledErrorBoundary>
    <h1>
      We're sorry, but an error has occurred and we're working on it. Refresh
      the page or go <Link to={routerUrls.introPage.route}>back</Link>
    </h1>
    {fullscreen ? <img src={cody} alt="cody" /> : null}
    <h2>If this keeps happening, please contact info.zg@reply.de.</h2>
  </StyledErrorBoundary>
);

export default ErrorMessage;
