import React from "react";
import { Header, LinkButton } from "../../components";
import { StyledBaseLayout } from "../../components/base-layout/StyledBaseLayout";
import { Main } from "..";
import { StyledFooter } from "../../components/footer/StyledFooter";
import PropTypes from "prop-types";
import { ScrollContainer } from "../scroll-container/ScrollContainer";

/**
 * @Component
 * @Description renders Check checkboxes page the logic behind accessing it is implementend in Onboarding.js
 * @param  {function} handleHamburgerClick Hamburger on click handler
 * @param  {function} handleClick Understood button click handler
 */

const CheckedCheckboxPage = ({ handleHamburgerClick, handleClick }) => (
  <StyledBaseLayout>
    <Header handleHamburgerClick={handleHamburgerClick} />
    <div />
    <Main allignLastPRight={true}>
      <ScrollContainer>
        <h2>Welcome</h2>
        <p>
          <b>
            Your onboarding is now officially over. You can come back and
            re-read the info in this app at any time. Also, if you have any
            further questions regarding the tasks and info during your
            onboarding, feel free to ask our office managers.
          </b>
        </p>{" "}
        <p>
          <b>
            Hopefully this experience went smoothly. If you have any feedback at
            all, let us know. We appreciate any comments that help us improve.
            :)
          </b>
        </p>
        <p>
          <b>We hope for a great collaboration!</b>
        </p>
        <p>
          <b> Your Comsysto Reply team</b>
        </p>
      </ScrollContainer>
    </Main>
    <div />
    <StyledFooter>
      <LinkButton
        maxWidthNone={true}
        dir={1}
        colSpan={5}
        onClick={() => {
          handleClick();
        }}
      >
        Thanks! Looking forward to working with you! :)
      </LinkButton>
    </StyledFooter>
  </StyledBaseLayout>
);
CheckedCheckboxPage.propTypes = {
  handleHamburgerClick: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default CheckedCheckboxPage;
