import styled from "styled-components";

export const ContentContainerData = styled.div`
  width: 75%;
  border: 3px solid ${(props) => props.borderColor || "rgb(30,159,204)"};
  border-radius: 1.5em / 1.5em;
  margin-top: 3vh;
  padding: 0 2rem;
  background-color: ${(props) =>
    props.checkedState ? props.borderColor : "transparent"};
  transition: background-color 1s;
  cursor: pointer;
`;
