import { ContentContainer, ScrollContainer } from "..";
import PropTypes from "prop-types";
import React from "react";
import { styles } from "../../constants/Constants";
import Accordion from "../../components/accordion/Accordion";
import { useTranslation } from "react-i18next";

const EmployeeProgress = ({
  countCheckboxesOfSections,
  mapCheckboxesToSections,
}) => {
  const { t } = useTranslation();

  const sections = [
    {
      name: t("employee-progress.first-day-name"),
      nameForDisplay: t("employee-progress.first-day-display"),
    },
    {
      name: t("employee-progress.first-week-name"),
      nameForDisplay: t("employee-progress.first-week-display"),
    },
    {
      name: t("employee-progress.other-info-name"),
      nameForDisplay: t("employee-progress.other-info-display"),
    },
  ];

  return (
    <ContentContainer>
      <ScrollContainer
        mainColor={styles.container.color.darkerBlue}
        maxHeight={"65vh"}
      >
        {sections.map((section, i) => {
          return (
            <Accordion
              key={i}
              title={section.nameForDisplay}
              childrenLength={countCheckboxesOfSections(section.name)}
            >
              {mapCheckboxesToSections(section.name)}
            </Accordion>
          );
        })}
      </ScrollContainer>{" "}
    </ContentContainer>
  );
};

EmployeeProgress.propTypes = {
  countCheckboxesOfSections: PropTypes.func.isRequired,
  mapCheckboxesToSections: PropTypes.func.isRequired,
};
export default EmployeeProgress;
