import React from "react";
import { StyledHamburger } from "./StyledHamburger";
import PropTypes from "prop-types";

/**
 * @component
 * @description Renders Hamburger Menu
 * @param  {function} handleClick Click handler
 */
const Hamburger = ({ handleClick }) => (
  <StyledHamburger onClick={() => handleClick()}>
    <span />
    <span />
    <span />
  </StyledHamburger>
);

Hamburger.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default Hamburger;
