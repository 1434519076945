import { ContentContainer, ScrollContainer } from "..";
import React, { useState } from "react";
import { adminUrls, baseBackendUrl } from "../../utils/baseUrl";
import downloadFileFromEndpoint from "../../utils/downloadFileFromEndpoint";
import { CheckboxContainer } from "../checkbox-container/CheckboxContainer";
import BlueCheckbox from "../../components/checkbox/BlueCheckbox";
import { ContentContainerData } from "../content-container/ContentContainerData";
import { styles } from "../../constants/Constants";
import { ButtonContainer } from "../button-container/ButtonContainer";
import { StyledBasicRoundButton } from "../../components/buttons/StyledBasicRoundButton";
import ModalWindow from "../../components/modal-window/ModalWindow";
import { useTranslation } from "react-i18next";

const EmployeeDocuments = ({
  documents,
  handleDocumentsCheck,
  documentRejectMessageValue,
  handleDocumentRejectTextChange,
  handleDocumentsRejection,
  toggleAlert,
}) => {
  const [err, setErr] = useState();
  let [rejectModalIsOpen, setRejectModalIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleRejectModal = () => {
    setRejectModalIsOpen(!rejectModalIsOpen);
  };

  const handleDownload = (fileName, fileType) => {
    try {
      downloadFileFromEndpoint(
        `${baseBackendUrl}/${adminUrls.downloadDocumentUrl}/${fileName}`.replace(
          ":fileType",
          fileType
        ),
        fileName,
        toggleAlert
      );
    } catch (err) {
      setErr(err);
    }
  };

  const handleReject = () => {
    handleDocumentsRejection();
    toggleRejectModal();
  };

  const textAreaStyle = {
    backgroundColor: "white",
    borderColor: "rgba(161,221,217,0.62)",
    borderWidth: "3px",
    borderRadius: "15px",
    minWidth: "90%",
    marginLeft: "1em",
    marginRight: "1em",
    fontSize: "1.2em",
  };

  const rejectModalBody = (
    <div style={{ width: "100%" }}>
      <ul>
        {documents.map((document, i) => {
          if (document.checked === true) {
            return (
              <li key={i}>
                <b>{document.filename}</b>
              </li>
            );
          }
        })}
      </ul>
      <textarea
        style={textAreaStyle}
        value={documentRejectMessageValue}
        onChange={handleDocumentRejectTextChange}
      />
    </div>
  );

  return (
    <ContentContainer>
      <ScrollContainer
        mainColor={"rgb(28,149,197)"}
        backgroundColor={"#ffffff"}
        borderColor={"rgb(115,215,217)"}
      >
        {documents.map((document, i) => {
          return (
            <CheckboxContainer key={i}>
              <BlueCheckbox
                id={i}
                checkedState={document.checked}
                handleClick={() => handleDocumentsCheck(document.id)}
              />
              <ContentContainerData
                checkedState={document.checked}
                style={{ fontSize: "1.5vw", textAlign: "center" }}
                borderColor={styles.container.color.lightBlue}
                onClick={() =>
                  handleDownload(document.filename, document.fileType)
                }
                title={document.filename}
                key={i}
              >
                <b>{document.filename}</b>
              </ContentContainerData>
            </CheckboxContainer>
          );
        })}
      </ScrollContainer>

      <ButtonContainer>
        <StyledBasicRoundButton
          marginTop={"2.5em"}
          onClick={toggleRejectModal}
          hidden={documents.length === 0}
          backgroundColor={"rgb(255,255,255)"}
        >
          {t("employee-documents.reject")}
        </StyledBasicRoundButton>
      </ButtonContainer>
      <ModalWindow
        headerText={<h5> {t("employee-documents.explanation")}</h5>}
        isOpen={rejectModalIsOpen}
        hideModal={toggleRejectModal}
        toDoIfConfirmed={() => handleReject()}
        body={rejectModalBody}
        cancelText={t("employee-documents.cancel")}
        confirmText={t("employee-documents.confirm")}
      />
    </ContentContainer>
  );
};

export default EmployeeDocuments;
