import teamgeist_logo from "../assests/images/teamgeist_logo.png";
import cicd_toolchain_logo from "../assests/images/cicd_toolchain_logo.png";
import end2end_diagnostics_logo from "../assests/images/End2End_diagnostics_logo.png";
import mdk_logo from "../assests/images/mobile_device_key_logo.png";
import vdc_logo from "../assests/images/vehicle_data_collection_logo.png";
import vrs_logo from "../assests/images/vehicle_recalling_system_logo.png";

export const homePageInfo = [
  {
    projectGroup: [
      {
        title: "Teamgeist",
        description:
          "Teamgeist is an app for the work environment of the 21st century. A world in which distributed teams work self-dependently" +
          "with flexible hours and flat hierarchies.\nThere are many reasons why team members may be absent, such as home office, vacation, sickness, education & training, or parental leave.\n" +
          "With teamgeist.io, teams can coordinate their collaboration - simply and fast. No more spreadsheets, rules and bureaucracy. Let teamgeist.io take care of absence planning and reclaim your time" +
          " for what's important to you. Whether in your company, at university, in projects or teams of freelancers: teamgeist.io democratizes collaboration and makes it more productive.",
        imageSrc: { teamgeist_logo },
        descriptionHtml:
          "<h3><strong>Use case</strong></h3>\n" +
          "<blockquote><em>With Teamgeist, teams can coordinate their collaboration - simply and fast. No more spreadsheets, rules and bureaucracy. Let Teamgeist take care of absence planning and reclaim your time for what’s important to you. Whether in your company, at university, in projects or teams of freelancers: Teamgeist democratizes collaboration and makes it more productive.</em></blockquote>\n" +
          "<h3><strong>Challenges</strong></h3>\n" +
          "<blockquote>Eliminating spreadsheets and bureaucracy is always a challange on organizational level. The challange of Teamgeist is to make absence management easier than ever. Book time off without long approval processes and call in sick with the click of a button. Teamgeist automatically notifies your team members and HR about your absence to minimize coordination efforts, by good old email or Slack.</blockquote>\n" +
          "<h3><strong>Solution</strong></h3>\n" +
          "<blockquote>Teamgeist is an app for the work environment of the 21st century. A world in which distributed teams work self-dependently, with flexible hours and flat hierarchies. There are many reasons why your team members may be absent, such as home office, vacation, sickness, education &amp; training, or parental leave.</blockquote>\n",
      },
      {
        title: "End-2-End diagnostics",
        description:
          "The system ''End-2-End diagnostics'' (E2E-D) allows the categorization of all mobile service calls and their results through saving, processing, interpreting, visualizing and making them searchable.\n" +
          "The solution is based on Java/Spring while using highly scalable, real-time capable Data Management technologies like Cassandra and MongoDB.",
        imageSrc: { end2end_diagnostics_logo },
        descriptionHtml:
          "<h3><strong>Use case</strong></h3>\n" +
          "<blockquote><em>Large scale roll out and increased usage of connected vehicles requires deep insights in the functions and communication with the Cloud backend. The goal is to properly inform the driver or service center agent about possible errors to be able to act. The solution E2E diagnostics gathers, processes and interpretes information from car online interface calls. It also makes this information understandable for humans like call center agents.</em></blockquote>\n" +
          "<h3><strong>Challenges</strong></h3>\n" +
          "<blockquote>The solution will process vast amount of data as the fleet of connected cars is rapidly growing. The key to success were 2 points: ability to connect new data sources very quickly, find an appropriate cloud-based data infrastructure and implement real-time processing application at scale, provide accurate and easily understandable visualisation in the UI and react upon not registered and thus at that moment not known errors.</blockquote>\n" +
          "<h3><strong>Solution</strong></h3>\n" +
          "<blockquote>The system „End-2-End diagnostics“ (E2E-D) allows the categorization of all mobile service calls and their results through saving, processing, interpreting, visualizing and making them searchable. The solution is based on Java/Spring while using highly scalable, real-time capable Data Management technologies like Cassandra and MongoDB.</blockquote>\n" +
          "<h3><strong>Key facts</strong></h3>\n" +
          "<blockquote>Highly scalable data ingestion and data processing capabilities Flexible integration of new data sources Combination of deterministic rules and stochastic approach with ML</blockquote>\n",
      },
      {
        title: "Mobile Device Key",
        description:
          "In the current digital era where more and more things are used on our smart devices, cars are no exception.\nUse of the conventional car keys as we know it will become obsolete " +
          "and the digital representation of the key will take its place. Within the CCC - Car Connectivity Consortium https://carconectivity.org/ a framework and interface concept for the implementation of the MDK project was created.",
        descriptionHtml:
          "<h3><strong>Use case</strong></h3>\n" +
          "<blockquote><em>In the current digital era where more and more things are used on our smart devices, cars are no exception. Use of the conventional car keys as we know it will become obsolete and the digital representation of the key will take its place. Within the CCC - Car Connectivity Consortium https://carconnectivity.org/ a framework and interface concept for the implementation of the MDK project was created.</em></blockquote>\n" +
          "<h3><strong>Challenges</strong></h3>\n" +
          "<blockquote>Crucial for the success of the solution is the security of the architecture and the user’s full control over his keys. With the digital key, we are extending the comfort of the vehicles. Because the Smartphone is our personal companion, it is the perfect device for this purpose. With the Smartphone, the vehicle can be driven completely without a conventional vehicle key can be used.</blockquote>\n" +
          "<h3><strong>Solution</strong></h3>\n" +
          "<blockquote>Digital keys are to be stored on the smartphone. Several keys can be stored on the smartphone for this purpose.</blockquote>\n" +
          "<blockquote>Main features:</blockquote>\n" +
          "<ul>\n" +
          "<li>Use of the smartphone as a vehicle key</li>\n" +
          "<li>Allocation of keys to friends and family</li>\n" +
          "<li>Remote key transfer</li>\n" +
          "</ul>\n" +
          "<h3><strong>Key facts</strong></h3>\n" +
          "<ul>\n" +
          "<li>Conventional vehicle key does not have to be present</li>\n" +
          "<li>Flexible management of digital keys via smartphone or Web App</li>\n" +
          "<li>Opening up further possibilities for Car Sharing and fleet management</li>\n" +
          "</ul>\n",
        imageSrc: { mdk_logo },
      },
    ],
  },
  {
    projectGroup: [
      {
        title: "Vehicle Data Collection",
        description:
          "The international automotive manufacturer has successfully rolled out a new, individual, scalable solution based on open source technologies with Comsysto Reply as a partner. Thus a 30-year-old legacy application for recall processes could be replaced by a modern web application that meets today’s standards of user experience, design, security and traffic.",
        imageSrc: { vdc_logo },
        descriptionHtml:
          "<h3><strong>Use case</strong></h3>\n" +
          "<blockquote><em>Our customer offers a wide range of value added services for its clients, which are based on information collected by the vehicle. The acquired data is then transmitted from the car to back-end services and can describe e.g. the vehicle‘s state itself, the traffic condition the vehicle finds itself in, or the vehicle‘s environment. New data is continuously used to improve prediction algorithms.</em></blockquote>\n" +
          "<h3><strong>Challenges</strong></h3>\n" +
          "<blockquote>New models with additional sensor information, the increase in vehicle registrations itself, as well as the increase in frequency for sending data in order to provide more accurate predictions are just some reasons why the number of requests increases exponentially in the future. This leads to the challenge that legacy systems and architectures are not capable of meeting the technical demands in terms of scalability, availability and resilience anymore.</blockquote>\n" +
          "<h3><strong>Solution</strong></h3>\n" +
          "<blockquote>The existing legacy microservices are ported to an on-premise Openshift cluster, and AWS based on microservice architecture bit by bit. Comsyto Reply enables this transformation by providing specific AWS architecture Know-How in combination with domain knowledge. This provides future-proof services and databases that easily scale with the increasing demands.</blockquote>\n" +
          "<h3><strong>Key facts</strong></h3>\n" +
          "<ul>\n" +
          "<li>Data coming from over 2,300,000 vehicles</li>\n" +
          "<li>~ 13,000,000,000 calls per day</li>\n" +
          "<li>Scalable micro services architecture running on EU, US and China</li>\n" +
          "</ul>\n",
      },
      {
        title: "CI/CD Toolchain",
        description:
          "Comsysto Reply is working with a big German automotive customer on the design, architecture and Implementation of a cloud-based Continuous Integration/Continuous Delivery Toolchain in order to automate testing and simulation of vehicle software components and thus drastically shorten vehicle development cycles.",
        imageSrc: { cicd_toolchain_logo },
        descriptionHtml:
          "<h3><strong>Use case</strong></h3>\n" +
          "<blockquote><em>Comsysto Reply is working with a big German automotive customer on the design, architecture and Implementation of a cloud-based Continuous Integration/Continuous Delivery Toolchain in order to automate testing and simulation of vehicle software components and thus drastically shorten vehicle development cycles.</em></blockquote>\n" +
          "<h3><strong>Challenges</strong></h3>\n" +
          "<p>Modern cars have between 6,000,000 to 10,000,000 lines of code built into their various controllers. From brakes to engine and suspension to steering wheel, all those modules process inputs and give outputs to other modules in order to make driving safer, easier and more comfortable. Each hardware module is also represented as a software model so that it can be tested using Matlab and Simulink.</p>\n" +
          "<h3><strong>Solution</strong></h3>\n" +
          "<blockquote>The project goal is to automate the processes and procedures described before so that knowledge about pipeline generation and execution is built into a pipeline itself. Additionally we need to shorten the time between the release of new hardware component model and final co-simulation execution where the entire integrated system is tested on a virtual car, and later on a real car.</blockquote>\n" +
          "<h3><strong>Key facts</strong></h3>\n" +
          "<ul>\n" +
          "<li>Process automation</li>\n" +
          "<li>Drastically shortened vehicle development cycles</li>\n" +
          "<li>Continuous Integration / Continuous Delivery</li>\n" +
          "</ul>\n",
      },
      {
        title: "Vehicle Recalling System",
        description:
          "The international automotive manufacturer has successfully rolled out a new, individual, scalable solution based on open source technologies with Comsysto Reply as a partner.\nThus a 30-year-old legacy application for recall processes could be replaced by a modern web application that meets today’s standards of user experience, design, security and traffic.",
        imageSrc: { vrs_logo },
        descriptionHtml:
          "<h3><strong>Use case</strong></h3>\n" +
          "<blockquote><em>The international automotive manufacturer has successfully rolled out a new, individual, scalable solution based on open source technologies with Comsysto Reply as a partner. Thus a 30-year-old legacy application for recall processes could be replaced by a modern web application that meets today’s standards of user experience, design, security and traffic.</em></blockquote>\n" +
          "<h3><strong>Challenges</strong></h3>\n" +
          "<blockquote>The recall web application can be used by internal employees as well as by car dealers, importers and workshops worldwide. Extreme load peaks can occur in case recall campaigns cause a particular public interest. In total, the application is planned to be used in over 30 different languages, which means additional complexity due to internationalization.</blockquote>\n" +
          "<h3><strong>Solution</strong></h3>\n" +
          "<blockquote>The existing monolithic system is replaced by a cloud-native microservice architecture running on the Cloud Foundry platform deployed to AWS, thanks to Comsysto Reply’s specific cloud-native architecture know-how and domain knowledge. The solution is implemented using technologies such as Java, Spring Boot, Typescript, and Angular and is based on the Twelve-Factor App methodology to provide a modern, scalable and maintainable system that is used on a global scale.</blockquote>\n" +
          "<h3><strong>Key facts</strong></h3>\n" +
          "<ul>\n" +
          "<li>Around 60 million transactions per day</li>\n" +
          "<li>Used by around 25.000 companies globally (internal corporate employees, car dealers, workshops)</li>\n" +
          "<li>Scalable microservice architecture engineered to support extreme load peeks and to meet today’s standards of user experience, design, security, and traffic</li>\n" +
          "</ul>\n",
      },
    ],
  },
];

export const homePageText = [
  "For years, Comsysto Reply has been a pioneer in the agile development of customized software solutions that enable companies to master challenges of digital transformation. With a clear focus on lean processes, open source frameworks and cloud services, we accelerate innovation, minimize risks and create sustainable business value. Comsysto Reply works in cross-functional product teams closely with customers, partners and Reply Group sister companies, taking a leading role in the application of Lean and Agile methodologies, Big Data, User Experience Research, DevOps as well as Continuous Delivery.",
  "We started out as a small company and have experienced from the very beginning that professional support can be great help. Therefore we know that working at the beginning of a project together with highly motivated interdisciplinary product teams is often the most fun. Our decision making paths are short the hierarchies are flat. Together we can achieve everything. At Comsysto, we focus on long-term cooperation based on partnership. We prefer to work without countless proxies and project managers in order to coordinate everything directly and with the end customers in mind. Without overhead. Simply to build great products together and let them grow in the market.",
];
