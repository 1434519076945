import { Snackbar } from "@material-ui/core";
import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";

function Alert(props) {
  return <MuiAlert elevation={6} variant="standard" {...props} />;
}

const CustomSnackbar = ({ open, closeAlert, message, severity }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={closeAlert}
      key={message}
      autoHideDuration={3000}
      disableWindowBlurListener={true}
    >
      <Alert severity={severity ? severity : "success"}>{message}</Alert>
    </Snackbar>
  );
};

CustomSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  closeAlert: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string,
};

export default CustomSnackbar;
