import React, { useEffect, useState, useCallback } from "react";
import { Route, Switch } from "react-router-dom";
import UsersPage from "./UsersPage";
import EmployeePage from "./EmployeePage";
import AdminMilestones from "./AdminMilestones";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint";
import putDataToEndpoint from "../../utils/putDataToEndPoint";
import postDataToEndpoint from "../../utils/postDataToEndPoint";
import { adminUrls } from "../../utils/baseUrl";
import routerUrls from "../../utils/routerUrls";
import deleteDataFromEndpoint from "../../utils/deleteDataFromEndpoint";
import CustomSnackbar from "../../components/snackbar/CustomSnackbar";
import ErrorBoundary from "../../utils/errorBoundary/ErrorBoundary";
import { UserTypes } from "../../constants/UserTypes";
import EditInterviewQuestionsPage from "../edit-interview-questions-page/EditInterviewQuestionsPage.js";
import EditFormFieldsPage from "../edit-form-fields-page/EditFormFieldsPage";
import UserProfilePage from "../user-profile-page/UserProfilePage.js";
import { useTranslation } from "react-i18next";

/**
 *  @Component
 *  @description Rednder admin page and fetches user data
 *  @props recieves handlehambugerclick abd handleUnauthorized function and userData
 */
const AdminPage = ({ handleUnauthorized, handleHamburgerClick, userData }) => {
  const { t } = useTranslation();

  const initialValues = {
    users: [],
    milestones: [],
    error: null,
    alertOpen: false,
    alertMessage: "",
    alertSeverity: "",
  };

  const [values, setValues] = useState(initialValues);

  const closeAlert = () => {
    setValues((prevState) => ({
      ...prevState,
      alertOpen: false,
    }));
  };

  const toggleAlert = (message, severity) => {
    setValues((prevState) => ({
      ...prevState,
      alertOpen: true,
      alertMessage: message,
      alertSeverity: severity,
    }));
  };

  /**
   * @function
   * @description
   * Function for getting user data from BE
   */
  const fetchUserData = async () => {
    const { errFlag, data } = await getDataFromEndpoint(adminUrls.userDataUrl);
    if (!errFlag) {
      setValues({ ...values, users: data });
    } else {
      setValues({ ...values, error: data });
    }
  };

  const fetchMilestoneData = async () => {
    const { errFlag, data } = await getDataFromEndpoint(
      adminUrls.milestonesUrl
    );
    if (!errFlag) {
      setValues({ ...values, milestones: data });
    } else {
      setValues({ ...values, error: data });
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchMilestoneData();
  }, []);

  const deleteMilestone = async (milestoneId) => {
    try {
      await postDataToEndpoint(`${adminUrls.milestonesUrl}/${milestoneId}`, {});
      fetchMilestoneData();
    } catch (err) {
      setValues({ ...values, error: err });
    }
  };

  const handleDeleteUser = async (userIdToDelete, userToDelete) => {
    await deleteDataFromEndpoint(
      adminUrls.userDataUrl + "/" + userIdToDelete
    ).then((r) => console.log(r));

    setValues({
      ...values,
      users: values.users.filter((e) => e.id !== userIdToDelete),
    });
    toggleAlert(t("admin-page.delete-message", { userToDelete }), "error");
  };

  /**
   * @function
   * @description Updates user type in state
   * @param  {number} userId
   * @param  {string} newType
   */
  const updateUserType = (userId, newType) => {
    const oldUserType = values.users.filter((user) => user.id === userId)[0]
      .userType;
    const updatedUsers = values.users.map((user) => {
      if (user.id === userId) {
        user.userType = newType;
      }
      return user;
    });
    return { oldUserType, updatedUsers };
  };
  const addUserHandler = async (email) => {
    const oldUserState = values.users;
    if (!email.split("@")[0].includes(".")) {
      toggleAlert(t("admin-page.warning-message"), "warning");
    } else {
      const firstName = email.split("@")[0].split(".")[0];
      const lastName = email.split("@")[0].split(".")[1];
      const username =
        firstName.charAt(0).toUpperCase() +
        firstName.slice(1) +
        " " +
        lastName.charAt(0).toUpperCase() +
        lastName.slice(1);
      setValues({
        ...values,
        users: [
          ...values.users,
          { name: username, email: email, userType: UserTypes.FULLTIME },
        ],
      });
      const { errFlag, data } = await postDataToEndpoint(
        `${adminUrls.userDataUrl}`,
        {
          email: email,
          userType: UserTypes.FULLTIME,
          name: username,
        }
      );

      if (errFlag) {
        setValues({ ...values, users: oldUserState });
        if (data === 401) {
          handleUnauthorized();
        }
        toggleAlert(t("admin-page.error-message", { username }), "error");
      } else {
        setValues({
          ...values,
          users: [
            ...oldUserState,
            {
              id: data.id,
              name: data.name,
              email: email,
              userType: UserTypes.FULLTIME,
              completionProgress: 0,
            },
          ],
        });
        toggleAlert(t("admin-page.success-message", { data }));
      }
    }
  };
  const changeUserType = async (event, userData) => {
    const { oldUserType, updatedUsers } = updateUserType(
      userData.userId,
      event.target.value
    );
    setValues({ ...values, users: updatedUsers });

    const { errFlag, data } = await putDataToEndpoint(
      `${adminUrls.userDataUrl}/${userData.userId}`,
      {
        userType: event.target.value,
        email: userData.email,
        onboarded: userData.onboarded,
        name: userData.name,
        jobTitle: userData.jobTitle,
      }
    );
    if (!errFlag) toggleAlert(t("admin-page.update-message"));
    if (errFlag) {
      const { updatedUsers } = updateUserType(userData.userId, oldUserType);
      setValues({
        ...values,
        users: updatedUsers,
      });
      if (data === 401) {
        handleUnauthorized();
      }
    }
  };

  const changeJobTitle = async (user, newJobTitle) => {
    const { errFlag, data } = await putDataToEndpoint(
      `${adminUrls.userDataUrl}/${user.id}`,
      {
        userType: user.userType,
        email: user.email,
        onboarded: user.onboarded,
        name: user.name,
        jobTitle: newJobTitle,
      }
    );
    if (!errFlag) {
      const updatedUsers = values.users.map((u) => {
        if (u.id === user.id) {
          u.jobTitle = data.jobTitle;
        }
        return user;
      });
      setValues({
        ...values,
        updatedUsers,
      });
      toggleAlert(t("admin-page.update-message"));
    }
  };

  return (
    <Switch>
      <Route
        path={routerUrls.adminPage.route}
        exact
        render={(props) => (
          <AdminMilestones
            {...props}
            deleteMilestone={deleteMilestone}
            milestones={values.milestones}
            handleHamburgerClick={handleHamburgerClick}
            userData={userData}
          />
        )}
      />
      <Route
        path={routerUrls.adminUsersPage.route}
        exact
        render={(props) => (
          <ErrorBoundary>
            {values.users.length > 0 && (
              <UsersPage
                {...props}
                addUserHandler={addUserHandler}
                changeUserType={changeUserType}
                users={values.users}
                handleHamburgerClick={handleHamburgerClick}
                handleDeleteUser={handleDeleteUser}
                toggleAlert={toggleAlert}
                userData={userData}
                changeJobTitle={changeJobTitle}
              />
            )}
            <CustomSnackbar
              message={values.alertMessage}
              closeAlert={closeAlert}
              open={values.alertOpen}
              severity={values.alertSeverity}
            />
          </ErrorBoundary>
        )}
      />
      <Route
        path={routerUrls.adminUsersProgressPage.route}
        exact
        render={(props) => (
          <EmployeePage
            {...props}
            handleHamburgerClick={handleHamburgerClick}
            handleUnauthorized={handleUnauthorized}
          />
        )}
      />
      <Route
        path={routerUrls.editInterviewQuestionsPage.route}
        exact
        render={(props) => (
          <ErrorBoundary>
            <EditInterviewQuestionsPage
              {...props}
              toggleAlert={toggleAlert}
              userData={userData}
            />
            <CustomSnackbar
              message={values.alertMessage}
              closeAlert={closeAlert}
              open={values.alertOpen}
              severity={values.alertSeverity}
            />
          </ErrorBoundary>
        )}
      />
      <Route
        path={routerUrls.editFormFieldsPage.route}
        exact
        render={(props) => (
          <ErrorBoundary>
            <EditFormFieldsPage
              {...props}
              toggleAlert={toggleAlert}
              userData={userData}
            />
            <CustomSnackbar
              message={values.alertMessage}
              closeAlert={closeAlert}
              open={values.alertOpen}
              severity={values.alertSeverity}
            />
          </ErrorBoundary>
        )}
      />
      <Route
        path={routerUrls.userProfilePageAdminView.route}
        render={(props) => (
          <ErrorBoundary>
            <UserProfilePage
              {...props}
              toggleAlert={toggleAlert}
              userId={props.match.params.userId}
              isAdmin={true}
            />
            <CustomSnackbar
              message={values.alertMessage}
              closeAlert={closeAlert}
              open={values.alertOpen}
              severity={values.alertSeverity}
            />
          </ErrorBoundary>
        )}
      />
    </Switch>
  );
};

export default AdminPage;
