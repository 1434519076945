import React, { useCallback, useEffect, useState } from "react";
import { BaseLayout, Label } from "../../components";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint.js";
import postDataToEndpoint from "../../utils/postDataToEndPoint";
import { adminUrls, baseBackendUrl, urls } from "../../utils/baseUrl.js";
import { ScrollContainer } from "../scroll-container/ScrollContainer.js";
import InterviewQuestion from "../../components/interview-question/InterviewQuestion.js";
import downloadFileFromEndpoint from "../../utils/downloadFileFromEndpoint.js";
import { FileTypes } from "../../constants/FileTypes.js";
import { StyledDownloadButton } from "../../components/buttons/StyledDownloadButton.js";
import { HiDownload } from "react-icons/hi";
import putDataToEndpoint from "../../utils/putDataToEndPoint.js";
import { StyledButtonsContainer } from "../buttons-container/StyledButtonsContainer";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders User profile page
 * @param  {function} toggleAlert toggle alert message
 * @param  {string} userId user id
 * @param  {boolean} isAdmin checks if admin is viewing users profile
 */
const UserProfilePage = ({ toggleAlert, userId, isAdmin }) => {
  const [userAccountInfo, setUserAccountInfo] = useState({});
  const [qAndA, setQAndA] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [slackPicture, setSlackPicture] = useState("");
  const [avatarPicture, setAvatarPicture] = useState("");
  const [seriousPicture, setSeriousPicture] = useState("");
  const [userData, setUserData] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [answers, setAnswers] = useState([""]);
  const [sentToSlack, setSentToSlack] = useState(false);
  const { t } = useTranslation();

  const fetchUserData = useCallback(
    async (userId) => {
      const { errFlag, data } = await getDataFromEndpoint(
        isAdmin ? `${adminUrls.userDataUrl}/${userId}` : `${urls.userUrl}`
      );
      if (!errFlag) {
        setUserData(data);
      } else {
        toggleAlert(t("user-profile.error-user"), "error");
      }
    },
    [toggleAlert, isAdmin]
  );

  const getAccountInfoData = useCallback(
    async (userId) => {
      try {
        const { data, errFlag } = await getDataFromEndpoint(
          urls.userAccountInfoUrl.replace(":userId", userId)
        );
        if (!errFlag) {
          setUserAccountInfo(data);
        }
      } catch (error) {
        toggleAlert(t("user-profile.error-info"), "error");
      }
    },
    [toggleAlert]
  );

  const getQuestionsAndAnswers = useCallback(
    async (userId) => {
      const { data, errFlag } = await getDataFromEndpoint(
        isAdmin
          ? `${adminUrls.answerUrl}`.replace(":userId", userId)
          : `${urls.answerUrl}`
      );
      if (!errFlag) {
        setQAndA(data);
        const tempAnswers = [];
        for (const d of data) {
          tempAnswers.push(d.answer);
        }
        setAnswers(tempAnswers);
      } else {
        toggleAlert(t("user-profile.error-questions"), "error");
      }
    },
    [toggleAlert, isAdmin]
  );

  const getUploadedDocuments = useCallback(
    async (userId) => {
      const { data, errFlag } = await getDataFromEndpoint(
        isAdmin
          ? adminUrls.getDocumentsByUserId.replace(":userId", userId)
          : `${urls.documentsUrl.replace(":fileType", "")}`
      );
      if (!errFlag) {
        setUploadedDocuments(data);
      } else {
        toggleAlert(t("user-profile.error-documents"), "error");
      }
    },
    [toggleAlert, isAdmin]
  );

  const downloadDocument = (fileType, fileName) => {
    downloadFileFromEndpoint(
      `${baseBackendUrl}/${adminUrls.downloadDocumentUrl}`
        .replace(":fileType", fileType)
        .replace(":fileName", fileName),
      fileName,
      toggleAlert
    );
  };

  const downloadDocumentsZip = (userId, fileType) => {
    downloadFileFromEndpoint(
      `${baseBackendUrl}/${adminUrls.downloadDocumentsForUserUrl}`
        .replace(":userId", userId)
        .replace(":fileType", fileType),
      `${userData.name}_${fileType}.zip`,
      toggleAlert
    );
  };

  const fetchPicture = useCallback(
    async (userId, fileType) => {
      try {
        const { data, errFlag } = await getDataFromEndpoint(
          urls.getPictureUrl
            .replace(":userId", userId)
            .replace(":fileType", fileType)
        );
        if (!errFlag) {
          return data;
        } else {
          toggleAlert(t("user-profile.error-pictures"), "error");
        }
      } catch (err) {}
    },
    [toggleAlert]
  );

  const getAllUsers = useCallback(async () => {
    try {
      const { data, errFlag } = await getDataFromEndpoint(
        adminUrls.userDataUrl
      );
      if (!errFlag) {
        setAllUsers(
          data.filter(
            (u) => u.id !== parseInt(userId) && u.ap?.id !== parseInt(userId)
          )
        );
      }
    } catch (error) {
      toggleAlert(t("user-profile.error-users"), "error");
    }
  }, [toggleAlert, userId]);

  const getAdminData = useCallback(async () => {
    try {
      const { data, errFlag } = await getDataFromEndpoint(urls.userUrl);
      if (!errFlag) {
        setAdminData(data);
      }
    } catch (error) {
      toggleAlert(t("user-profile.error-admin"), "error");
    }
  }, [toggleAlert]);

  const changeUserAP = async (newAP) => {
    userData.apId = newAP;
    try {
      const { data, errFlag } = await putDataToEndpoint(
        `${adminUrls.userDataUrl}/${userId}`,
        userData
      );
      if (!errFlag) {
        setUserData(data);
        toggleAlert(t("user-profile.success-ap"));
      }
    } catch (error) {
      toggleAlert(t("user-profile.error-update-user"), "error");
    }
  };

  const pictures = [slackPicture, avatarPicture, seriousPicture];

  useEffect(() => {
    fetchUserData(userId);
    getAccountInfoData(userId);
    getQuestionsAndAnswers(userId);
    getUploadedDocuments(userId);
    isAdmin && getAdminData() && getAllUsers();
    fetchPicture(userId, FileTypes.SLACK_PICTURE).then((data) =>
      data !== undefined ? setSlackPicture("data:image;base64," + data) : null
    );
    fetchPicture(userId, FileTypes.AVATAR_PICTURE).then((data) =>
      data !== undefined ? setAvatarPicture("data:image;base64," + data) : null
    );
    fetchPicture(userId, FileTypes.SERIOUS_PICTURE).then((data) =>
      data !== undefined ? setSeriousPicture("data:image;base64," + data) : null
    );
  }, [
    fetchUserData,
    getAccountInfoData,
    getQuestionsAndAnswers,
    getUploadedDocuments,
    isAdmin,
    getAdminData,
    getAllUsers,
    fetchPicture,
    userId,
  ]);

  const handleAnswerChange = (newAnswer, index) => {
    answers[index] = newAnswer;
    setAnswers(answers);
  };

  const handleSave = (ans) => {
    saveAnswersForUser(true);
  };

  const prepareAnswersToSend = () => {
    const tempAnswers = [];
    let i = 0;
    for (const q of qAndA) {
      tempAnswers.push({ questionId: q.questionId, answerText: answers[i] });
      i++;
    }
    return JSON.stringify(tempAnswers);
  };

  const saveAnswersForUser = async (showAlert) => {
    const tempAnswers = prepareAnswersToSend();
    try {
      const { data, errFlag } = await putDataToEndpoint(
        `${urls.answerUrl}/${userId}`,
        JSON.parse(tempAnswers)
      );
      if (!errFlag) {
        if (showAlert) {
          toggleAlert(t("user-profile.answers-saved"));
        }
        return data;
      } else {
        toggleAlert(data.data.message, "error");
        throw new Error(t("user-profile.data-error"));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendToSlack = () => {
    if (sentToSlack) {
      toggleAlert(t("user-profile.already-sent"), "info");
    } else {
      approveAnswers();
      sendToSlack();
    }
  };

  const approveAnswers = async (showAlert) => {
    try {
      const { data, errFlag } = await putDataToEndpoint(
        adminUrls.answerApprovalUrl.replace("userId", userId),
        { approved: true }
      );
      if (!errFlag) {
        return data;
      } else {
        toggleAlert(data.data.message, "error");
        throw new Error(t("user-profile.data-error"));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const sendToSlack = async (showAlert) => {
    try {
      const { data, errFlag } = await postDataToEndpoint(
        adminUrls.sendAnswersAndPictureToSlackUrl.replace("userId", userId),
        { introductionMessage: t("user-profile.introduction") }
      );
      if (!errFlag) {
        if (showAlert) {
          toggleAlert(t("user-profile.data-sent"));
        }
        setSentToSlack(true);
        return data;
      } else {
        toggleAlert(data.data.message, "error");
        throw new Error(t("user-profile.data-error"));
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (userData !== null) {
    return (
      <BaseLayout hasTextEditor={true} mainPadding={"0 13% 2% 13%"}>
        <div>
          <Grid item>
            <h1
              style={{
                color: "#57ADDD",
                fontWeight: "bold",
                marginBottom: "1%",
              }}
            >
              {t("user-profile.title")}
            </h1>
          </Grid>
          <Grid item>
            <h2>
              <strong>{userData.name}</strong>
            </h2>
          </Grid>
          <br />
          <Grid item md={12}>
            <Card
              style={{
                background: "rgba(196, 196, 196, 0.15)",
                padding: "1em",
                marginBottom: "1.5em",
                boxShadow: "0px 5.38775px 12.1224px rgba(0, 0, 0, 0.25)",
              }}
            >
              <h4>
                <strong>{t("user-profile.info")}</strong>
              </h4>
              <div
                style={{ display: "grid", gridTemplateColumns: "1.5fr 1fr" }}
              >
                <Label>{t("user-profile.info")}</Label>
                <span>{userData.userType}</span>
                <Label>{t("user-profile.job")}</Label>
                <span>{userData.jobTitle}</span>
                <Label>{t("user-profile.contact")}</Label>
                <span>{userData.email.split("@")[0]}</span>
                <Label>{t("user-profile.mail")}</Label>
                <span>{userData.email}</span>
                <Label>{t("user-profile.ap")} </Label>
                {isAdmin ? (
                  <div>
                    <select
                      style={{
                        minWidth: "10em",
                        background: "rgba(196, 196, 196, 0.15)",
                        borderRadius: "30px",
                        padding: "0.2em",
                        border: "1px solid #57ADDD",
                      }}
                      value={userData.ap?.id}
                      onChange={(e) => {
                        changeUserAP(e.target.value);
                      }}
                    >
                      {allUsers &&
                        allUsers.map((user, i) => {
                          return (
                            <option key={i} value={user.id}>
                              {user.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                ) : (
                  <span>{userData.ap?.name}</span>
                )}
              </div>
            </Card>

            <Card
              style={{
                background: "rgba(196, 196, 196, 0.15)",
                padding: "1em",
                marginBottom: "1.5em",
                boxShadow: "0px 5.38775px 12.1224px rgba(0, 0, 0, 0.25)",
              }}
            >
              <h4>
                <strong>{t("user-profile.choices")}</strong>
              </h4>
              <div
                style={{ display: "grid", gridTemplateColumns: "1.5fr 1fr" }}
              >
                <Label>{t("user-profile.laptop")}</Label>
                <span>{userAccountInfo?.laptopChoice}</span>
                <Label>{t("user-profile.cellphone")}</Label>
                <span>{userAccountInfo?.cellphoneChoice}</span>
                <Label>{t("user-profile.headphones")} </Label>
                <span>{userAccountInfo?.headphonesChoice}</span>
                <Label>{t("user-profile.keyboard")} </Label>
                <span>{userAccountInfo?.keyboardAndMouseChoice}</span>
              </div>
            </Card>
          </Grid>

          <Card
            style={{
              background: "rgba(196, 196, 196, 0.15)",
              padding: "1em",
              marginBottom: "3em",
              boxShadow: "0px 5.38775px 12.1224px rgba(0, 0, 0, 0.25)",
            }}
          >
            <h4>
              <strong>{t("user-profile.documentation")}</strong>
            </h4>
            <ul>
              {[
                FileTypes.APPLICATION_DOCUMENT,
                FileTypes.AOR_DOCUMENT,
                FileTypes.OTHER_DOCUMENT,
              ].map((fileType, index) => {
                return (
                  <Grid container key={`docs${index}`}>
                    <Grid item md={7}>
                      <li>
                        <h6 style={{ color: "#57ADDD" }}>
                          <b>{fileType.replace("_", " ")}S:</b>
                        </h6>
                      </li>
                      <ul style={{ overflow: "auto" }}>
                        {uploadedDocuments
                          .filter((doc) => doc.fileType === fileType)
                          .map((doc, i) => (
                            <li
                              key={i}
                              style={{
                                cursor: "pointer",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              onClick={() =>
                                downloadDocument(doc.fileType, doc.filename)
                              }
                            >
                              {doc.filename}
                            </li>
                          ))}
                        <br />
                      </ul>
                    </Grid>
                    {isAdmin && (
                      <Grid
                        item
                        md={5}
                        style={{ display: "flex", justifyContent: "right" }}
                      >
                        <StyledDownloadButton
                          fontSize={"0.9em"}
                          onClick={() =>
                            downloadDocumentsZip(userData.id, fileType)
                          }
                        >
                          <HiDownload />
                          {fileType.split("_")[0]} Documents
                        </StyledDownloadButton>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </ul>
          </Card>
          <Grid item xs={12}>
            <h4>
              <strong>{t("user-profile.q&a")}</strong>
            </h4>
            <ScrollContainer maxHeight={"70vh"} marginBottom={"3.5em"}>
              {qAndA.map((q, index) => {
                return (
                  <InterviewQuestion
                    readOnly={!isAdmin}
                    key={index}
                    questionText={index + 1 + ". " + q.question}
                    answer={q.answer}
                    onChangeAnswer={(newAnswer) =>
                      handleAnswerChange(newAnswer, index)
                    }
                  />
                );
              })}
              {isAdmin && (
                <StyledButtonsContainer disabled={sentToSlack}>
                  <Button
                    variant={"outline-info"}
                    onClick={handleSave}
                    disabled={sentToSlack}
                  >
                    {t("user-profile.save")}
                  </Button>
                  <Button
                    variant={"outline-info"}
                    onClick={handleSendToSlack}
                    disabled={sentToSlack}
                  >
                    {t("user-profile.save")}
                  </Button>
                </StyledButtonsContainer>
              )}
            </ScrollContainer>
          </Grid>
          <Grid>
            <ImageList sx={{ marginTop: "2em" }} cols={3} gap={8}>
              {[
                FileTypes.SLACK_PICTURE,
                FileTypes.AVATAR_PICTURE,
                FileTypes.SERIOUS_PICTURE,
              ].map((picture, i) => {
                if (pictures[i]) {
                  return (
                    <ImageListItem key={`picture${i}`}>
                      <img
                        src={pictures[i]}
                        srcSet={pictures[i]}
                        alt={picture}
                        loading="lazy"
                        onClick={() =>
                          isAdmin
                            ? downloadDocumentsZip(userData.id, picture)
                            : null
                        }
                        style={{
                          borderRadius: "15px",
                          maxHeight: "15em",
                          maxWidth: "15em",
                          cursor: "pointer",
                        }}
                      />
                      <ImageListItemBar
                        sx={{
                          borderBottomLeftRadius: "15px",
                          borderBottomRightRadius: "15px",
                        }}
                        title={picture.split("_").join(" ")}
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            aria-label={`info about ${picture
                              .split("_")
                              .join(" ")}`}
                          ></IconButton>
                        }
                      />
                    </ImageListItem>
                  );
                } else {
                  return <></>;
                }
              })}
            </ImageList>
          </Grid>
        </div>
      </BaseLayout>
    );
  } else {
    return <></>;
  }
};
UserProfilePage.propTypes = {
  toggleAlert: PropTypes.func,
  userId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default UserProfilePage;
