import styled from "styled-components";

export const MilestoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 60%;
  border-radius: 3em / 3em;
  margin: 10vh 14vw;
  padding: 2rem 2rem;
  background-color: ${(props) => props.backgroundColor};
`;
