import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints";

export const StyledHomePageHeader = styled.header`
  margin-top: 1em;
  width: 100%;
  padding: 0 2% 0 2%;
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  align-items: center;
  img {
    padding: 5px;
    margin-top: 0.5em;
    height: 7em;
    justify-self: start;
    border-radius: 35px;
  }
  button {
    justify-self: start;
  }

  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
    img {
      height: 75px;
    }
  }
`;
