import styled from "styled-components";

export const StyledButtonsContainer = styled.div`
  display: flex;
  margin-top: 2em;
  margin-bottom: 1em;
  display: flex;
  justify-content: right;
  button {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    display: block;
    min-width: 15%;
    width: auto;

    font-size: 0.9em;
    margin: 0.5em;
    border-radius: 5px;
  }
`;
