import { createGlobalStyle } from "styled-components";
import ProximaNovaBold from "./assests/fonts/ProximaNova-Bold.otf";
import ProximaNovaExtraBold from "./assests/fonts/ProximaNova-Extrabold.otf";
import ProximaNovaBoldItalic from "./assests/fonts/ProximaNova-BoldIt.otf";
import ProximaNovaItalic from "./assests/fonts/ProximaNova-RegItalic.otf";
import ProximaNova from "./assests/fonts/ProximaNova-Regular.otf";

export const GlobalStyles = createGlobalStyle`
  @font-face {
      font-family: ProximaNova;
      font-weight: 400;
      font-style: normal;
      src:  local('Proxima Nova'),local('ProximaNova'),
            url(${ProximaNova}) format("opentype"),
            url(${ProximaNovaItalic}) format("opentype");
  }
  @font-face {
      font-family: ProximaNova;
      font-weight: 400;
      font-style: italic;
      src:  local('Proxima Nova'),local('ProximaNova'),
            url(${ProximaNovaItalic}) format("opentype");
  }
  @font-face {
      font-family: ProximaNova;
      font-weight: 700;
      font-style: normal;
      src:  local('Proxima Nova'),local('ProximaNova'),
            url(${ProximaNovaBold}) format("opentype");   
  }
  @font-face {
      font-family: ProximaNova;
      font-weight: 700;
      font-style: italic;
      src:  local('Proxima Nova'),local('ProximaNova'),
            url(${ProximaNovaBoldItalic}) format("opentype");   
  }
  @font-face {
      font-family: ProximaNova;
      font-weight: 900;
      font-style: bold;
      src:  local('Proxima Nova'),local('ProximaNova'),
            url(${ProximaNovaExtraBold}) format("opentype");   
  }
  html {
    box-sizing: border-box;
  }
  *, 
  *:before, 
  *:after {
    box-sizing: inherit;
    font-family: inherit;
    
  }
  a{
    text-decoration: underline;
    &:hover{
      color : #1e9fcc;
    }
  }
  
  body {
    font-family: ProximaNova,Arial, sans-serif;
    font-style:normal;
    line-height: 1.5;;
    margin:0;
    padding:0;
  }
  button , input {
    outline: none;
  }

`;
