import React, { useState, useEffect } from "react";
import AdminHeader from "./../../components/admin-header/AdminHeader";
import { StyledBaseLayout } from "../../components/base-layout/StyledBaseLayout";
import PropTypes from "prop-types";
import { MainContainer } from "../main/MainContainer";
import { styles } from "../../constants/Constants";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint";
import { adminUrls, urls } from "../../utils/baseUrl";
import { FullNameLabel } from "../../components/label/FullNameLabel";
import { ButtonContainer } from "../button-container/ButtonContainer";
import { BasicRoundButton } from "../../components/buttons/BasicRoundButton";
import EmployeeData from "./EmployeeData";
import EmployeeProgress from "./EmployeeProgress";
import running_man_logo from "../../assests/images/running-man-logo-no-blank-space.png";
import routerUrls from "../../utils/routerUrls";
import EmployeeInterview from "./EmployeeInterview";
import { Image } from "react-bootstrap";
import EmployeeDocuments from "./EmployeeDocuments";
import postDataToEndpoint from "../../utils/postDataToEndPoint";
import deleteDataFromEndpoint from "../../utils/deleteDataFromEndpoint";
import CustomSnackbar from "../../components/snackbar/CustomSnackbar";
import { useTranslation } from "react-i18next";

/**
 * @Component Renders users progress page
 * Gets the users ID from URL and then requests backend for data about specific user
 */

const EmployeePage = ({ handleHamburgerClick, handleUnauthorized }) => {
  const initialValues = {
    usersFullName: "",
    checkboxData: [],
    inputData: [],
    error: null,
    lastClicked: null,
    documents: [],
    documentRejectMessage: "",
    introductionMessage: "",
    mentionData: null,
    rejectMessage: "",
    alertOpen: false,
    alertMessage: "",
    alertSeverity: "",
  };

  const [values, setValues] = useState(initialValues);
  const { t } = useTranslation();

  userId = props.match.params.userId;

  /**
   * @FUNCTION
   * @description
   * Function for getting user data from BE
   */
  const fetchUserData = async () => {
    const { errFlag, data } = await getDataFromEndpoint(
      `${adminUrls.userDataUrl}/${userId}`
    );
    if (!errFlag) {
      setValues({ ...values, usersFullName: data.name });
    } else if (data === 401) {
      handleUnauthorized();
    } else {
      setValues({ ...values, error: data });
    }
  };

  const fetchCheckboxData = async () => {
    const { errFlag, data } = await getDataFromEndpoint(
      `${adminUrls.completedCheckboxesByUsersIdUrl}/${userId}`
    );
    if (!errFlag) {
      setValues({ ...values, checkboxData: data });
    } else if (data === 401) {
      handleUnauthorized();
    } else {
      setValues({ ...values, error: data });
    }
  };

  const fetchInputData = async () => {
    const { errFlag, data } = await getDataFromEndpoint(
      `${urls.userAccountInfoUrl.replace(":userId", userId)}`
    );
    if (!errFlag) {
      setValues({ ...values, inputData: data });
    } else if (data === 401) {
      handleUnauthorized();
    } else {
      setValues({ ...values, error: data });
    }
  };

  const fetchDocuments = async () => {
    const { errFlag, data } = await getDataFromEndpoint(
      `${adminUrls.getDocumentsByUserId.replace(":userId", userId)}`
    );
    if (!errFlag) {
      let filesChecked = [];
      for (let i = 0; i < data.length; i++) {
        filesChecked.push({
          id: i,
          filename: data[i].filename,
          checked: false,
        });
      }
      setValues({ ...values, documents: filesChecked });
    } else if (data === 401) {
      handleUnauthorized();
    } else {
      setValues({ ...values, error: data });
    }
  };

  const mapCheckboxesToSections = (section) => {
    const data = values.checkboxData;
    if (!!data) {
      const checkboxes = data
        .filter((checkbox) => checkbox.checkboxSection === section)
        .sort((a, b) => a.pageNumber - b.pageNumber);
      if (checkboxes.length === 0) {
        return null;
      }
      return checkboxes.map((c) => <li key={c.id}>{c.title}</li>);
    }
  };

  const countCheckboxesOfSections = (section) => {
    const data = values.checkboxData;
    if (!!data) {
      const checkboxes = data.filter(
        (checkbox) => checkbox.checkboxSection === section
      );
      return checkboxes.length;
    }
    return 0;
  };

  const fetchData = async () => {
    await fetchUserData(userId);
    await fetchCheckboxData();
    await fetchInputData();
    await fetchDocuments();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickEvent = (id) => {
    if (values.lastClicked === id) {
      setValues({ ...values, lastClicked: null });
    } else {
      setValues({ ...values, lastClicked: id });
    }
  };

  const handleMentionChange = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    setValues({
      ...values,
      introductionMessage: newValue,
      mentionData: { newValue, newPlainTextValue, mentions },
    });
  };

  const handleRejectTextChange = (event) => {
    setValues({ ...values, rejectMessage: event.target.value });
  };

  const closeAlert = () => {
    setValues({ ...values, alertOpen: false });
  };

  const toggleAlert = (message, severity) => {
    setValues({
      ...values,
      alertOpen: true,
      alertMessage: message,
      alertSeverity: severity,
    });
  };

  const handleDocumentCheck = (id) => {
    let newDocumentsState = values.documents;

    for (let i = 0; i < newDocumentsState.length; i++) {
      if (newDocumentsState[i].id === id) {
        let document = newDocumentsState[i];
        document.checked = !document.checked;
        newDocumentsState[i] = document;
      }
    }
    setValues({ ...values, documents: newDocumentsState });
  };

  const sendDocumentRejectMessageToSlack = () => {
    const { errFlag, data } = postDataToEndpoint(
      `${adminUrls.sendMessageToUserOnSlackUrl.replace("userId", userId)}`,
      {
        message: values.documentRejectMessage,
      }
    );
    if (data === 401) {
      handleUnauthorized();
    } else if (errFlag) {
      setValues({ ...values, error: data });
    }
  };

  const deleteDocuments = (rejectedDocuments) => {
    const { data, errFlag } = deleteDataFromEndpoint(
      adminUrls.deleteDocumentsUrl,
      rejectedDocuments
    ).then((res) => toggleAlert(res.data));
    if (data === 401) {
      handleUnauthorized();
    } else if (errFlag) {
      setValues({ ...values, error: data });
    }
  };

  const handleDocumentsRejection = () => {
    let rejectedDocuments = values.documents
      .filter((documentChecked) => documentChecked.checked === true)
      .map((e) => e.filename);
    setValues({
      ...values,
      documents: values.documents.filter(
        (documentChecked) => documentChecked.checked !== true
      ),
    });
    sendDocumentRejectMessageToSlack();
    deleteDocuments(rejectedDocuments);
  };

  const handleDocumentRejectTextChange = (event) => {
    setValues({ ...values, documentRejectMessage: event.target.value });
  };

  const renderSwitch = (buttonId) => {
    const userMentionData = [
      {
        id: userId,
        display: values.usersFullName.split(" ").join(".").toLowerCase(),
      },
    ];

    switch (buttonId) {
      case 1:
        return <EmployeeData inputData={values.inputData} userId={userId} />;
      case 2:
        return (
          <EmployeeProgress
            countCheckboxesOfSections={countCheckboxesOfSections}
            mapCheckboxesToSections={mapCheckboxesToSections}
          />
        );
      case 3:
        return (
          <EmployeeDocuments
            documents={values.documents}
            handleDocumentsCheck={handleDocumentCheck}
            documentRejectMessageValue={values.documentRejectMessage}
            handleDocumentRejectTextChange={handleDocumentRejectTextChange}
            handleDocumentsRejection={handleDocumentsRejection}
            toggleAlert={toggleAlert}
          />
        );
      case 4:
        return (
          <EmployeeInterview
            userId={userId}
            introductionMessageValue={values.introductionMessage}
            handleMentionChange={handleMentionChange}
            userMentionData={userMentionData}
            handleRejectTextChange={handleRejectTextChange}
            rejectMessageValue={values.rejectMessage}
            toggleAlert={toggleAlert}
          />
        );
      default:
    }
  };

  const redirectBack = () => {
    props.history.push(routerUrls.adminUsersPage.route);
  };

  return (
    <StyledBaseLayout
      usersLayout={true}
      backgroundNone={true}
      backgroundColor={styles.container.backgroundColor.turquoise}
    >
      <AdminHeader handleHamburgerClick={handleHamburgerClick} />{" "}
      <main>
        <MainContainer
          backgroundColor={styles.container.backgroundColor.turquoise}
        >
          <FullNameLabel font={"24px"}>{values.usersFullName}</FullNameLabel>

          <section
            className={
              !!values.lastClicked ? t("employee-page.transitioned") : null
            }
          >
            <ButtonContainer>
              <BasicRoundButton
                borderColor={styles.button.border.color.darkerBlue}
                onClick={handleClickEvent.bind(1)}
              >
                Data
              </BasicRoundButton>
              <BasicRoundButton
                borderColor={styles.button.border.color.darkerBlue}
                onClick={handleClickEvent.bind(2)}
              >
                Progress
              </BasicRoundButton>
              <BasicRoundButton
                borderColor={styles.button.border.color.darkerBlue}
                onClick={handleClickEvent.bind(3)}
              >
                Documents
              </BasicRoundButton>
              <BasicRoundButton
                borderColor={styles.button.border.color.darkerBlue}
                onClick={handleClickEvent.bind(4)}
              >
                Interview answers
              </BasicRoundButton>
              <BasicRoundButton
                borderColor={styles.button.border.color.darkerBlue}
                onClick={redirectBack}
              >
                <Image
                  src={running_man_logo}
                  alt=""
                  style={{ height: "2.2vw", width: "2.2vw" }}
                />{" "}
                All users
              </BasicRoundButton>
            </ButtonContainer>
          </section>

          <section
            className={
              !!values.lastClicked ? t("employee-page.transitioned") : null
            }
          >
            {renderSwitch(values.lastClicked)}
          </section>
        </MainContainer>
      </main>{" "}
      <CustomSnackbar
        open={values.alertOpen}
        closeAlert={closeAlert}
        message={values.alertMessage}
        severity={values.alertSeverity}
      />
    </StyledBaseLayout>
  );
};

EmployeePage.propTypes = {
  handleHamburgerClick: PropTypes.func,
};

export default EmployeePage;
