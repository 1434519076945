import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { HiDownload } from "react-icons/hi";
import { StyledDownloadButton } from "../../components/buttons/StyledDownloadButton.js";
import { StyledRoundUploadButton } from "../../components/buttons/StyledRoundUploadButton.js";
import { StyledUploadButton } from "../../components/buttons/StyledUploadButton.js";
import { BaseLayout } from "../../components/index.js";
import ModalWindow from "../../components/modal-window/ModalWindow.js";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper.js";
import TextContainer from "../../components/text-editor/TextContainer.js";
import { FileTypes } from "../../constants/FileTypes.js";
import { UserTypes } from "../../constants/UserTypes.js";
import { adminUrls, baseBackendUrl, urls } from "../../utils/baseUrl.js";
import deleteDataFromEndpoint from "../../utils/deleteDataFromEndpoint.js";
import downloadFileFromEndpoint from "../../utils/downloadFileFromEndpoint.js";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint.js";
import FileUploader from "../application-docs-page/FileUploader.js";
import { UserContext } from "../../utils/context/UserContext.js";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders page for uploading the rest of the documentation
 * @param  {string} to path to next page
 * @param  {string} from path to previous page
 * @param  {Array} data checkboxInfo data for the current page
 * @param  {Object} stepperData stepper pages data; consist of lists of page titles and page routes
 * @param  {function} toggleAlert toggle alert component with message
 * @param  {function} updateCheckboxInfoData send updated CheckboxInfo data to BE
 */
const OtherDocsPage = ({
  to,
  from,
  data,
  stepperData,
  toggleAlert,
  updateCheckboxInfoData,
}) => {
  const userData = useContext(UserContext);

  const [chosenUserType, setChosenUserType] = useState(
    userData.userType === UserTypes.ADMIN
      ? UserTypes.FULLTIME
      : userData.userType
  );
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [documentTemplates, setDocumentTemplates] = useState({
    FULLTIME: [],
    STUDENT: [],
  });
  const [documentToBeDeleted, setDocumentToBeDeleted] = useState({});
  const [deleteModalIsOpen, setDeleteModalToOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getDocumentTemplates();
    getUploadedDocuments();
  }, []);

  const handleDownloadClick = () => {
    downloadFileFromEndpoint(
      `${baseBackendUrl}/${urls.downloadTemplateDocsUrl}`
        .replace(":userType", chosenUserType)
        .replace(":fileType", FileTypes.OTHER_DOCUMENT),
      "Other-document-templates.zip",
      toggleAlert
    );
  };

  const getDocumentTemplates = async () => {
    const { data, errFlag } = await getDataFromEndpoint(
      adminUrls.documentTemplatesUrl.replace(
        ":fileType",
        FileTypes.OTHER_DOCUMENT
      )
    );
    if (!errFlag) {
      setDocumentTemplates({
        FULLTIME: data.filter((doc) => doc.userType === UserTypes.FULLTIME),
        STUDENT: data.filter((doc) => doc.userType === UserTypes.STUDENT),
      });
    } else {
      throw new Error(t("other-docs.error-fetch"));
    }
  };

  const getUploadedDocuments = async () => {
    const { data, errFlag } = await getDataFromEndpoint(
      urls.documentsUrl.replace(":fileType", FileTypes.OTHER_DOCUMENT)
    );
    if (!errFlag) {
      setUploadedDocuments(data);
    } else {
      throw new Error(t("other-docs.error-upload"));
    }
  };

  const fetchChosenUserType = (userType) => {
    setChosenUserType(userType);
  };

  const deleteDocument = () => {
    const { errFlag } = deleteDataFromEndpoint(
      urls.deleteDocumentUrl
        .replace(":fileType", documentToBeDeleted.fileType)
        .replace(":fileName", documentToBeDeleted.filename),
      {}
    );
    if (!errFlag) {
      setUploadedDocuments(
        uploadedDocuments.filter(
          (doc) => doc.filename !== documentToBeDeleted.filename
        )
      );
      toggleAlert(t("other-docs.success-delete", { documentToBeDeleted }));
    } else {
      toggleAlert(t("other-docs.error-document"), "error");
    }
  };

  const deleteDocumentTemplate = () => {
    const { errFlag } = deleteDataFromEndpoint(
      adminUrls.deleteDocumentTemplateUrl.replace(
        ":documentTemplateId",
        documentToBeDeleted.id
      ),
      {}
    );
    if (!errFlag) {
      setDocumentTemplates({
        FULLTIME:
          chosenUserType === UserTypes.FULLTIME
            ? documentTemplates["FULLTIME"].filter(
                (doc) => doc.id !== documentToBeDeleted.id
              )
            : documentTemplates["FULLTIME"],
        STUDENT:
          chosenUserType === UserTypes.STUDENT
            ? documentTemplates["STUDENT"].filter(
                (doc) => doc.id !== documentToBeDeleted.id
              )
            : documentTemplates["STUDENT"],
      });
      toggleAlert(t("other-docs.success-delete", { documentToBeDeleted }));
    } else {
      toggleAlert(t("other-docs.error-template"), "error");
    }
  };

  const handleDelete = () => {
    documentToBeDeleted.isDocumentTemplate
      ? deleteDocumentTemplate()
      : deleteDocument();
    toggleDeleteModal();
  };

  const toggleDeleteModal = (document, isDocumentTemplate) => {
    if (!!document) {
      document["isDocumentTemplate"] = isDocumentTemplate;
      setDocumentToBeDeleted(document);
    } else {
      setDocumentToBeDeleted({});
    }
    setDeleteModalToOpen(!deleteModalIsOpen);
  };

  let currentStep = 0;
  stepperData.routes.find((route, index) => {
    if (route.value === data[0].pageNumber) {
      currentStep = index;
    }
  });

  return (
    <BaseLayout from={from} to={to} hasTextEditor={true}>
      <HorizontalLinearStepper
        stepTitles={stepperData.titles}
        stepRoutes={stepperData.routes}
        currentStep={currentStep}
      />
      <TextContainer
        data={data}
        updateCheckboxInfoData={updateCheckboxInfoData}
        fetchChosenUserType={fetchChosenUserType}
      />
      <>
        {userData.userType === UserTypes.ADMIN && (
          <>
            <StyledRoundUploadButton>
              <FileUploader
                toggleAlert={toggleAlert}
                title={t("other-docs.title")}
                url={adminUrls.uploadDocumentTemplatesUrl.replace(
                  ":userType",
                  chosenUserType
                )}
                refetchDocuments={getDocumentTemplates}
                fileType={FileTypes.OTHER_DOCUMENT}
              />
              <br />
            </StyledRoundUploadButton>
            <span
              style={{ color: "#57ADDD", marginLeft: "1em", marginTop: "1em" }}
            >
              Uploaded:&nbsp;
              {documentTemplates[chosenUserType].map((doc, i) => {
                return (
                  <button
                    onClick={() => toggleDeleteModal(doc, true)}
                    style={{
                      background: "transparent",
                      color: "#57ADDD",
                      borderColor: "transparent",
                    }}
                    title="Delete this document"
                  >
                    {doc.name} &times;
                    {documentTemplates.length > i + 1 ? "," : null}
                  </button>
                );
              })}
            </span>
            <br />
          </>
        )}
      </>
      <StyledDownloadButton onClick={() => handleDownloadClick()}>
        <HiDownload /> Other docs
      </StyledDownloadButton>
      <StyledUploadButton labelFontSize={"1.1em"}>
        <FileUploader
          toggleAlert={toggleAlert}
          url={urls.uploadDocumentUrl}
          refetchDocuments={getUploadedDocuments}
          label={t("other-docs.label")}
          fileType={FileTypes.OTHER_DOCUMENT}
        />
      </StyledUploadButton>
      <span style={{ color: "#57ADDD", marginLeft: "1em", marginTop: "1em" }}>
        Uploaded:&nbsp;
        {uploadedDocuments.map((doc, i) => {
          return (
            <button
              onClick={() => toggleDeleteModal(doc, false)}
              style={{
                background: "transparent",
                color: "#57ADDD",
                borderColor: "transparent",
              }}
              title="Delete this document"
            >
              {doc.filename} &times;
              {uploadedDocuments.length > i + 1 ? "," : null}
            </button>
          );
        })}
      </span>
      <ModalWindow
        headerText={
          <h4>
            <strong>{t("other-docs.question")}</strong>
          </h4>
        }
        isOpen={deleteModalIsOpen}
        hideModal={toggleDeleteModal}
        toDoIfConfirmed={() => handleDelete()}
        body={
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {documentToBeDeleted.name || documentToBeDeleted.filename}
          </div>
        }
        cancelText={t("other-docs.cancel")}
        confirmText={t("other-docs.delete")}
      />
    </BaseLayout>
  );
};
OtherDocsPage.prototype = {
  from: PropTypes.string,
  to: PropTypes.string,
  data: PropTypes.array.isRequired,
  stepperData: PropTypes.object.isRequired,
  toggleAlert: PropTypes.func,
  updateCheckboxInfoData: PropTypes.func.isRequired,
};

export default OtherDocsPage;
