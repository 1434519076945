import React from "react";
import PropTypes from "prop-types";
import { HoverDeleteX } from "../buttons/hover-delete-x/HoverDeleteX";
import whiteTransparentX from "../../assests/images/X-icon.png";
import { StyledMilestone } from "./StyledMilestone";
import { StyledMilestoneText } from "./StyledMilestoneText";
import routerUrls from "../../utils/routerUrls";
import { StyledMilestoneLink } from "./StyledMilestoneLink";

const MilestoneList = ({ milestones, deleteMilestone }) => {
  return (
    <>
      {milestones.map((milestone) => (
        <StyledMilestone key={milestone.id}>
          <StyledMilestoneText>
            <StyledMilestoneLink
              to={routerUrls.adminUsersProgressPage.route.replace(
                ":userId",
                milestone.userId
              )}
            >
              {milestone.message}
            </StyledMilestoneLink>
          </StyledMilestoneText>
          <HoverDeleteX
            src={whiteTransparentX}
            onClick={() => deleteMilestone(milestone.id)}
          />
        </StyledMilestone>
      ))}
    </>
  );
};

MilestoneList.propTypes = {
  milestones: PropTypes.array.isRequired,
  deleteMilestone: PropTypes.func.isRequired,
};

export default MilestoneList;
