import { Box, Card, CardContent, Modal } from "@mui/material";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { CardImg, Carousel, Col, Container, Image, Row } from "react-bootstrap";
import { GiCancel } from "react-icons/gi";
import { HashLink as Link } from "react-router-hash-link";
import { homePageInfo, homePageText } from "../../__cms__/HomePageInfo.js";
import picture3 from "../../assests/images/ComsystoReply_Worldcloud.png";
import logo from "../../assests/images/logo.png";
import picture1 from "../../assests/images/summer_event_berlin_2022.jpg";
import picture2 from "../../assests/images/summer_event_berlin_2022_2.JPG";
import { Footer } from "../../components";
import ShowBlog from "../../components/blogs/ShowBlog.js";
import { StyledDownloadButton } from "../../components/buttons/StyledDownloadButton.js";
import { StyledHomePageHeader } from "../../components/header/StyledHomePageHeader";
import { ScrollContainer } from "../scroll-container/ScrollContainer";
import { StlyledHomepage } from "./StyledHompage";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders Homepage
 */
const Homepage = ({}) => {
  const [isModalOpen, setModalToOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [blogs, setBlogs] = useState(null);
  const sanitizedHTML = DOMPurify.sanitize(modalDescription, {
    USE_PROFILES: { html: true },
  });
  const { t } = useTranslation();

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = () => {
    return fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/comsystoreply"
    )
      .then((resp) => resp.json())
      .then((blogs) => setBlogs(blogs));
  };

  const handleClose = () => {
    setModalToOpen(!isModalOpen);
  };

  const modalStyle = {
    padding: "1em",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign: "justify",
    whiteSpace: "pre-wrap",
  };

  const openModal = (project) => {
    setModalToOpen(!isModalOpen);
    setModalTitle(project.title);
    setModalDescription(project.descriptionHtml);
  };

  return (
    <StlyledHomepage backgroundImage={`url(${picture1})`}>
      <div className={"backgroundContent"}>
        <StyledHomePageHeader>
          <div>
            <img src={logo} alt="logo" />
          </div>
          <Link to="#about">
            <h4>{t("homepage.about")}</h4>
          </Link>
          <Link to="#projects">
            <h4>{t("homepage.projects")}</h4>
          </Link>
          <a href="https://medium.com/comsystoreply">
            <h4>{t("homepage.blog")}</h4>
          </a>
        </StyledHomePageHeader>

        <Container
          style={{
            padding: "4em 3em 10em 3em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Row>
            <Col md={12}>
              <span
                style={{
                  fontSize: "120px",
                  fontWeight: 1000,
                  color: "#004149",
                  lineHeight: "100%",
                }}
              >
                {t("homepage.welcome")}
                <br /> {t("homepage.onboard")}
              </span>
              <br />
              <h4>
                {t("homepage.join")}
                <a
                  href={"https://www.comsystoreply.com/"}
                  style={{ color: "#006ead" }}
                >
                  {" "}
                  {t("homepage.comsysto")}{" "}
                </a>
                {t("homepage.as")} <br /> {t("homepage.full-time")}
              </h4>
              <div>
                <Link to={"/onboarding"}>
                  <StyledDownloadButton
                    minWidth={"10em"}
                    margin={"1em 1em 0 0"}
                    color={"black"}
                    fontSize={"20px"}
                  >
                    {t("homepage.EE")}
                  </StyledDownloadButton>
                </Link>
                <Link to={"/onboarding"}>
                  <StyledDownloadButton
                    minWidth={"10em"}
                    margin={"1em 0 0 auto"}
                    color={"black"}
                    fontSize={"20px"}
                    background={"#FFFFFF"}
                    border={"1px solid #57ADDD"}
                  >
                    {t("homepage.student")}
                  </StyledDownloadButton>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <h2
        id={"about"}
        style={{ color: "#57ADDD", margin: "2em 0em 0.5em 5em" }}
      >
        <strong> {t("homepage.about-CS")}</strong>
      </h2>
      <Container
        style={{ paddingBottom: "3em", textAlign: "justify", fontSize: "20px" }}
      >
        <Row md={12}>
          <Col md={6}>
            <div style={{ padding: "1em", marginBottom: "2em" }}>
              {homePageText[0]}
            </div>
            <div>
              <Image
                src={picture3}
                alt={"Comsysto WordCloud"}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  display: "block",
                }}
              />
            </div>
          </Col>
          <Col md={6}>
            <div>
              <Image
                src={picture2}
                alt={"Sommer Event Berlin 2022"}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  display: "block",
                }}
              />
            </div>
            <div style={{ padding: "1em", paddingTop: "1.5em" }}>
              {homePageText[1]}
            </div>
          </Col>
        </Row>
      </Container>
      <hr style={{ margin: "0" }} />

      <div style={{ backgroundColor: "rgba(196, 196, 196, 0.15)" }}>
        <h2
          id={"projects"}
          style={{ color: "#57ADDD", padding: "2em 0em 0em 5em" }}
        >
          <strong>{t("homepage.projects")}</strong>
        </h2>

        <div
          style={{
            padding: "2em 0",
            paddingBottom: "5em",
            marginTop: "2em",
          }}
          className={"projects-container"}
        >
          <Carousel key={0}>
            {homePageInfo.map((projectGroup) => {
              return (
                <Carousel.Item interval={3500}>
                  <Row>
                    {projectGroup.projectGroup.map((project) => {
                      return (
                        <Col lg={4} md={4}>
                          <Card
                            style={{ padding: "1em", margin: "1em" }}
                            className={"project-card"}
                          >
                            <CardImg src={Object.values(project.imageSrc)} />
                            <CardContent
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <h5
                                style={{
                                  color: "#57ADDD",
                                  textAlign: "center",
                                }}
                              >
                                <>{project.title}</>
                              </h5>
                              {project.description}
                            </CardContent>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <StyledDownloadButton
                                minWidth={"7em"}
                                onClick={() => openModal(project)}
                              >
                                {t("homepage.details")}
                              </StyledDownloadButton>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>

      <h2
        id={"blogs"}
        style={{ color: "#57ADDD", margin: "2em 0em 0.5em 5em" }}
      >
        <strong>{t("homepage.medium")}</strong>
      </h2>
      <Container style={{ textAlign: "justify", fontSize: "1em" }}>
        <div
          style={{ display: "flex", justifyContent: "right", color: "#57ADDD" }}
        >
          <a href={"https://medium.com/comsystoreply"}>{t("homepage.all")}</a>
        </div>
        <Row>
          {blogs?.items &&
            blogs.items
              .slice(0, 3)
              .map((post, index) => (
                <ShowBlog key={index} {...post} {...index} />
              ))}
        </Row>
      </Container>

      <hr style={{ margin: "0" }} />
      <Footer />
      <div>
        <Modal
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <GiCancel
                size={25}
                style={{ cursor: "pointer" }}
                onClick={() => setModalToOpen(!isModalOpen)}
              />
            </div>
            <h3 style={{ color: "#57ADDD" }}>
              <strong>{modalTitle}</strong>
            </h3>
            <br />
            <ScrollContainer>
              <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
            </ScrollContainer>
          </Box>
        </Modal>
      </div>
    </StlyledHomepage>
  );
};

export default Homepage;
