import styled from "styled-components";

export const ButtonContainer = styled.div`
  grid-column: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 380px;
  min-height: 40vh;
  place-self: stretch;
  padding: 2vh;
  margin: 20px;
`;
