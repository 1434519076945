import React from "react";
import PropTypes from "prop-types";
import { BlueCheckboxWrapper } from "./BluecheckboxWrapper";

const BlueCheckbox = ({ id, checkedState, handleClick }) => (
  <BlueCheckboxWrapper>
    <input
      type="checkbox"
      id={id}
      name={id.toString()}
      checked={checkedState}
      onChange={() => handleClick(id)}
    />
  </BlueCheckboxWrapper>
);

BlueCheckbox.propTypes = {
  id: PropTypes.number.isRequired,
  checkedState: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default BlueCheckbox;
