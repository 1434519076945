import styled from "styled-components";

export const StyledRoundUploadButton = styled.div`
  button {
    background: rgba(196, 196, 196, 0.15);
    box-shadow: 0px 5.38775px 12.1224px rgba(0, 0, 0, 0.25);
    border-radius: 0.35rem;
    border: 0px;
    padding-top: 0.5em;
    padding-left: auto;
    padding-bottom: 0.5em;
    padding-right: auto;
    margin: 1em;
    min-width: 16em;
    color: black;
  }
`;
