import styled from "styled-components";
import Accordion from "@material-ui/core/Accordion";
import { styles } from "../../constants/Constants";

const BORDER_RADIUS = "10px";

export const StyledAccordion = styled(Accordion)`
  // MuiAccordion & MuiPaper are default styles which need to be overridden to set our styles

  &.MuiPaper-outlined {
    border: 2px solid ${styles.button.border.color.lightBlue};
  }

  &.MuiAccordion-rounded:first-child {
    border-top-left-radius: ${BORDER_RADIUS};
    border-top-right-radius: ${BORDER_RADIUS};
  }

  &.MuiAccordion-rounded:last-child {
    border-bottom-left-radius: ${BORDER_RADIUS};
    border-bottom-right-radius: ${BORDER_RADIUS};
  }

  &.MuiAccordion-rounded {
    border-radius: ${BORDER_RADIUS};
  }

  margin: 4% 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
