import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";

const modalBodyStyle = {
  justifyContent: "space-evenly",
  border: "none",
  overflow: "hidden",
  fontSize: "1.2em",
  paddingLeft: "1.5em",
  width: "auto",
};

const headerStyle = {
  justifyContent: "center",
  border: "none",
  padding: "1.5em",
};

const modalFooterStyle = {
  justifyContent: "space-evenly",
  border: "none",
  display: "flex",
  overflow: "hidden",
  padding: "1em 1.5em",
  marginLeft: "auto",
};

/**
 * @description
 * To implement the modal window first you need to create a state in the parent component named modalIsOpen and set it to false.
 * Then you need to add the toggleModal function:
   toggleModal = () => {
    this.setState({modalIsOpen: !this.state.modalIsOpen})
    }
 * Call the function where you need it (after a click on button) to activate the modal:  this.toggleModal();
 * Lastly add the ModalWindow component to the render:
   <ModalWindow
     headerText={"Deleting a user"}
     isOpen={this.state.modalIsOpen}
     hideModal={this.toggleModal}
     toDoIfConfirmed={() => {function; this.toggleModal()}}
   />
 */

const ModalWindow = ({
  headerText,
  isOpen,
  hideModal,
  toDoIfConfirmed,
  body,
  cancelText,
  confirmText,
}) => {
  return (
    <Modal show={isOpen} onHide={hideModal} centered>
      <ModalHeader style={headerStyle} closeButton>
        <Modal.Title>{headerText}</Modal.Title>
      </ModalHeader>
      <ModalBody style={modalBodyStyle}>{body}</ModalBody>

      <ModalFooter style={modalFooterStyle}>
        <Button
          variant="outline-info"
          style={{ minWidth: "6em" }}
          onClick={() => hideModal()}
        >
          {cancelText}
        </Button>
        <Button
          variant="info"
          style={{ minWidth: "6em" }}
          onClick={() => toDoIfConfirmed()}
        >
          {confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalWindow;
