const prodHomepageUrl = "https://onboarding.comsysto.com";
const developHomepageUrl = "https://dev.onboarding.comsysto.com";
const localHomepageUrl = "http://localhost:3000";

let baseUrl = "";
let backendRedirectUrl = "";
let baseBackendUrl = "";

const { NODE_ENV } = process.env;
const { href } = window.location;

const isProd = NODE_ENV === "production" && href.includes(prodHomepageUrl);
const isDev = NODE_ENV === "production" && href.includes(developHomepageUrl);
const isLocal = NODE_ENV === "development" && href.includes(localHomepageUrl);

if (isLocal) {
  baseUrl = "http://localhost:3000"; // local
  backendRedirectUrl = "http://localhost:8080/api"; // local
  baseBackendUrl = "http://localhost:8080/api"; // local
} else if (isDev) {
  baseUrl = "https://dev.onboarding.comsysto.com"; // dev
  backendRedirectUrl = "https://dev.onboarding.comsysto.com/api"; // dev
  baseBackendUrl = "https://dev.onboarding.comsysto.com/api"; // dev
} else if (isProd) {
  baseUrl = "https://onboarding.comsysto.com"; // prod
  backendRedirectUrl = "https://onboarding.comsysto.com/api"; // prod
  baseBackendUrl = "https://onboarding.comsysto.com/api"; // prod
}

export { baseUrl, backendRedirectUrl, baseBackendUrl };

export const urls = {
  userUrl: "user/me",
  checkboxesUrl: "checkbox",
  progressUrl: "checkbox/progress",
  userDataUrl: "user",
  downloadTemplateDocsUrl: "document-template/download/:userType/:fileType",
  documentsUrl: "file/document/:fileType",
  uploadDocumentUrl: "file/document",
  deleteDocumentUrl: "file/document/:fileType/:fileName",
  userAccountInfoUrl: "account-info/:userId",
  userAccountInfoLaptopUrl: "account-info/laptop/:userId",
  userAccountInfoHardwareUrl: "account-info/hardware/:userId",
  answerUrl: "answer",
  answersSentUrl: "account-info/send-answers-to-admin",
  getPictureUrl: "file/picture/download/:userId/:fileType",
  uploadPictureUrl: "file/picture",
  formFieldAnswers: "personal-data/answers",
};
export const adminUrls = {
  userDataUrl: "user",
  completedCheckboxesByUsersIdUrl: "checkbox/userId",
  milestonesUrl: "milestone",
  getDocumentsByUserId: "file/admin/document/:userId",
  getDocumentsByFileTypeAndUserId: "file/admin/document/:fileType/:userId",
  downloadDocumentUrl: "file/document/download/:fileType/:fileName",
  downloadDocumentsForUserUrl: "file/document/download-all/:fileType/:userId",
  sendMessageToUserOnSlackUrl: "message/user/userId",
  answerUrl: "answer/:userId",
  answerApprovalUrl: "account-info/answers-approval/userId",
  sendAnswersAndPictureToSlackUrl: "message/userId",
  getSlackPictureUrl: "message/picture/userId",
  answersSentToAdminUrl: "account-info/send-answers-to-admin/userId",
  checkboxInfoUrl: "checkboxinfo",
  documentTemplatesUrl: "document-template/:fileType",
  uploadDocumentTemplatesUrl: "document-template/:userType",
  deleteDocumentTemplateUrl: "document-template/:documentTemplateId",
  questionUrl: "question",
  multipleQuestionsUrl: "question/multiple",
  formFieldsUrl: "personal-data/fields",
};
