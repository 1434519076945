import axios from "axios";
import { baseBackendUrl } from "./baseUrl";

import { ACCESS_TOKEN } from "../constants/Constants";
const config = {
  headers: {
    "Content-Type": "application/json",
    userId: "user123",
    Authorization: "",
  },
};

const createReturnValue = (errFlag, data) => ({
  errFlag,
  data,
});
/**
 * @function
 * @description Function is used to put data recieved to the recieved endpoint
 *
 * It returns an error flag and the data recieved from the backend∑
 *
 * @param {string} url Url of the backend endpoint
 * @param {*} data Data being put to backend
 */
const putDataToEndpoint = async (url, data) => {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    config.headers.Authorization = `JWT ${localStorage.getItem(ACCESS_TOKEN)}`;
  }
  try {
    const response = await axios({
      method: "put",
      headers: config.headers,
      url: `${baseBackendUrl}/${url}`,
      data: data,
    });
    return createReturnValue(false, response.data);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        return createReturnValue(true, 401);
      } else {
        return createReturnValue(true, error.response);
      }
    }
    throw new Error("putDataToEndpoint error");
  }
};

export default putDataToEndpoint;
