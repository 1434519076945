import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints";

export const Main = styled.main`
  @font-face {
    font-family: "proxima_nova_rgregular";
    src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot");
    src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff")
        format("woff"),
      url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf")
        format("truetype"),
      url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  font-family: "proxima_nova_rgregular";

  padding: ${({ mainPadding }) => mainPadding || "0 20% 2% 15%"};
  min-height: 75vh;

  ${({ hasTextEditor }) => {
    return hasTextEditor ? null : "color:black";
  }}

  label {
    width: 100%;
    padding: 0px 5px;
    color: black;
  }
  p {
    margin: 0;
    ${({ indentP }) => {
      return indentP ? "margin-left:55px; p > p { margin: 0;}" : null;
    }};
  }
  p > p {
    margin: 0;
  }
  & > div p:last-child {
    ${({ allignLastPRight }) => allignLastPRight && "place-self:center end;"}
  }

  aside {
    display: grid;
    height: 56vh;
    grid-column: span 2;
    grid-column-end: -1;
    grid-template-columns: 1fr;
    align-items: center;
    flex-direction: column;
  }
  aside:nth-child(n) {
    width: 100%;
  }
  div {
    ${({ hasTextEditor }) => {
      return hasTextEditor
        ? null
        : "display: grid; grid-gap: 30px; grid-auto-columns: 1fr;";
    }}

    align-items: center;
    ${({ alignItemsTop }) => {
      return alignItemsTop
        ? "grid-auto-columns: auto;grid-gap:20px; align-content:start;"
        : null;
    }};
  }

  span {
    ${({ hasTextEditor }) => {
      return hasTextEditor
        ? null
        : "font-size: 1.4em; color: white; display: block; margin-top: 30px;";
    }}
  }
  br {
    display: block;
    margin-bottom: 15px;
  }

  .routeButtonsContainer {
    display: grid;
    margin: 1em;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    font-size: 0.8em;
    .indent {
      margin-left: 5px;
    }
    br {
      margin-bottom: 5px;
    }
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    font-size: 1.1em;
  }
  @media screen and (min-width: ${breakpoints.xlg}px) {
    font-size: 1.5em;
    min-height: 80vh;
    h2 {
      font-size: 1.7em;
    }
    h3 {
      font-size: 1.5em;
    }
  }
`;
