import styled from "styled-components";
import { BasicButton } from "./BasicButton";
import { styles } from "../../constants/Constants";

export const StyledBasicRoundButton = styled(BasicButton)`
  border-radius: 20px;
  color: black;
  max-width: ${(props) => (props.width ? props.width : "100%")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "50px")};
  padding: 15px;
  border: ${(props) =>
    "4px solid " + props.borderColor || styles.button.border.color.lightBlue};
  position: relative;
  margin-top: 4vh;
  font-size: large;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : styles.button.color.lightBlue};

  img {
    margin-right: 1.5%;
    width: 20%;
  }
`;
