import React from "react";
import { StyledFooter } from "./StyledFooter";
import RouteButton from "../buttons/route-button/RouteButton";
import PropTypes from "prop-types";
import logo from "../../assests/images/logo.png";
import { footerInfo } from "../../__cms__/footerInfo";

/**
 * @component
 * @description Component renders footer with 0-2 {@link RouteButton}, logo and footer info
 * @param  {string} from Path to previous page
 * @param  {string} to Path to next page
 */
const Footer = ({ from, to }) => {
  return (
    <StyledFooter>
      {from && <RouteButton dir={0} to={from} />}
      {to && <RouteButton dir={1} to={to} />}
      <img src={logo} alt="#" />

      {footerInfo.map((column, index) => {
        return (
          <div key={index}>
            {column.columnItems.map((columnItem, i) => (
              <div key={i}>
                <a href={columnItem.link}>{columnItem.text}</a>
                {columnItem.icons}
                <br />
              </div>
            ))}
          </div>
        );
      })}
    </StyledFooter>
  );
};
Footer.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
};
export default Footer;
