import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints";
export const StyledShortcut = styled.a`
  position: relative;
  padding: 30px;
  display: grid;
  border-radius: 27px;
  justify-self: stretch;
  align-self: stretch;
  text-decoration: none;
  text-align: center;
  :before {
    content: "";
    position: absolute;
    display: flex;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    background: rgba(219, 249, 247, 0.62);
    background-blend-mode: overlay;
    mix-blend-mode: soft-light;
    border: 2px solid #4fd5d7;
    border-radius: 27px;
    justify-self: center;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    :before {
      background-color: rgba(219, 249, 247, 1);
      transform: scale(0.7);
      align-self: center;
      justify-self: center;
    }
    :after {
      content: "${({ text }) => text}";
      position: absolute;
      display: grid;
      align-items: center;
      justify-items: center;
      color: black;
      font-size: 0.7em;
      text-align: center;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: 51;
      word-wrap: break-word;
      line-height: 1.5em;
    }
    h1 {
      visibility: hidden;
    }
  }

  h1 {
    display: block;
    text-decoration: none;
    color: black;
    width: 100%;
    justify-self: center;
    align-self: center;
    z-index: 1;
    width: auto;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    &:hover:after {
      font-size: 1.1em;
    }
    padding: 10px;
    :after,
    :before {
      padding: 10px;
    }
    h1 {
      font-size: 1.6em;
    }
  }
  @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.lg}px) {
    &:hover:after {
      font-size: 1.3em;
    }
    h1 {
      font-size: 1.7em;
    }
  }
  @media (min-width: ${breakpoints.lg}px) {
    &:hover:after {
      font-size: 1.7em;
    }
    h1 {
      font-size: 2em;
    }
  }
`;
