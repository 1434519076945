import React, { Component } from "react";
import ErrorMessage from "../../components/error-message/ErrorMessage";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  render() {
    if (this.state.hasError) {
      return <ErrorMessage fullscreen={true} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
