import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BaseLayout } from "../../components";
import FormField from "../../components/form-field/FormField";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper";
import TextContainer from "../../components/text-editor/TextContainer.js";
import { urls } from "../../utils/baseUrl";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint";
import postDataToEndpoint from "../../utils/postDataToEndPoint";
import { StyledButtonsContainer } from "../buttons-container/StyledButtonsContainer";
import { ScrollContainer } from "../scroll-container/ScrollContainer";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders Trello page
 * @param  {string} to path to next page
 * @param  {string} from  path to previous page
 * @param  {Array} data CheckboxInfo data for the current page
 * @param  {Object} stepperData stepper pages data; consist of lists of page titles and page routes
 * @param  {function} updateCheckboxInfoData send updated checkboxInfo data to BE
 */
const PersonalDataPage = ({
  to,
  from,
  data,
  stepperData,
  toggleAlert,
  updateCheckboxInfoData,
}) => {
  const [formAnswers, setFormAnswers] = useState([]);
  const [error, setError] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    getFormAnswers();
  }, []);

  const getFormAnswers = async () => {
    try {
      const { data, errFlag } = await getDataFromEndpoint(
        `${urls.formFieldAnswers}`
      );
      if (errFlag) {
        throw new Error(t("personal-data.data-error"));
      }
      setFormAnswers(data);
    } catch (err) {
      setError(true);
    }
  };

  const handleAnswerChange = (value, index) => {
    formAnswers[index].answer = value;
    setFormAnswers(formAnswers);
  };

  const prepareAnswersToSend = () => {
    const tempAnswers = [];
    let i = 0;
    for (const f of formAnswers) {
      tempAnswers.push({ formFieldId: f.formField.id, answer: f.answer });
      i++;
    }
    return JSON.stringify(tempAnswers);
  };

  const saveAnswers = async () => {
    //izvuci formFieldId i answere za njih
    const tempFieldsAndAnswers = prepareAnswersToSend();
    try {
      const { data, errFlag } = await postDataToEndpoint(
        `${urls.formFieldAnswers}`,
        JSON.parse(tempFieldsAndAnswers)
      );
      if (!errFlag) {
        toggleAlert(t("personal-data.answers-saved"));
        return data;
      } else {
        toggleAlert(data.data.message, "error");
        throw new Error(t("personal-data.data-error"));
      }
    } catch (err) {
      setError(err);
    }
  };

  const handleSave = (event) => {
    saveAnswers();
  };

  return (
    <BaseLayout
      from={from}
      to={to}
      hasTextEditor={true}
      onBack={handleSave}
      onNext={handleSave}
    >
      <HorizontalLinearStepper
        stepTitles={stepperData.titles}
        stepRoutes={stepperData.routes}
        currentStep={data[0].orderOnStepper - 1}
      >
        <TextContainer
          data={data}
          updateCheckboxInfoData={updateCheckboxInfoData}
        >
          <React.Fragment>
            <ScrollContainer>
              {formAnswers.map((f, index) => {
                return (
                  <FormField
                    marginLeft={"6vw"}
                    marginRight={"20vw"}
                    key={index}
                    formFieldLabel={f.formField.label}
                    formFieldHint={f.formField.hint}
                    answerFieldType={f.formField.fieldType}
                    answer={f.answer}
                    onChangeAnswer={(newAnswer) =>
                      handleAnswerChange(newAnswer, index)
                    }
                  />
                );
              })}
            </ScrollContainer>
            <StyledButtonsContainer>
              <Button variant={"outline-info"} onClick={handleSave}>
                {t("personal-data.save")}
              </Button>
            </StyledButtonsContainer>
          </React.Fragment>
        </TextContainer>
      </HorizontalLinearStepper>
    </BaseLayout>
  );
};

PersonalDataPage.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string,
  data: PropTypes.array.isRequired,
  stepperData: PropTypes.object.isRequired,
  updateCheckboxInfoData: PropTypes.func.isRequired,
};

export default PersonalDataPage;
