import styled from "styled-components";
import { styles } from "../../../constants/Constants";
import React from "react";

const StyledOvalButton = styled.button`
  border-width: 2px;
  border-radius: 10px;
  border-style: solid;
  font-size: medium;
  padding-top: 5px;
  padding-bottom: 5px;
  ${(props) => props}
`;

export const OvalButton = ({
  backgroundColor = styles.button.color.lightBlue,
  borderColor = styles.button.border.color.lightBlue,
  leftAndRightPadding,
  ...props
}) => {
  return (
    <StyledOvalButton
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      padding-left={leftAndRightPadding}
      padding-right={leftAndRightPadding}
    >
      {props.children}
    </StyledOvalButton>
  );
};
