import { Button } from "react-bootstrap";
import React from "react";
import { UserTypes } from "../../constants/UserTypes";
import PropTypes from "prop-types";

/**
 * @component
 * @description Component renders Edit, Save and UserType(FULLTIME/STUDENT) buttons for Admin text editor
 * @param  {function} handleEditClick handle click on Edit button
 * @param  {function} handleSaveClick handle click on Save button
 * @param  {function} handleUserTypeClick handle click on FULLTIME/STUDENT button
 * @param  {string} chosenUserType chosen user type
 * @param  {boolean} onlyFullTimeView show only FULL-TIME user view
 * @param  {boolean} isEditMode is edit mode on
 */
const TextEditorButtons = ({
  handleEditClick,
  handleSaveClick,
  handleUserTypeClick,
  chosenUserType,
  onlyFullTimeView,
  isEditMode,
}) => {
  return (
    <div style={{ display: "flex", marginBottom: "1em" }}>
      <Button
        variant="outline-info"
        active={chosenUserType === UserTypes.FULLTIME}
        onClick={() => handleUserTypeClick(UserTypes.FULLTIME)}
        style={{
          marginRight: "0.5em",
          boxShadow:
            chosenUserType === UserTypes.FULLTIME
              ? "0px 4px 19px rgba(0, 0, 0, 0.25)"
              : null,
        }}
      >
        {UserTypes.FULLTIME}
      </Button>
      <Button
        variant="outline-info"
        hidden={onlyFullTimeView}
        active={chosenUserType === UserTypes.STUDENT}
        onClick={() => handleUserTypeClick(UserTypes.STUDENT)}
        style={{
          boxShadow:
            chosenUserType === UserTypes.STUDENT
              ? "0px 4px 19px rgba(0, 0, 0, 0.25)"
              : null,
        }}
      >
        {UserTypes.STUDENT}
      </Button>
      <Button
        variant="secondary"
        style={{
          marginLeft: "auto",
          marginRight: "0.5em",
          boxShadow: !isEditMode ? "0px 4px 19px rgba(0, 0, 0, 0.25)" : null,
          minWidth: "4.5em",
        }}
        onClick={() => handleEditClick()}
      >
        {!isEditMode ? "Edit" : "Cancel"}
      </Button>
      <Button
        variant="info"
        disabled={!isEditMode}
        style={{
          boxShadow: isEditMode ? "0px 4px 19px rgba(0, 0, 0, 0.25)" : null,
          minWidth: "4.5em",
        }}
        onClick={() => handleSaveClick()}
      >
        Save
      </Button>
    </div>
  );
};

TextEditorButtons.propTypes = {
  handleEditClick: PropTypes.func,
  handleSaveClick: PropTypes.func,
  handleUserTypeClick: PropTypes.func,
  chosenUserType: PropTypes.string,
  onlyFullTimeView: PropTypes.bool,
  isEditMode: PropTypes.bool,
};

export default TextEditorButtons;
