import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import BaseLayout from "../../components/base-layout/BaseLayout";
import DragAndDropCanvas from "../../components/drag-and-drop-canvas/DragAndDropCanvas.js";
import DragAndDropItem from "../../components/drag-and-drop-canvas/DragAndDropItem";
import ModalWindow from "../../components/modal-window/ModalWindow";
import { adminUrls } from "../../utils/baseUrl";
import deleteDataFromEndpoint from "../../utils/deleteDataFromEndpoint";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint";
import postDataToEndpoint from "../../utils/postDataToEndPoint";
import putDataToEndpoint from "../../utils/putDataToEndPoint";
import { StyledTextContainer } from "../text-container/StyledTextContainer";
import FormFieldsContainer from "./FormFieldsContainer";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders Personal Data Form fields and enables editing, deleting, creating new fields and changing fields order using drag and drop
 * @param  {function} toggleAlert toggle alert component
 */
const EditFormFieldsPage = ({ toggleAlert }) => {
  const [fields, setFields] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [newFieldMode, setNewFieldMode] = useState(false);
  const [newFieldLabel, setNewFieldLabel] = useState("");
  const [newFieldType, setNewFieldType] = useState("SHORT_TEXT");
  const [newFieldHint, setNewFieldHint] = useState("");
  const [fieldToBeDeleted, setFieldToBeDeleted] = useState({});
  const [isDeleteModalOpen, setDeleteModalToOpen] = useState(false);
  const [isFieldsOrderUpdated, setFieldsOrderToUpdated] = useState(false);
  const { t } = useTranslation();

  const fieldTypes = {
    SHORT_TEXT: t("edit-form-field.field-types.short-text"),
    LONG_TEXT: t("edit-form-field.field-types.long-text"),
    DATETIME: t("edit-form-field.field-types.datetime"),
    EMAIL: t("edit-form-field.field-types.email"),
    CHECKBOX: t("edit-form-field.field-types.checkbox"),
  };

  useEffect(() => {
    getFields();
  }, []);

  const getFields = async () => {
    setFields([]);
    const { data, errFlag } = await getDataFromEndpoint(
      `${adminUrls.formFieldsUrl}`
    );
    if (!errFlag) {
      setFields(data);
    } else {
      throw new Error(t("edit-form-field.error-fetch"));
    }
  };

  const handleSave = async () => {
    if (newFieldMode) await saveNewField();
    else await updateFields();
  };

  const saveNewField = async () => {
    const { data, errFlag } = await postDataToEndpoint(
      `${adminUrls.formFieldsUrl}`,
      JSON.stringify({
        label: newFieldLabel,
        fieldType: newFieldType,
        hint: newFieldHint,
      })
    );
    if (!errFlag) {
      setFields([data, ...fields]);
      setNewFieldMode(false);
      setNewFieldLabel("");
      setNewFieldHint("");
      setNewFieldType("SHORT_TEXT");
      toggleAlert(t("edit-form-field.success-create"));
    } else {
      throw new Error(t("edit-form-field.error-create"));
    }
  };

  const updateFieldsOrder = () => {
    return fields.map((field, i) => {
      field.orderOnPage = ++i * 100;
      return field;
    });
  };

  const updateFields = async () => {
    const { data, errFlag } = await putDataToEndpoint(
      `${adminUrls.formFieldsUrl}`,
      JSON.stringify(updateFieldsOrder())
    );
    if (!errFlag) {
      setFields(data);
      setEditMode(false);
      setFieldsOrderToUpdated(false);
      toggleAlert(t("edit-form-field.success-update"));
    } else {
      throw new Error(t("edit-form-field.error-update"));
    }
  };

  const handleEditFields = async () => {
    if (editMode) await getFields();
    if (isFieldsOrderUpdated) setFieldsOrderToUpdated(false);
    setEditMode(!editMode);
  };

  const handleNew = () => {
    setNewFieldMode(!newFieldMode);
  };

  const handleNewFieldHintChange = (event) => {
    setNewFieldHint(event.target.value);
  };

  const handleNewFieldLabelChange = (event) => {
    setNewFieldLabel(event.target.value);
  };

  const getFieldTypeShortcut = (value) => {
    return Object.keys(fieldTypes).find((key) => fieldTypes[key] === value);
  };

  const handleNewFieldTypeChange = (event) => {
    const fieldType = getFieldTypeShortcut(event.target.textContent);
    setNewFieldType(fieldType);
  };

  const handleFieldLabelChange = (newText, i) => {
    let updatedFields = fields;
    updatedFields[i - 1].label = newText;
    setFields(updatedFields);
  };

  const handleFieldHintChange = (newText, i) => {
    let updatedFields = fields;
    updatedFields[i - 1].hint = newText;
    setFields(updatedFields);
  };

  const handleFieldTypeChange = (selectedFieldType, i) => {
    const fieldType = getFieldTypeShortcut(selectedFieldType);
    let updatedFields = fields;
    updatedFields[i - 1].fieldType = fieldType;
    setFields(updatedFields);
  };

  const handleDeleteClick = (field) => {
    setFieldToBeDeleted(field);
    setDeleteModalToOpen(!isDeleteModalOpen);
  };

  const deleteField = async (id) => {
    const { errFlag } = await deleteDataFromEndpoint(
      `${adminUrls.formFieldsUrl}/${id}`,
      {}
    );
    if (!errFlag) {
      setFields(fields.filter((f) => f.id !== id));
      setDeleteModalToOpen(!isDeleteModalOpen);
      toggleAlert(t("edit-form-field.success-delete"), "error");
    } else {
      throw new Error(t("edit-form-field.error-delete"));
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModalToOpen(!isDeleteModalOpen);
  };

  const moveField = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = fields[dragIndex];
      const hoverItem = fields[hoverIndex];
      setFields((fields) => {
        const updatedFields = [...fields];
        updatedFields[dragIndex] = hoverItem;
        updatedFields[hoverIndex] = dragItem;
        return updatedFields;
      });
      setFieldsOrderToUpdated(true);
      setEditMode(true);
    },
    [fields]
  );

  return (
    <BaseLayout hasTextEditor={true}>
      <h2 style={{ color: "#57ADDD", fontWeight: "bold", marginBottom: "1%" }}>
        Edit Personal Data Fields
        <br />
      </h2>
      <div style={{ display: "flex", margin: "1em 0em" }}>
        <Button
          variant={"info"}
          onClick={handleNew}
          style={{ boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)" }}
        >
          {newFieldMode
            ? t("edit-form-field.cancel")
            : t("edit-form-field.new-field")}
        </Button>
        <Button
          variant={"outline-info"}
          onClick={handleEditFields}
          disabled={newFieldMode}
          style={{ marginLeft: "auto", minWidth: "5em", marginRight: "0.5em" }}
        >
          {editMode || isFieldsOrderUpdated
            ? t("edit-form-field.cancel")
            : t("edit-form-field.edit")}
        </Button>
        <Button
          variant={"info"}
          onClick={handleSave}
          style={{
            minWidth: "5em",
            boxShadow:
              editMode || isFieldsOrderUpdated
                ? "0px 4px 19px rgba(0, 0, 0, 0.25)"
                : null,
          }}
          disabled={
            newFieldLabel?.length === 0 && !editMode && !isFieldsOrderUpdated
          }
        >
          Save
        </Button>
      </div>
      <div>
        {newFieldMode && (
          <div style={{ gridColumn: "1/3" }}>
            <StyledTextContainer margin={"1.5em 6em 1.5em 0em"}>
              <div style={{ display: "flex" }}>
                <label>Add new field</label>
                <p style={{ fontSize: "13px" }}>Choose answer's format:</p>
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      color: "rgb(0,0,0)",
                      backgroundColor: "#f6f6f6",
                      border: "0px",
                    }}
                  ></Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.values(fieldTypes).map((value, i) => (
                      <Dropdown.Item onClick={handleNewFieldTypeChange} key={i}>
                        <div>{value}</div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                <textarea
                  onChange={handleNewFieldLabelChange}
                  value={newFieldLabel}
                  placeholder={t("edit-form-field.label")}
                  style={{ height: "50px" }}
                ></textarea>
                <textarea
                  onChange={handleNewFieldHintChange}
                  value={newFieldHint}
                  placeholder={t("edit-form-field.hint")}
                  style={{ height: "50px" }}
                ></textarea>
                <div style={{ fontSize: "13px" }}>
                  {t("edit-form-field.answer")} {fieldTypes[newFieldType]}
                </div>
              </div>
            </StyledTextContainer>
          </div>
        )}
        <DragAndDropCanvas>
          {fields.map((field, index) => {
            return (
              <DragAndDropItem
                key={field.id}
                index={index}
                moveListItem={moveField}
              >
                <FormFieldsContainer
                  field={field}
                  index={index}
                  editMode={editMode}
                  handleFieldLabelChange={handleFieldLabelChange}
                  handleFieldHintChange={handleFieldHintChange}
                  handleDeleteClick={handleDeleteClick}
                  fieldTypes={fieldTypes}
                  handleFieldTypeChange={handleFieldTypeChange}
                />
              </DragAndDropItem>
            );
          })}
        </DragAndDropCanvas>
      </div>
      <ModalWindow
        headerText={
          <h4>
            <b>{t("edit-form-field.question")}</b>
          </h4>
        }
        isOpen={isDeleteModalOpen}
        hideModal={toggleDeleteModal}
        toDoIfConfirmed={() => deleteField(fieldToBeDeleted.id)}
        body={
          <li>
            {fieldToBeDeleted.label}
            <div>{fieldToBeDeleted.hint}</div>
          </li>
        }
        cancelText={t("edit-form-field.cancel")}
        confirmText={t("edit-form-field.delete")}
      />
    </BaseLayout>
  );
};

EditFormFieldsPage.propTypes = {
  toggleAlert: PropTypes.func,
};
export default EditFormFieldsPage;
