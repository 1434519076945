import styled from "styled-components";
import { BasicButton } from "../../components/buttons/BasicButton";

export const BasicRoundButton = styled(BasicButton)`
  border-radius: 20px;
  color: black;
  max-width: ${(props) => (props.width ? props.width : "350px")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "-120px")};
  padding: 30px;
  border: ${(props) => "4px solid " + props.borderColor || "none"};
  position: relative;
  margin-top: 4vh;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};

  img {
    margin-right: 1.5%;
    width: 20%;
  }
`;
