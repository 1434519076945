export const stepStyle = {
  marginBottom: "1em",
  "& .MuiSvgIcon-root": {
    color: "#ffffff",
    borderStyle: "solid",
    borderColor: "rgb(0,0,0,0.6)",
    borderRadius: 12,
    borderWidth: 3,
    height: 24,
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: "#ffffff",
    borderStyle: "solid",
    borderColor: "rgb(0,0,0,0.6)",
    borderRadius: 15,
    borderWidth: 5,
    height: 24,
  },
  "& .MuiStepIcon-root.Mui-completed": {
    border: 0,
  },
};

export const completedAndActiveStepStyle = {
  "& .MuiStepIcon-root.Mui-active": {
    color: "#1976d2",
  },
};
