import PropTypes from "prop-types";
import React, { useState } from "react";
import delete_icon from "../../assests/images/delete_icon.png";
import AddUserFrom from "../../components/add-user-form/AddUserForm";
import BaseLayout from "../../components/base-layout/BaseLayout";
import { StyledRoundButton } from "../../components/buttons/StyledRoundButton";
import { OvalLinkButton } from "../../components/buttons/oval-link-button/OvalLinkButton";
import ModalWindow from "../../components/modal-window/ModalWindow";
import { TableContainer } from "../../components/talbe/Table";
import { styles } from "../../constants/Constants";
import { JobTitles } from "../../constants/JobTitles.js";
import { UserTypes } from "../../constants/UserTypes";
import routerUrls from "../../utils/routerUrls";
import { useTranslation } from "react-i18next";
/**
 * @Component Renders user page
 * @param  {array} users data about users
 * @param  {function} addUserHandler handler for adding users
 * @param  {function} changeUserType handler for changing user types
 * @param  {function} handleDeleteUser handler for deleting user
 * @param  {function} toggleAlert toggle alert message
 * @param  {function} changeJobTitle handler for updating job title for user
 */

const UsersPage = ({
  users,
  changeUserType,
  addUserHandler,
  handleDeleteUser,
  toggleAlert,
  changeJobTitle,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState();
  const [userToDelete, setUserToDelete] = useState();
  const { t } = useTranslation();

  const toggleModal = (userId) => {
    setModalIsOpen(!modalIsOpen);
    if (!modalIsOpen) {
      setUserIdToDelete(userId);
      setUserToDelete(users.find((user) => user.id === userId).name);
    }
  };

  const handleDelete = () => {
    handleDeleteUser(userIdToDelete, userToDelete);
    toggleModal();
  };

  return (
    <BaseLayout mainPadding={"0 10% 2% 10%"}>
      <main>
        <AddUserFrom addUserHandler={addUserHandler} />
        <TableContainer>
          <div>
            <table>
              <thead>
                <tr>
                  <th>{t("users-page.name")}</th>
                  <th>{t("users-page.role")}</th>
                  <th>{t("users-page.email")}</th>
                  <th>{t("users-page.job")}</th>
                </tr>
              </thead>
              <tbody>
                {!!users &&
                  users.map((user, i) => (
                    <tr key={`${i++}row`}>
                      <td>{user.name}</td>
                      <td>
                        <select
                          value={user.userType}
                          onChange={(e) => {
                            changeUserType(e, {
                              userId: user.id,
                              email: user.email,
                              onboarded: user.onboarded,
                              name: user.name,
                              jobTitle: user.jobTitle,
                            });
                          }}
                        >
                          <option value={UserTypes.ADMIN}>
                            {t("users-page.user-type.admin")}
                          </option>
                          <option value={UserTypes.FULLTIME}>
                            {t("users-page.user-type.employee")}
                          </option>
                          <option value={UserTypes.STUDENT}>
                            {t("users-page.user-type.student")}
                          </option>
                        </select>
                      </td>
                      <td>{user.email}</td>
                      <td>
                        <select
                          value={user.jobTitle}
                          onChange={(e) => {
                            changeJobTitle(user, e.target.value);
                          }}
                        >
                          {JobTitles.map((jobTitle, i) => {
                            return (
                              <option key={i} value={jobTitle}>
                                {jobTitle}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <OvalLinkButton
                          to={routerUrls.userProfilePageAdminView.route.replace(
                            ":userId",
                            user.id
                          )}
                          leftAndRightPadding={"15px"}
                          borderColor={"rgb(36,133,156, 0.3)"}
                        >
                          {t("users-page.profile-button")}
                        </OvalLinkButton>
                      </td>
                      <td>
                        <StyledRoundButton
                          width={"100%"}
                          borderWidth={"2px"}
                          backgroundColor={styles.button.color.lightBlue}
                          borderColor={"rgb(36,133,156, 0.3)"}
                          onClick={() => toggleModal(user.id)}
                        >
                          <img src={delete_icon} alt="" />
                        </StyledRoundButton>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <ModalWindow
                headerText={
                  <h4>
                    <strong>{t("users-page.delete-message")}</strong>
                  </h4>
                }
                isOpen={modalIsOpen}
                hideModal={toggleModal}
                toDoIfConfirmed={() => handleDelete()}
                body={
                  <div style={{ justifyContent: "center", display: "flex" }}>
                    <h3>{userToDelete}</h3>
                  </div>
                }
                cancelText={t("users-page.cancel")}
                confirmText={t("users-page.confirm")}
              />
            </table>
          </div>
        </TableContainer>
      </main>
    </BaseLayout>
  );
};

UsersPage.propTypes = {
  changeUserType: PropTypes.func.isRequired,
  addUserHandler: PropTypes.func.isRequired,
  users: PropTypes.array,
  handleDeleteUser: PropTypes.func.isRequired,
  toggleAlert: PropTypes.func,
};

export default UsersPage;
