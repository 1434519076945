import React from "react";
import { Route, Switch } from "react-router-dom";
import { Onboarding } from "./containers";
import { GlobalStyles } from "./GloablStyles";
import { InfoLabel } from "./components/";
import { onboardingurls } from "./utils/routerUrls";
import OAuth2RedirectHandler from "./utils/OAuth2RedirectHandler";
import ErrorBoundary from "./utils/errorBoundary/ErrorBoundary";
import Homepage from "./containers/homepage/Homepage.js";

/**
 * Root component with routes to homepage and onboarding app pages
 *  @component
 */
const App = () => (
  <div className="App">
    <GlobalStyles />
    <Switch>
      <Route
        path="/"
        exact
        render={(props) => (
          <ErrorBoundary>
            <Homepage {...props} />
          </ErrorBoundary>
        )}
      />
      <Route
        path={onboardingurls}
        exact
        render={(props) => (
          <ErrorBoundary>
            <Onboarding {...props} />
          </ErrorBoundary>
        )}
      />
      <Route
        path="/login/oauth2/code/okta"
        exact
        component={OAuth2RedirectHandler}
      />
      <Route
        render={(props) => <InfoLabel text="Error 404 page not found" />}
      />
    </Switch>
  </div>
);

export default App;
