import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints";

export const StyledHeader = styled.header`
  margin-top: 0.5em;
  width: 100%;
  padding: 0 1% 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  img {
    padding: 2px;
    margin-top: 0.5em;
    display: block;
    height: 70px;
    justify-self: start;
    border-radius: 35px;
  }
  button {
    justify-self: start;
  }

  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
    img {
      height: 75px;
    }
  }
`;
