import React from "react";
import { ACCESS_TOKEN } from "../constants/Constants";
import { Redirect } from "react-router-dom";

/**
 *
 * @function
 * @description
 * Function handles Oauth2 redirect
 *
 * If authentication was succsesful it  stores JWT token into localStorage
 * If authentication wasn't succsesful it returns error to main state
 *
 */
const OAuth2RedirectHandler = ({ location }) => {
  const getUrlParameter = (name) => {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    let results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const token = getCookie("jwt");
  if (token) {
    localStorage.setItem(ACCESS_TOKEN, token);
    return (
      /*
                If last visited page path is present in local storage, redirect user to it,
                otherwise redirect to the homepage
                 */
      !!localStorage.getItem("lastVisited") ? (
        <Redirect
          to={{
            pathname: localStorage.getItem("lastVisited"),
            state: { from: location },
          }}
        />
      ) : (
        <Redirect
          to={{
            pathname: "/onboarding",
            state: { from: location },
          }}
        />
      )
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: {
            from: location,
            error: "OAuth2 Token not found.",
          },
        }}
      />
    );
  }
};

export default OAuth2RedirectHandler;
