import styled from "styled-components";

export const StyledErrorBoundary = styled.div`
  text-align: center;
  width: 60%;
  margin: 0 auto;
  img {
    max-width: 100%;
    height: 60vh;
  }
  a {
    color: rgb(11, 158, 206);
  }
  a:hover {
    color: rgb(41, 188, 236);
  }
`;
