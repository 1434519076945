import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints";
export const StyledHamburger = styled.button`
  width: 100px;
  height: 70px;
  background-color: white;

  border: 3px solid rgb(30, 159, 204);
  & span {
    pointer-events: none;
    width: 80%;
    display: block;
    border: 2px solid black;
    margin: 15% auto;
  }
  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
    width: 80px;
    height: 56px;
  }
  @media screen and (max-width: ${breakpoints.xlg}) {
    width: 90px;
    height: 63px;
  }
`;
