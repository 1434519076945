import styled from "styled-components";

export const StyledAddUserForm = styled.form`
  margin: 10px auto 30px;
  width: 100%;
  div {
    outline: none;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 50px;

    place-items: stretch;
    text-align: center;
    height: 50px;
    padding: 0px 10px;
    border: 3px solid rgb(36, 133, 156, 0.3);
    background-color: rgba(161, 221, 217, 0.12);
    border-radius: 10px;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
  }
  label {
    padding: 15px;
    font-size: 1.1em;
    font-weight: bold;
  }
  input {
    display: block;
    font-size: 1em;
    margin: 0;
    background-color: transparent;
    border: 0;
    &::placeholder {
      color: black;
    }
    &:invalid {
      border: 0;
      box-shadow: none;
    }
  }

  button[type="submit"] {
    box-sizing: border-box;
    margin: 0;
    background-color: transparent;
    border: 0;
    font-size: 2em;
    border-radius: 50%;
    place-items: center;
    display: grid;
    img {
      border-radius: 50%;
      padding: 5px;
      fill: black;
      width: 30px;
      height: 30px;
    }
    img:hover {
      background-color: #4fd5d7;
    }
  }
`;
