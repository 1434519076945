import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints.js";

export const StyledTextContainer = styled.div`
  margin-top: 1em;
  background: rgba(196, 196, 196, 0.15);
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: ${(props) => (props.padding ? props.padding : "1em 1em 0.5em")};
  margin: ${(props) => (props.margin ? props.margin : "1.5em 0 1.5em")};
  textarea {
    resize: none;
    padding: 0.5em;
    background-color: ${(props) =>
      props.readOnly ? "rgb(255,255,255, 0.5)" : "#FFFFFF"};
    height: 8vh;
    width: 100%;
    font-size: ${(props) =>
      props.textFontSize ? props.textFontSize : "0.75em"};
    border-color: #ffffff;
    color: rgba(0, 0, 0, 0.5);
    cursor: ${(props) => (props.readOnly ? "default" : "pointer")};
  }

  label {
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) =>
      props.labelFontSize ? props.labelFontSize : "0.85em"};
    line-height: 127.2%;
    letter-spacing: -0.025em;
    color: #000000;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    textarea {
      height: 3em;
      font-size: 0.8m;
    }
    label {
      font-size: 0.9em;
    }
  }
  @media screen and (min-width: ${breakpoints.xlg}px) {
    textarea {
      height: 3.5em;
      font-size: 0.9em;
    }
    label {
      font-size: 1em;
    }
  }
`;
