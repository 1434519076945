import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledMilestoneLink = styled(Link)`
  &:link,
  &:hover {
    text-decoration: none;
    color: white;
  }
`;
