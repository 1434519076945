import styled from "styled-components";

export const NavigationWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: grid;
  grid-template-columns: auto 1fr;

  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
`;
