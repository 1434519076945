import styled from "styled-components";

export const MainContainer = styled.div`
  margin: 0 auto;
  position: relative;

  section {
    position: absolute;
    margin: 5% auto;
    left: 0;
    right: 0;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }

  section:nth-of-type(1) {
    z-index: 20;
    background-color: ${(props) => {
      return props.backgroundColor != null ? props.backgroundColor : null;
    }};
  }

  section:nth-of-type(2) {
    z-index: 10;
    background: linear-gradient(white, rgb(175, 226, 223));
    transform: translateX(150%);
    height: 68.5vh;
    margin: auto;
  }

  section.transitioned:nth-of-type(1) {
    transform: translateX(-50%);
  }

  section.transitioned:nth-of-type(2) {
    transform: translateX(50%);
  }

  @media only screen and (max-device-width: 800px) {
    display: block;

    section {
      position: relative;
      margin: 5% auto;
      width: 100%;
      transform: translateX(0);
      transition: transform 0.5s ease-in-out;
    }

    section.transitioned:nth-of-type(1) {
      transform: none;
    }

    section.transitioned:nth-of-type(2) {
      opacity: 1;
      transform: none;
    }
  }
`;
