import { ACCESS_TOKEN } from "../constants/Constants";
import axios from "axios";
import fileDownload from "js-file-download";

const config = {
  headers: {
    "Content-Type": "application/json",
    userId: "user123",
    Authorization: "",
  },
};

const downloadFileFromEndpoint = (url, filename, toggleAlert) => {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    config.headers.Authorization = `JWT ${localStorage.getItem(ACCESS_TOKEN)}`;
  }
  axios
    .get(url, {
      responseType: "blob",
      headers: config.headers,
    })
    .then((res) => {
      fileDownload(res.data, filename);
      toggleAlert(`${filename} downloaded successfully.`);
    });
};

export default downloadFileFromEndpoint;
