import PropTypes from "prop-types";
import React from "react";
import BaseLayout from "../../components/base-layout/BaseLayout";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper";
import TextContainer from "../../components/text-editor/TextContainer.js";

/**
 * @component
 * @description Component renders POCs page
 * @param  {string} to path to next page
 * @param  {string} from  path to previous page
 * @param  {array} data CheckboxInfo data for the current page
 * @param  {Object} stepperData stepper pages data; consist of lists of page titles and page routes
 * @param  {function} updateCheckboxInfoData send updated checkboxInfo data to BE
 */
const POCsPage = ({ from, to, data, stepperData, updateCheckboxInfoData }) => {
  return (
    <BaseLayout from={from} to={to} hasTextEditor={true}>
      <HorizontalLinearStepper
        stepTitles={stepperData.titles}
        stepRoutes={stepperData.routes}
        currentStep={data[0].orderOnStepper - 1}
      >
        <h2
          style={{ color: "#57ADDD", fontWeight: "bold", marginBottom: "1%" }}
        >
          {data[0].pageTitle}
          <br />
        </h2>
        <TextContainer
          data={data}
          updateCheckboxInfoData={updateCheckboxInfoData}
        />
      </HorizontalLinearStepper>
    </BaseLayout>
  );
};

POCsPage.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string,
  data: PropTypes.array.isRequired,
  updateCheckboxInfoData: PropTypes.func.isRequired,
  stepperData: PropTypes.object.isRequired,
};

export default POCsPage;
