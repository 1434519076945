import styled from "styled-components";
import { Link } from "react-router-dom";
import { breakpoints } from "../../../utils/cssBreakpoints";
export const LinkButton = styled(Link)`
  display: grid;
  height: 60px;
  max-width: ${(props) => (props.maxWidthNone ? "none" : "230px ")};
  width: 75%;
  border: 3px solid rgb(11, 158, 206);
  background-color: rgb(255, 255, 255);
  grid-column: span ${({ colSpan }) => (colSpan ? colSpan : 2)};

  align-self: center;
  justify-self: end;

  /* Text css */
  text-align: center;
  justify-items: center;
  align-items: center;
  font-size: 18px;
  text-decoration: none;
  font-weight: 700;
  color: rgb(11, 158, 206);
  letter-spacing: 1px;
  :hover {
    cursor: pointer;
  }
  ${(props) => (props.dir === 1 ? "grid-column-end:-1;" : "")}
  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
    width: 100%;
    height: 54px;
    justify-self: end;
    font-size: 16px;
    ${(props) => (props.dir === 1 ? "width:100%;" : "")}
  }
  @media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xlg}px) {
    width: 85%;
  }
  @media (min-width: ${breakpoints.xlg}px) {
    width: 100%;
    font-size: 24px;
  }
`;
