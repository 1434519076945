import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";
import { OvalButton } from "../oval-button/OvalButton";

const StyledLink = styled(Link)`
  &:visited,
  &:link,
  &:hover {
    text-decoration: none;
    color: ${(props) => props.color};
  }
  img {
    width: 15px;
  }
`;

export const OvalLinkButton = ({
  to,
  backgroundColor,
  borderColor,
  leftAndRightPadding,
  color = "black",
  ...props
}) => {
  return (
    <OvalButton
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      leftAndRightPadding={leftAndRightPadding}
      color={color}
    >
      <StyledLink to={to} color={color}>
        {props.children}
      </StyledLink>
    </OvalButton>
  );
};
