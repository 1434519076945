import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BaseLayout from "../../components/base-layout/BaseLayout";
import DragAndDropCanvas from "../../components/drag-and-drop-canvas/DragAndDropCanvas.js";
import DragAndDropItem from "../../components/drag-and-drop-canvas/DragAndDropItem";
import ModalWindow from "../../components/modal-window/ModalWindow";
import { adminUrls } from "../../utils/baseUrl";
import deleteDataFromEndpoint from "../../utils/deleteDataFromEndpoint";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint";
import postDataToEndpoint from "../../utils/postDataToEndPoint";
import putDataToEndpoint from "../../utils/putDataToEndPoint";
import { StyledTextContainer } from "../text-container/StyledTextContainer";
import QuestionContainer from "./QuestionContainer.js";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders Interview questions and enables editing, deleting, creating new questions and changing questions order using drag and drop
 * @param  {function} toggleAlert toggle alert component
 */
const EditInterviewQuestionsPage = ({ toggleAlert }) => {
  const [questions, setQuestions] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [newQuestionMode, setNewQuestionMode] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [questionToBeDeleted, setQuestionToBeDeleted] = useState({});
  const [isDeleteModalOpen, setDeleteModalToOpen] = useState(false);
  const [isQuestionOrderUpdated, setQuestionOrderToUpdated] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = async () => {
    setQuestions([]);
    const { data, errFlag } = await getDataFromEndpoint(
      `${adminUrls.questionUrl}`
    );
    if (!errFlag) {
      setQuestions(data);
    } else {
      throw new Error(t("edit-interview-questions.error-fetch"));
    }
  };

  const handleSave = async () => {
    if (newQuestionMode) await saveNewQuestion();
    else await updateQuestions();
  };

  const saveNewQuestion = async () => {
    const { data, errFlag } = await postDataToEndpoint(
      `${adminUrls.questionUrl}`,
      JSON.stringify({ text: newQuestion })
    );
    if (!errFlag) {
      setQuestions([data, ...questions]);
      setNewQuestionMode(false);
      setNewQuestion("");
      toggleAlert(t("edit-interview-questions.success-create"));
    } else {
      throw new Error(t("edit-interview-questions.error-create"));
    }
  };

  const updateQuestionsOrder = () => {
    return questions.map((question, i) => {
      question.order = ++i * 100;
      return question;
    });
  };

  const updateQuestions = async () => {
    const { data, errFlag } = await putDataToEndpoint(
      `${adminUrls.multipleQuestionsUrl}`,
      JSON.stringify(updateQuestionsOrder())
    );
    if (!errFlag) {
      setQuestions(data);
      setEditMode(false);
      setQuestionOrderToUpdated(false);
      toggleAlert(t("edit-interview-questions.success-upload"));
    } else {
      throw new Error(t("edit-interview-questions.error-upload"));
    }
  };

  const handleEdit = async () => {
    if (editMode) await getQuestions();
    if (isQuestionOrderUpdated) setQuestionOrderToUpdated(false);
    setEditMode(!editMode);
  };

  const handleNew = () => {
    setNewQuestion("");
    setNewQuestionMode(!newQuestionMode);
  };

  const handleQuestionChange = (newText, i) => {
    let updatedQuestions = questions;
    updatedQuestions[i].text = newText;
    setQuestions(updatedQuestions);
  };

  const handleDeleteClick = (question) => {
    setQuestionToBeDeleted(question);
    setDeleteModalToOpen(!isDeleteModalOpen);
  };

  const deleteQuestion = async (id) => {
    const { errFlag } = await deleteDataFromEndpoint(
      `${adminUrls.questionUrl}/${id}`,
      {}
    );
    if (!errFlag) {
      setQuestions(questions.filter((q) => q.id !== id));
      setDeleteModalToOpen(!isDeleteModalOpen);
      toggleAlert(t("edit-interview-questions.success-delete"), "error");
    } else {
      throw new Error(t("edit-interview-questions.error-delete"));
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModalToOpen(!isDeleteModalOpen);
  };

  const handleActivation = async (question) => {
    question.active = !question?.active;
    const { data, errFlag } = await putDataToEndpoint(
      `${adminUrls.questionUrl}/${question.id}`,
      JSON.stringify(question)
    );
    if (!errFlag) {
      setQuestions(
        questions.map((q) => {
          if (q.id === question.id) {
            q.active = data.active;
          }
          return q;
        })
      );
      toggleAlert(t("edit-interview-questions.q-success-update"));
    } else {
      throw new Error(t("edit-interview-questions.q-error-update"));
    }
  };

  const moveQuestion = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = questions[dragIndex];
      const hoverItem = questions[hoverIndex];
      setQuestions((questions) => {
        const updatedQuestions = [...questions];
        updatedQuestions[dragIndex] = hoverItem;
        updatedQuestions[hoverIndex] = dragItem;
        return updatedQuestions;
      });
      setQuestionOrderToUpdated(true);
      setEditMode(true);
    },
    [questions]
  );

  return (
    <BaseLayout hasTextEditor={true}>
      <h2 style={{ color: "#57ADDD", fontWeight: "bold", marginBottom: "1%" }}>
        Edit Interview Questions
        <br />
      </h2>
      <div style={{ display: "flex", margin: "1em 0em" }}>
        <Button
          variant={"info"}
          onClick={handleNew}
          style={{ boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)" }}
        >
          {newQuestionMode
            ? t("edit-interview-questions.cancel")
            : t("edit-interview-questions.new-question")}
        </Button>
        <Button
          variant={"outline-info"}
          onClick={handleEdit}
          disabled={newQuestionMode}
          style={{ marginLeft: "auto", minWidth: "5em", marginRight: "0.5em" }}
        >
          {editMode || isQuestionOrderUpdated
            ? t("edit-interview-questions.cancel")
            : t("edit-interview-questions.edit")}
        </Button>
        <Button
          variant={"info"}
          onClick={handleSave}
          style={{
            minWidth: "5em",
            boxShadow:
              editMode || isQuestionOrderUpdated
                ? "0px 4px 19px rgba(0, 0, 0, 0.25)"
                : null,
          }}
          disabled={
            newQuestion?.length === 0 && !editMode && !isQuestionOrderUpdated
          }
        >
          {t("edit-interview-questions.save")}
        </Button>
      </div>
      <div>
        {newQuestionMode && (
          <div style={{ gridColumn: "1/3" }}>
            <StyledTextContainer margin={"1.5em 6em 1.5em 0em"}>
              <label>{t("edit-interview-questions.add-new-question")}</label>
              <textarea
                onChange={(e) => setNewQuestion(e.target.value)}
                value={newQuestion}
              ></textarea>
            </StyledTextContainer>
          </div>
        )}
        <DragAndDropCanvas>
          {questions.map((question, index) => {
            return (
              <DragAndDropItem
                key={question.id}
                index={index}
                moveListItem={moveQuestion}
              >
                <QuestionContainer
                  question={question}
                  index={index}
                  editMode={editMode}
                  handleQuestionChange={handleQuestionChange}
                  handleActivation={handleActivation}
                  handleDeleteClick={handleDeleteClick}
                />
              </DragAndDropItem>
            );
          })}
        </DragAndDropCanvas>
      </div>
      <ModalWindow
        headerText={
          <h4>
            <b>{t("edit-interview-questions.question")}</b>
          </h4>
        }
        isOpen={isDeleteModalOpen}
        hideModal={toggleDeleteModal}
        toDoIfConfirmed={() => deleteQuestion(questionToBeDeleted.id)}
        body={<li>{questionToBeDeleted.text}</li>}
        cancelText={t("edit-interview-questions.cancel")}
        confirmText={t("edit-interview-questions.delete")}
      />
    </BaseLayout>
  );
};

EditInterviewQuestionsPage.propTypes = {
  toggleAlert: PropTypes.func,
};
export default EditInterviewQuestionsPage;
