import styled from "styled-components";

export const StyledDownloadButton = styled.button`
  background: ${(props) => (props.background ? props.background : "#57ADDD")};
  box-shadow: 0px 5.38775px 12.1224px rgba(0, 0, 0, 0.25);
  border-radius: 0.35rem;
  border: ${(props) => (props.border ? props.border : "0px")};
  padding-left: auto;
  padding-right: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: ${(props) => (props.margin ? props.margin : "1em")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "16em")};
  color: ${(props) => (props.color ? props.color : "white")};
  ${(props) => props.fontSize && "font-size:" + props.fontSize}
`;
