import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import plus from "../../assests/images/plus.svg";
import { urls } from "../../utils/baseUrl";
import { UserContext } from "../../utils/context/UserContext";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint";
import { StyledAddUserInformationForm } from "./StyledAddUserInformationForm";

/**
 *
 * @Component
 * @description Renders add user from
 *
 * @param  {function} addUserHandler handler for adding users
 */
const AddUserInformationForm = ({
  title,
  infoType,
  inputText,
  setInputText,
  addUserInformationHandler,
}) => {
  const userData = useContext(UserContext);

  const [error] = useState(null);

  useEffect(() => {
    fetchInitialUserInformationData();
  }, []);

  const inputOnChangeHandler = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addUserInformationHandler();
  };

  /**
   * @function
   * @description Gets user account information text from backend about current user
   *
   * If response is 401(Unauthorized) it redirects the user to login page
   *
   * @return fetched data (object {userAccountInfoType: value})
   */
  const fetchInitialUserInformationData = async () => {
    const { data, errFlag } = await getDataFromEndpoint(
      urls.userAccountInfoUrl.replace(":userId", userData.id)
    );
    if (!errFlag) {
      setInputText(data[infoType]);
    } else {
      if (data.response.status === 404) {
        setInputText("");
      } else if (data === 401) {
        handleUnauthorized();
      } else {
        throw new Error(data);
      }
    }
  };

  return (
    <StyledAddUserInformationForm onSubmit={handleSubmit}>
      <label htmlFor="textInput">{title}</label>
      <div>
        <input
          type="text"
          name="inputText"
          id="inputText"
          value={inputText}
          onChange={(event) => {
            inputOnChangeHandler(event);
          }}
        />
        <button type="submit">
          <img src={plus} alt="" />
        </button>
      </div>
    </StyledAddUserInformationForm>
  );
};

AddUserInformationForm.propTypes = {
  title: PropTypes.string.isRequired,
  infoType: PropTypes.string.isRequired,
  inputText: PropTypes.string.isRequired,
  setInputText: PropTypes.func.isRequired,
  addUserInformationHandler: PropTypes.func.isRequired,
};
export default AddUserInformationForm;
