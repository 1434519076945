export const ACCESS_TOKEN = "jwt";

const turquoise = "rgb(175,226,223)";
const darkerBlue = "rgb(11, 158, 206)";

export const styles = {
  button: {
    border: {
      color: {
        lightBlue: "rgba(79, 213, 215, 1)",
        darkerBlue: darkerBlue,
      },
    },
    color: {
      lightBlue: turquoise,
    },
  },

  container: {
    backgroundColor: {
      turquoise: turquoise,
      cloudBlue: "rgb(0,202,238)",
    },
    color: {
      lightBlue: "rgb(121,223,224)",
      darkerBlue: darkerBlue,
    },
  },
};
