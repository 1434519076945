import * as DOMPurify from "dompurify";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { UserTypes } from "../../constants/UserTypes";
import { UserContext } from "../../utils/context/UserContext.js";
import { useCheckboxTextHandler } from "../../utils/useCheckboxTextHandler.js";
import { Label } from "../label/Label";
import TextEditor from "./TextEditor";
import TextEditorButtons from "./TextEditorButtons.js";

/**
 * @component
 * @description If user is Admin, component renders Text editor for editing checkbox text for Full-time or Student user. If user is type Full-time or Student, it renders only checkbox text
 * @param  {function} updateCheckboxInfoData send updated checkboxInfo data to BE
 * @param  {function} fetchChosenUserType return chosen user type to parent component
 * @param  {array} children array of components that are placed in between checkbox texts
 * @param  {array} data checkboxInfo data for the current page
 * @param  {boolean} onlyFullTimeView show only FULL-TIME user view
 */
const TextContainer = ({
  updateCheckboxInfoData,
  fetchChosenUserType,
  children,
  data,
  onlyFullTimeView,
}) => {
  const userData = useContext(UserContext);
  const isAdminUser = userData.userType === UserTypes.ADMIN;

  const { getCheckboxInfoData, handleCheckboxInfoTextChange } =
    useCheckboxTextHandler(data);
  const checkboxInfoData = getCheckboxInfoData();
  const [editMode, setEditMode] = useState(false);
  const [chosenUserType, setChosenUserType] = useState(UserTypes.FULLTIME);

  const handleEditClick = () => {
    if (editMode) {
      window.location.reload();
    }
    setEditMode(!editMode);
  };

  const handleSaveClick = () => {
    checkboxInfoData.forEach((checkboxInfo) => {
      updateCheckboxInfoData(checkboxInfo[chosenUserType]);
    });
    if (editMode) setEditMode(!editMode);
  };

  const handleUserTypeClick = () => {
    setChosenUserType(userData.userType);
    if (!!fetchChosenUserType) {
      fetchChosenUserType(userData.userType);
    }
  };

  const handleTextChange = (text, index) => {
    const regex =
      /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1(?:[^>]*?\s+title=(["'])(.*?)\3)?[^>]*?>(.*?)<\/a>/g;
    let match;

    while ((match = regex.exec(text))) {
      const link = document.createElement("a");
      link.href = match[2];
      link.target = "_blank";
      const linkTitle = match[4] || match[5];
      link.innerText = linkTitle;

      const linkStart = match.index;
      const linkEnd = linkStart + match[0].length;
      text = text.slice(0, linkStart) + link.outerHTML + text.slice(linkEnd);
      regex.lastIndex = linkStart + link.outerHTML.length;
    }
    handleCheckboxInfoTextChange(text, chosenUserType, index);
  };

  const showTextOrTextEditor = (editMode, index) => {
    if (editMode && checkboxInfoData[index][chosenUserType]?.text) {
      return (
        <TextEditor
          data={checkboxInfoData[index][chosenUserType]?.text}
          handleTextChange={handleTextChange}
          index={index}
        />
      );
    }
    if (!editMode) {
      const sanitizedHTML = DOMPurify.sanitize(
        checkboxInfoData[index][
          isAdminUser ? chosenUserType : userData.userType
        ]?.text,
        { USE_PROFILES: { html: true } },
      );

      return (
        <Label
          dangerouslySetInnerHTML={{
            __html: sanitizedHTML,
          }}
        ></Label>
      );
    }
  };

  return (
    <>
      {isAdminUser && (
        <TextEditorButtons
          handleEditClick={handleEditClick}
          handleSaveClick={handleSaveClick}
          handleUserTypeClick={handleUserTypeClick}
          chosenUserType={chosenUserType}
          onlyFullTimeView={onlyFullTimeView}
          isEditMode={editMode}
        />
      )}
      {!!checkboxInfoData && children
        ? React.Children.map(
            children,
            (child, index) =>
              !!checkboxInfoData[index] && (
                <>
                  {showTextOrTextEditor(editMode, index)}
                  {React.cloneElement(child, {
                    style: { ...child.props.style },
                  })}
                </>
              ),
          )
        : !!checkboxInfoData[0] && showTextOrTextEditor(editMode, 0)}
    </>
  );
};

TextContainer.propTypes = {
  updateCheckboxInfoData: PropTypes.func.isRequired,
  fetchChosenUserType: PropTypes.func,
  children: PropTypes.array,
  data: PropTypes.array.isRequired,
  onlyFullTimeView: PropTypes.bool,
};

export default TextContainer;
