import styled from "styled-components";
import React from "react";

const StyledHeading = styled.h1`
  text-align: center;
`;
/**
 * @component
 * @description Used to render a label with text from props
 * Used for 404 page ,loading and logging in
 * @param  {String} text
 */
export const InfoLabel = ({ text }) => <StyledHeading>{text}</StyledHeading>;
