import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ProgressWrapper = styled.div`
  margin: auto;
  width: 25px;
  border: 3px solid rgb(11, 158, 206);
  background-color: #fff;
  height: 100%;
  position: relative;
  display: inline-block;
`;
const ProgresBar = styled.div`
  width: 100%;
  background-color: rgb(159, 231, 234);
  height: ${(props) => props.height}%;
  position: absolute;
  bottom: 0;
  border-top: 3px solid rgb(11, 158, 206);
`;
/**
 * @components
 * @description Component renders progresbar
 * @param  {number} height Progresbar height
 */
const ProgressBar = ({ height }) => (
  <ProgressWrapper>
    <ProgresBar height={height} />
  </ProgressWrapper>
);

ProgresBar.propTypes = {
  height: PropTypes.number.isRequired,
};
export default ProgressBar;
