import styled from "styled-components";

export const StyledRoundButton = styled.button`
  width: ${(props) => props.width || "60%"};
  border-width: ${(props) => props.borderWidth || "2px"};
  border-style: solid;
  background-color: ${(props) =>
    props.sentToAdmin ? "#117a8b" : props.backgroundColor || "#17a2b8"};
  border-color: ${(props) => props.borderColor || "rgb(11, 138, 206)"};
  border-radius: 15px;
  color: white;
  padding: 0.6em;
  display: block;
  font-size: 0.7em;
  margin-top: ${(props) => props.marginTop || "null"};
  margin-left: ${(props) => props.marginLeft || "null"};
  ${(props) =>
    props.checked ? "background-color: rgba(120, 120, 120, 0);" : ""}
  img {
    width: 15px;
  }
`;
