import React from "react";
import { StyledAccordion } from "./StyledAccordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const Accordion = ({ title, children, childrenLength }) => {
  let disabled = false;
  if (childrenLength === 0) {
    disabled = true;
  }

  return (
    <StyledAccordion disabled={disabled} variant={"outlined"}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{children}</Typography>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default Accordion;
