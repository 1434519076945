import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Footer, Header, Navigation } from "../../components";
import { UserTypes } from "../../constants/UserTypes";
import { Main } from "../../containers";
import { UserContext } from "../../utils/context/UserContext";
import RouteButton from "../buttons/route-button/RouteButton";
import { StyledBaseLayout } from "./StyledBaseLayout";

/**
 * /**
 * @component
 * @description Wrapper component that has base layout for most pages
 * @param  {function} handleHamburgerClick Hamburger click handler
 * @param  {string} from Optional route to previous page
 * @param  {string} to Optional route to next page
 * @param  {element} children
 * @param  {boolean} hasAside
 * @param  {boolean} indentP
 * @param  {boolean} alignItemsTop
 * @param  {boolean} hasTextEditor
 * @param  {string} mainPadding main container padding
 * @param {function} onBack callback function when clicked on back button
 * @param {function} onNext callback function when clicked on next button
 */
const BaseLayout = ({
  handleHamburgerClick,
  from,
  to,
  children,
  hasAside,
  indentP,
  alignItemsTop,
  hasTextEditor,
  mainPadding,
  onBack,
  onNext,
}) => {
  const userData = useContext(UserContext);

  return (
    <StyledBaseLayout>
      <Navigation
        isVisible={true}
        isUserAdmin={userData.userType === UserTypes.ADMIN}
        userType={userData.userType}
      />
      <div
        style={{
          gridColumnStart: 2,
          gridColumnEnd: 3,
          gridRowStart: 1,
          gridRowEnd: 2,
        }}
      >
        <Header handleHamburgerClick={handleHamburgerClick} />
        <Main
          indentP={indentP}
          hasAside={hasAside}
          alignItemsTop={alignItemsTop}
          hasTextEditor={hasTextEditor}
          mainPadding={mainPadding}
        >
          {children}
          <div className={"routeButtonsContainer"}>
            {from ? <RouteButton dir={0} to={from} onClick={onBack} /> : null}
            {to ? <RouteButton dir={1} to={to} onClick={onNext} /> : null}
          </div>
        </Main>
      </div>
      <Footer />
    </StyledBaseLayout>
  );
};

BaseLayout.propTypes = {
  handleHamburgerClick: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
  hasTextEditor: PropTypes.bool,
  mainPadding: PropTypes.string,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};
export default BaseLayout;
