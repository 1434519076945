import React, { useContext } from "react";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";
import { UserContext } from "./context/UserContext";

export const AdminRoute = ({
  component: Component,
  isUserAdmin,
  handleHamburgerClick,
  ...rest
}) => {
  const userData = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isUserAdmin) {
          return (
            <Component
              {...props}
              handleHamburgerClick={handleHamburgerClick}
              userData={userData}
            />
          );
        } else {
          return <Redirect to="/onboarding" />;
        }
      }}
    />
  );
};
