import * as React from "react";
import { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from "@mui/material";
import { completedAndActiveStepStyle } from "./HorizontalLinearStepperStyles";
import { useHistory } from "react-router";
import { BiCircle } from "react-icons/bi";
import { FaRegDotCircle } from "react-icons/fa";

/**
 * @component
 * @description Component renders Horizontal Linear Stepper
 * @param  {array} stepTitles array of step titles
 * @param  {array} stepRoutes array of step routes
 * @param  {Object} children child elements
 * @param  {number} currentStep current active step
 * @param  {string} stepperMargin stepper margins
 */
const HorizontalLinearStepper = ({
  stepTitles,
  stepRoutes,
  children,
  currentStep,
  stepperMargin,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [completed, setCompleted] = React.useState({});
  const history = useHistory();

  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set([...skipped]);
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleStep = (step) => () => {
    setActiveStep(step);

    history.push(stepRoutes[step].route);
  };

  const totalSteps = () => {
    return stepTitles.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear={true}
        activeStep={activeStep}
        alternativeLabel={true}
        sx={{ margin: stepperMargin || "0 -10% 2em -10%" }}
      >
        {stepTitles.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step
              key={label}
              {...stepProps}
              completed={completed[index]}
              sx={completed[index] ? completedAndActiveStepStyle : null}
            >
              <StepButton
                color="inherit"
                onClick={handleStep(index)}
                icon={activeStep === index ? <FaRegDotCircle /> : <BiCircle />}
              >
                <StepLabel {...labelProps}>{label.toString()}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>

      {children}
      {allStepsCompleted() ? (
        <React.Fragment>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <br />
            <Button onClick={handleReset} style={{ marginLeft: "auto" }}>
              Reset
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {/**<Button
                         color="inherit"
                         disabled={activeStep === 0}
                         onClick={handleBack}
                         sx={{mr: 1}}
                         >
                         Back
                         </Button>
                         <Box sx={{ flex: '1 1 auto' }} />
                         <Button onClick={handleNext} sx={{ mr: 1 }}>
                         Next
                         </Button>**/}
            {/**{activeStep !== stepTitles.length &&
                            (completed[activeStep] ? (
                                <Typography variant="caption" sx={{display: 'inline-block'}}>
                                    Step {activeStep + 1} already completed
                                </Typography>
                            ) : (
                                <Button variant="outlined" onClick={handleComplete} style={{marginLeft: "auto"}}>
                                    {completedSteps() === totalSteps() - 1
                                        ? 'Finish'
                                        : 'Complete Step'}
                                </Button>
                            ))}**/}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

HorizontalLinearStepper.propTypes = {
  stepTitles: PropTypes.array.isRequired,
  stepRoutes: PropTypes.array.isRequired,
  children: PropTypes.object,
  currentStep: PropTypes.number,
  stepperMargins: PropTypes.string,
};

export default HorizontalLinearStepper;
