import { ACCESS_TOKEN } from "../constants/Constants";
import axios from "axios";
import { baseBackendUrl } from "./baseUrl";

const config = {
  headers: {
    userId: "user123",
    Authorization: "",
  },
};

const createReturnValue = (errFlag, data) => ({
  errFlag,
  data,
});

const uploadFileToEndpoint = async (url, formData) => {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    config.headers.Authorization = `JWT ${localStorage.getItem(ACCESS_TOKEN)}`;
  }
  try {
    const result = await axios({
      method: "post",
      headers: config.headers,
      url: `${baseBackendUrl}/${url}`,
      data: formData,
    });
    return createReturnValue(false, result.data);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        return createReturnValue(true, 401);
      }
    }
    throw new Error("uploadFileToEndpoint error");
  }
};

export default uploadFileToEndpoint;
