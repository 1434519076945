import React, { useContext } from "react";
import PropTypes from "prop-types";
import BaseLayout from "../../components/base-layout/BaseLayout";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper";
import TextContainer from "../../components/text-editor/TextContainer.js";
import { UserTypes } from "../../constants/UserTypes";
import { UserContext } from "../../utils/context/UserContext";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders Intro page
 * @param  {string} to path to next page
 * @param  {array} data CheckboxInfo data for the current page
 * @param  {Object} stepperData stepper pages data; consist of lists of page titles and page routes
 * @param  {function} updateCheckboxInfoData send updated checkboxInfo data to BE
 */
const IntroPage = ({ to, data, stepperData, updateCheckboxInfoData }) => {
  const userData = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <BaseLayout to={to} hasTextEditor={true}>
      <HorizontalLinearStepper
        stepTitles={stepperData.titles}
        stepRoutes={stepperData.routes}
        currentStep={data[0].orderOnStepper - 1}
      >
        <h2
          style={{ color: "#57ADDD", fontWeight: "bold", marginBottom: "1%" }}
        >
          {t("intro-page.welcome")}
          <br />
          {userData.userType !== UserTypes.ADMIN && (
            <>
              <span style={{ color: "black" }}>{userData.name}</span> !
            </>
          )}
        </h2>
        <TextContainer
          data={data}
          updateCheckboxInfoData={updateCheckboxInfoData}
        />
      </HorizontalLinearStepper>
    </BaseLayout>
  );
};

IntroPage.propTypes = {
  to: PropTypes.string,
  data: PropTypes.array.isRequired,
  stepperData: PropTypes.object.isRequired,
  updateCheckboxInfoData: PropTypes.func.isRequired,
};

export default IntroPage;
