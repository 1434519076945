import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints.js";

export const StlyledHomepage = styled.div`
  @font-face {
    font-family: "proxima_nova_rgregular";
    src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot");
    src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff")
        format("woff"),
      url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf")
        format("truetype"),
      url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }

  padding: 0% 0%;
  width: 100%;
  font-family: "proxima_nova_rgregular";

  .backgroundContent {
    background-image: "";
  }

  .backgroundContent::after {
    content: "";
    background-image: ${(props) => props.backgroundImage || ""};
    background-position: 50% 50%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 1em;
    right: 0;
    position: absolute;
    z-index: -1;
    overflow: hidden;
    max-height: 45em;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    .projects-container {
      margin-left: 5em;
      margin-right: 5em;
    }
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    .project-card {
      width: 20em;
    }

    .projects-container {
      margin-left: 5em;
      margin-right: 5em;
      padding-left: 5em;
      padding-right: 5em;
    }
  }

  @media screen and (min-width: ${breakpoints.xlg}px) {
    .project-card {
      width: 25em;
    }

    .projects-container {
      margin-left: 25em;
      margin-right: 25em;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
