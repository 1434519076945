import styled from "styled-components";
import { StyledMilestone } from "../../milestone/StyledMilestone";

export const HoverDeleteX = styled.img`
  display: block;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  visibility: hidden;
  :hover {
    cursor: pointer;
  }
  ${StyledMilestone}:hover & {
    visibility: visible;
  }
`;
