import React from "react";
import PropTypes from "prop-types";
import { DndProvider } from "react-dnd-cjs";
import HTML5Backend from "react-dnd-html5-backend-cjs";

/**
 * @component
 * @description Component renders Drag and Drop Canvas
 * @param  {Array} children array of Drag and Drop Items
 */
const DragAndDropCanvas = ({ children }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <>{children}</>
    </DndProvider>
  );
};
DragAndDropCanvas.propTypes = {
  children: PropTypes.array.isRequired,
};

export default DragAndDropCanvas;
