/**
 * @Function
 * @description function gets random color
 */
export const getRandomColor = () => {
  let color = sessionStorage.getItem("picture-color");
  if (color != null) {
    return color;
  }

  const letters = "0123456789ABCDEF";
  color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  sessionStorage.setItem("picture-color", color);
  return color;
};

/**
 * @Function
 * @description creates circular image from initials with passed size and color
 * @param  {string} size picture size
 * @param  {string} name user name used to extract first and last initials
 * @param  {string} color background color
 */
export const createImageFromInitials = (size, name, color) => {
  if (name == null) return;

  const words = name?.split(" ").map((word) => word[0]);
  name = words[0] + words[words.length - 1];

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;

  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, size, size);

  context.fillStyle = `${color}50`;
  context.fillRect(0, 0, size, size);

  context.fillStyle = color;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `${size / 2}px Roboto`;
  context.fillText(name, size / 2, size / 2);

  return canvas.toDataURL();
};
