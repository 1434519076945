import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { adminNavigationInfo, navigationInfo } from "../../__cms__/";
import logo from "../../assests/images/logo.png";
import { UserTypes } from "../../constants/UserTypes.js";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../utils/context/UserContext";
import { Navbar } from "./Navbar";
import { NavigationWrapper } from "./NavigationWrapper";

/**
 * @component
 * @description Component renders navigations
 * @param  {bool} isVisible
 */
const Navigation = ({ isVisible }) => {
  const location = useLocation();
  const userData = useContext(UserContext);

  const returnListItem = (listItem) => {
    if (listItem.onlyForFulltime && userData.userType === UserTypes.STUDENT)
      return;
    if (!!listItem && listItem?.dropDownButton === true) {
      let childList = null;
      if (!!listItem?.list) {
        childList = parseNavContent(listItem);
      }

      const isActive = listItem.list.some(
        (item) => item.listItem.link === location.pathname
      );

      return (
        <li key={key}>
          <input id={`${key}db`} type="checkbox" defaultChecked={isActive} />
          <label className={"heading-list-item"} htmlFor={`${key}db`}>
            {listItem.text}
          </label>
          <ul key={key++}>{childList}</ul>
        </li>
      );
    } else if (!!listItem) {
      return (
        <li key={key++}>
          {listItem.heading ? (
            <Link className={"heading-list-item"} to={listItem.link}>
              {listItem.text}
            </Link>
          ) : (
            <Link
              to={listItem.link}
              {...(listItem.link !== location.pathname && {
                onClick: () => scrollTo({ top: 0 }),
              })}
            >
              {listItem.text}
            </Link>
          )}
        </li>
      );
    } else {
      return null;
    }
  };
  let key = 0;
  /**
   * @function
   * @description Function parses navigation info to a html5 navigation items
   * @param {*} contentList List of Navigation items
   */
  const parseNavContent = (contentList) => {
    return contentList?.list.map((unparsedContentItem) => {
      if (!!unparsedContentItem.listItem) {
        return returnListItem(unparsedContentItem.listItem);
      } else if (!!unparsedContentItem.list) {
        return <ul key={key++}>{parseNavContent(unparsedContentItem)}</ul>;
      }
      return 0;
    });
  };
  let navigationContent =
    userData.userType === UserTypes.ADMIN
      ? parseNavContent(adminNavigationInfo)
      : parseNavContent(navigationInfo);
  return (
    <NavigationWrapper isVisible={isVisible}>
      <Navbar>
        <img src={logo} alt="logo" />
        <div>
          <ul>{navigationContent}</ul>
        </div>
      </Navbar>
    </NavigationWrapper>
  );
};

Navigation.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};
export default Navigation;
