import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints.js";

export const StyledFooter = styled.footer`
  margin: 0 auto;
  width: 100%;
  padding: 2% 1% 1% 5%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 0.65fr 0.2fr 0.15fr;
  grid-template-rows: 1fr;
  background-color: #ffffff;
  font-style: normal;
  img {
    display: block;
    height: 85px;
    justify-self: start;
  }
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;

  @media screen and (min-width: ${breakpoints.xlg}px) {
    font-size: 1.2em;
    max-height: 100%;
  }
`;
