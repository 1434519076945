import React, { useRef } from "react";
import uploadFileToEndpoint from "../../utils/uploadFileToEndpoint";
import { AiOutlinePlus } from "react-icons/ai";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders button with label for uploading documents/pictures
 * @param  {function} toggleAlert toggles alert message
 * @param  {string} title title
 * @param  {string} url api url
 * @param  {function} refetchDocuments returns files/pictures to parent component
 * @param  {boolean} disabled is upload button disabled
 * @param  {string} fileType file type
 * @param  {string} label label
 */

const FileUploader = ({
  toggleAlert,
  title,
  url,
  refetchDocuments,
  disabled,
  fileType,
  label,
}) => {
  const fileInput = useRef(null);
  const { t } = useTranslation();

  const onChange = (event) => {
    for (const file of event.target.files) {
      let formData = new FormData();
      formData.append("file", file, file.name);
      if (!!fileType) formData.append("fileType", fileType);
      uploadFileToEndpoint(url, formData)
        .then(() => {
          toggleAlert(t("file-uploader.upload-success", { fileType }));
          if (!!refetchDocuments) refetchDocuments();
        })
        .catch(() => {
          toggleAlert(t("file-uploader.upload-error", { fileType }), "error");
        });
    }
  };

  return (
    <>
      {label ? <label>{label}</label> : null}
      <input
        style={{ display: "none" }}
        type="file"
        multiple="multiple"
        onChange={onChange}
        ref={fileInput}
      />
      <button onClick={() => fileInput.current.click()} disabled={disabled}>
        <AiOutlinePlus />
        <span> {title || t("file-uploader.upload-title")}</span>
      </button>
    </>
  );
};

FileUploader.prototype = {
  toggleAlert: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  refetchDocuments: PropTypes.func,
  disabled: PropTypes.bool,
  fileType: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default FileUploader;
