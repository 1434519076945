import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { AdminRoute } from "../../utils/AdminRoute";
import ErrorBoundary from "../../utils/errorBoundary/ErrorBoundary";
import {
  CheckedCheckboxPage,
  ConfluencePage,
  DocsPage,
  EventsPage,
  GDrivePage,
  GecoPage,
  GithubPage,
  HardwareInfoPage,
  IntroPage,
  SeeVeePage,
  SlackPage,
  TamTamyPage,
  TgPage,
  TrelloPage,
  MiroPage,
} from "../";
import { InfoLabel } from "../../components";
import CustomSnackbar from "../../components/snackbar/CustomSnackbar";
import { ACCESS_TOKEN } from "../../constants/Constants";
import { UserTypes } from "../../constants/UserTypes.js";
import {
  adminUrls,
  backendRedirectUrl,
  baseUrl,
  urls,
} from "../../utils/baseUrl";
import { UserContext } from "../../utils/context/UserContext";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint";
import putDataToEndPoint from "../../utils/putDataToEndPoint";
import routerUrls from "../../utils/routerUrls";
import AdminPage from "../admin-pages/AdminPage";
import AllianzPage from "../allianz-page/AllianzPage";
import MedicalCheckPage from "../medical-check-page/MedicalCheckPage";
import AORsPage from "../aors-page/AORsPage.js";
import APSystemsPage from "../ap-systems-page/APSystemsPage";
import InterviewQuestionsPage from "../interview-questions-page/InterviewQuestionsPage";
import LanguageCoursePage from "../language-course-page/LanguageCoursePage";
import OtherDocsPage from "../other-documentation-page/OtherDocsPage.js";
import PersonalDataPage from "../personal-data-page/PersonalDataPage";
import PersonalDevelopmentDaysPage from "../personal-development-days-page/PersonalDevelopmentDaysPage";
import POCsPage from "../poc-page/POCsPage";
import UserProfilePage from "../user-profile-page/UserProfilePage";

/**
 * @Component
 * @description Root component for onboarding app
 */

const Onboarding = () => {
  const [state, setState] = useState({
    data: null,
    error: null,
    buttonData: null,
    user: null,
    progress: 0,
    isNavbarVisible: true,
    isAdmin: false,
    loading: true,
    alertOpen: false,
    alertMessage: "",
    alertSeverity: "",
  });

  const [lastVisitedLocation, setLastVisitedLocation] = useState("");

  let location = useLocation();

  /**
   * @function
   * @description
   * handles navbar state
   */
  const handleHamburgerClick = () => {
    setState((prevState) => ({
      ...prevState,
      isNavbarVisible: !prevState.isNavbarVisible,
    }));
  };
  /**
   * @function
   * @description Function checks if there is a userlogged in
   */

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  useEffect(() => {
    setIsUserAuthenticated(
      !!localStorage.getItem(ACCESS_TOKEN) && !!state.user
    );
    isUserAdmin();
  }, [state.user]);

  const handleUnauthorized = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    window.location = `${backendRedirectUrl}/oauth2/authorization/okta?redirect_uri=${baseUrl}/login/oauth2/code/okta`;
  };

  const handleUserOnboarded = async () => {
    const { data, errFlag } = await putDataToEndPoint(
      `${urls.userDataUrl}/${state.user.id}`,
      {
        email: state.user.email,
        userType: state.user.userType,
        onboarded: true,
      }
    );

    if (errFlag && data === 401) {
      handleUnauthorized();
    } else {
      setState({ ...state, user: { ...state.user, onboarded: true } });
    }
  };

  /**
   * @function
   * @description Returns new checkbox state and the new value of the checkbox
   * @param  {any} {data} Data is checkbox data extracted frim aexport const userUrl = ""pp state
   * @param  {int} id Id of the checkbox that was clicked
   *
   */
  const updateCheckboxData = ({ data }, id) => {
    let newValue = null;
    const updatedState = data.map((checkbox) => {
      if (checkbox.id === id) {
        checkbox.state = !checkbox.state;
        newValue = checkbox.state;
      }
      return checkbox;
    });
    return [updatedState, newValue];
  };
  /**
   * @function
   * @description Changes state of checkbox that was clicked and sends that data do BE
   *
   * If response isn't 200(OK) after 2 seconds, state is reversed again
   *
   * If response is 401(Unauthorized) it redirects the user to login page
   * @param  {number} id Id of the checkbox that was clicked
   *
   */
  const checkboxOnChangeHandler = async (id) => {
    const [updatedState, newValue] = updateCheckboxData(state, id);
    setState({
      ...state,
      data: updatedState,
    });
    const { data, errFlag } = await putDataToEndPoint(
      `${urls.checkboxesUrl}/${id}`,
      {
        state: newValue,
      }
    );
    setTimeout(() => {
      if (errFlag) {
        const [oldState] = updateCheckboxData(state, id);
        setState({
          ...state,
          data: oldState,
        });
        if (data === 401) {
          handleUnauthorized();
        }
      }
    }, 2000);

    getProgress();
  };

  const updateCheckboxInfoData = async (checkboxInfo) => {
    const { data, errFlag } = await putDataToEndPoint(
      `${adminUrls.checkboxInfoUrl}/${checkboxInfo.id}`,
      checkboxInfo
    );
    if (!errFlag) {
      toggleAlert("Text updated successfully!");
    } else if (data === 401) {
      handleUnauthorized();
      toggleAlert("Error updating text. You are not authorized!", "error");
    } else {
      toggleAlert("Error updating text", "error");
    }
  };

  /**
   * @function
   * @description
   * Function for getting checkbox data from BE
   *
   */
  const fetchBackendData = async () => {
    const { data, errFlag } = await getDataFromEndpoint(urls.checkboxesUrl);
    if (!errFlag) {
      setState(
        (prevState) => ({
          ...prevState,
          data,
        }),
        () => {
          this.getProgress();
        }
      );
    } else {
      if (data === 401) {
        handleUnauthorized();
      }
      setState({
        ...state,
        error: data,
      });
    }
  };
  /**
   * @function
   * @description
   *
   * Function is used to logout
   *
   * deletes jwt token and reloads the page
   */
  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    return <Redirect to="/" />;
  };
  /**
   * @function
   * @description
   * Function for fetching and setting progres data
   */
  const getProgress = async () => {
    const { data, errFlag } = await getDataFromEndpoint(urls.progressUrl);
    if (!errFlag) {
      setState({ ...state, progress: data * 100 });
    } else {
      if (data === 401) {
        handleUnauthorized();
      }
      setState({ ...state, error: data });
    }
  };
  /**
   * Function fetches user of the currently logged in user
   *
   * @description
   * If response is 401(Unauthorized) it redirects the user to login page
   */
  const fetchuser = async () => {
    const { data, errFlag } = await getDataFromEndpoint("user/me");
    if (!errFlag) {
      setState({ ...state, user: data });
    } else {
      if (data === 401) {
        handleUnauthorized();
      }
      setState({ ...state, error: data });
    }
  };

  /**
   * @function
   * @description Function filters what checkboxes are on the page with the recieved id
   *
   * @param {int} pageId Id of page that is
   *
   */
  const mapCheckboxesToPage = (pageId) => {
    const data = state.data;
    if (!!data) {
      const checkboxes = data
        .filter((checkbox) => checkbox.pageNumber === pageId)
        .sort((a, b) => a.orderOnPage - b.orderOnPage);
      if (!!checkboxes) {
        if (checkboxes.length === 1 && checkboxes[0].type === "BUTTON") {
          return checkboxes[0];
        } else if (checkboxes.length === 0) {
          return null;
        } else {
          return checkboxes;
        }
      }
    }
  };

  /**
   * @function
   * @description Function filters checkboxes that are contained in the given array of stepper pages, sorts them and returns page titles along with pages routes.
   * It also removes routes that are not available for user type STUDENT
   * @param {array} pages stepper pages ids
   */
  const mapStepperDataToPage = (pages) => {
    const data = state.data;
    let stepperData = {};
    if (!!data) {
      stepperData.titles = Array.from(
        new Set(
          data
            .filter((checkbox) => pages.includes(checkbox.pageNumber))
            .sort((a, b) => a.orderOnStepper - b.orderOnStepper)
            .map((checkbox) => checkbox.pageTitle)
        )
      );
      stepperData.routes = Object.values(routerUrls)
        .filter((route) => pages.includes(route.value))
        .sort(
          (a, b) =>
            a.stepperPages.indexOf(a.value) - b.stepperPages.indexOf(b.value)
        );
      if (!!stepperData) {
        if (
          stepperData.titles.length === 0 ||
          stepperData.routes.length === 0
        ) {
          return null;
        } else {
          //remove unavailable routes (ex. for user type STUDENT)
          if (stepperData.titles.length !== stepperData.routes.length) {
            const allPageNumbers = data.map((checkbox) => checkbox.pageNumber);
            stepperData.routes.map((route) => {
              if (!allPageNumbers.includes(route.value)) {
                stepperData.routes = stepperData.routes.filter(
                  (r) => r.value !== route.value
                );
              }
            });
          }
          return stepperData;
        }
      }
    }
  };

  /**
   * @function
   * @description
   * Function checks if the user is an admin
   */
  const isUserAdmin = () => {
    if (state.user?.userType === "ADMIN") {
      setState((prevState) => ({ ...prevState, isAdmin: true }));
    } else {
      setState((prevState) => ({ ...prevState, isAdmin: false }));
    }
  };

  /**
   * @function
   * @description
   * Function fetches all data for current page
   */
  const fetchData = async () => {
    await fetchuser();
    await fetchBackendData();
    setState((prevState) => ({ ...prevState, loading: false }));
  };

  useEffect(() => {
    setState((prevState) => ({ ...prevState, isNavbarVisible: false }));

    /*
  	 	save current page to lastVisited for redirect after login
  	 */
    if (state.isAdmin) {
      localStorage.setItem("lastVisited", "/onboarding/admin/users");
    } else if (location.pathname !== "/onboarding") {
      localStorage.setItem("lastVisited", location.pathname);
    }
  }, [location]);

  useEffect(() => {
    fetchData();
    setLastVisitedLocation(location.pathname);
  }, []);

  const closeAlert = () => {
    setState({ ...state, alertOpen: false });
  };

  const toggleAlert = (message, severity) => {
    setState({
      ...state,
      alertOpen: true,
      alertMessage: message,
      alertSeverity: severity,
    });
  };

  return (
    <Switch>
      {isUserAuthenticated && !state.loading ? (
        <UserContext.Provider value={state.user}>
          <Route
            path={routerUrls.introPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <IntroPage
                  data={mapCheckboxesToPage(routerUrls.introPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.introPage.stepperPages
                  )}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  to={routerUrls.pocsPage.route}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.eventsPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <EventsPage
                  data={mapCheckboxesToPage(routerUrls.eventsPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.eventsPage.stepperPages
                  )}
                  from={routerUrls.languageCoursePage.route}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.hardwareInfoPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <HardwareInfoPage
                  data={mapCheckboxesToPage(routerUrls.hardwareInfoPage.value)}
                  userData={state.user}
                  stepperData={mapStepperDataToPage([
                    routerUrls.hardwareInfoPage.value,
                  ])}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  from={routerUrls.personalDataPage.route}
                  to={
                    state.user.userType !== UserTypes.STUDENT
                      ? routerUrls.docsPage.route
                      : routerUrls.aorsPage.route
                  }
                  toggleAlert={toggleAlert}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.interviewQuestionsPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <InterviewQuestionsPage
                  data={mapCheckboxesToPage(
                    routerUrls.interviewQuestionsPage.value
                  )}
                  stepperData={mapStepperDataToPage(
                    routerUrls.interviewQuestionsPage.stepperPages
                  )}
                  from={routerUrls.pocsPage.route}
                  to={routerUrls.personalDataPage.route}
                  userData={state.user}
                  toggleAlert={toggleAlert}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.docsPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                {state.user.userType !== UserTypes.STUDENT && (
                  <DocsPage
                    data={mapCheckboxesToPage(routerUrls.docsPage.value)}
                    stepperData={mapStepperDataToPage(
                      routerUrls.docsPage.stepperPages
                    )}
                    toggleAlert={toggleAlert}
                    updateCheckboxInfoData={updateCheckboxInfoData}
                    from={routerUrls.hardwareInfoPage.route}
                    to={routerUrls.aorsPage.route}
                    userData={state.user}
                    {...props}
                  />
                )}
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.aorsPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <AORsPage
                  data={mapCheckboxesToPage(routerUrls.aorsPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.aorsPage.stepperPages
                  )}
                  toggleAlert={toggleAlert}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  from={
                    state.user.userType !== UserTypes.STUDENT
                      ? routerUrls.docsPage.route
                      : routerUrls.hardwareInfoPage.route
                  }
                  to={routerUrls.otherDocsPage.route}
                  userData={state.user}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.otherDocsPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <OtherDocsPage
                  data={mapCheckboxesToPage(routerUrls.otherDocsPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.otherDocsPage.stepperPages
                  )}
                  toggleAlert={toggleAlert}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  from={routerUrls.aorsPage.route}
                  to={routerUrls.slackPage.route}
                  userData={state.user}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.gecoAccountPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <GecoPage
                  data={mapCheckboxesToPage(routerUrls.gecoAccountPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.gecoAccountPage.stepperPages
                  )}
                  from={routerUrls.trelloPage.route}
                  to={routerUrls.tgPage.route}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  toggleAlert={toggleAlert}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.tgPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <TgPage
                  data={mapCheckboxesToPage(routerUrls.tgPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.tgPage.stepperPages
                  )}
                  from={routerUrls.gecoAccountPage.route}
                  to={routerUrls.confluencePage.route}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  toggleAlert={toggleAlert}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.trelloPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <TrelloPage
                  data={mapCheckboxesToPage(routerUrls.trelloPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.trelloPage.stepperPages
                  )}
                  from={routerUrls.slackPage.route}
                  to={routerUrls.gecoAccountPage.route}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.slackPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <SlackPage
                  data={mapCheckboxesToPage(routerUrls.slackPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.slackPage.stepperPages
                  )}
                  from={routerUrls.otherDocsPage.route}
                  to={routerUrls.trelloPage.route}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  userData={state.user}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.githubPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <GithubPage
                  data={mapCheckboxesToPage(routerUrls.githubPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.githubPage.stepperPages
                  )}
                  from={routerUrls.confluencePage.route}
                  to={routerUrls.seeVeePage.route}
                  userData={state.user}
                  {...props}
                  toggleAlert={toggleAlert}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.seeVeePage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <SeeVeePage
                  data={mapCheckboxesToPage(routerUrls.seeVeePage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.seeVeePage.stepperPages
                  )}
                  from={routerUrls.githubPage.route}
                  to={routerUrls.tamTamyPage.route}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  userData={state.user}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.tamTamyPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <TamTamyPage
                  data={mapCheckboxesToPage(routerUrls.tamTamyPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.tamTamyPage.stepperPages
                  )}
                  from={routerUrls.seeVeePage.route}
                  to={routerUrls.gDrivePage.route}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  userData={state.user}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.gDrivePage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <GDrivePage
                  data={mapCheckboxesToPage(routerUrls.gDrivePage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.gDrivePage.stepperPages
                  )}
                  from={routerUrls.tamTamyPage.route}
                  to={routerUrls.miroPage.route}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  userData={state.user}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.miroPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <MiroPage
                  data={mapCheckboxesToPage(routerUrls.miroPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.miroPage.stepperPages
                  )}
                  from={routerUrls.gDrivePage.route}
                  to={routerUrls.personalDevelopmentDaysPage.route}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  userData={state.user}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.confluencePage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <ConfluencePage
                  data={mapCheckboxesToPage(routerUrls.confluencePage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.confluencePage.stepperPages
                  )}
                  from={routerUrls.tgPage.route}
                  to={routerUrls.githubPage.route}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />

          <Route
            path={routerUrls.allianzPage.route}
            exact
            render={(props) => {
              return (
                <ErrorBoundary>
                  <AllianzPage
                    data={mapCheckboxesToPage(routerUrls.allianzPage.value)}
                    stepperData={mapStepperDataToPage(
                      routerUrls.allianzPage.stepperPages
                    )}
                    from={routerUrls.personalDevelopmentDaysPage.route}
                    to={routerUrls.medicalCheckPage.route}
                    userData={state.user}
                    updateCheckboxInfoData={updateCheckboxInfoData}
                    {...props}
                  />
                  <CustomSnackbar
                    message={state.alertMessage}
                    closeAlert={closeAlert}
                    open={state.alertOpen}
                    severity={state.alertSeverity}
                  />
                </ErrorBoundary>
              );
            }}
          />
          <Route
            path={routerUrls.personalDevelopmentDaysPage.route}
            exact
            render={(props) => {
              return (
                <ErrorBoundary>
                  <PersonalDevelopmentDaysPage
                    data={mapCheckboxesToPage(
                      routerUrls.personalDevelopmentDaysPage.value
                    )}
                    stepperData={mapStepperDataToPage(
                      routerUrls.personalDevelopmentDaysPage.stepperPages
                    )}
                    from={routerUrls.miroPage.route}
                    to={routerUrls.allianzPage.route}
                    userData={state.user}
                    updateCheckboxInfoData={updateCheckboxInfoData}
                    {...props}
                  />
                  <CustomSnackbar
                    message={state.alertMessage}
                    closeAlert={closeAlert}
                    open={state.alertOpen}
                    severity={state.alertSeverity}
                  />
                </ErrorBoundary>
              );
            }}
          />
          <Route
            path={routerUrls.medicalCheckPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <MedicalCheckPage
                  data={mapCheckboxesToPage(routerUrls.medicalCheckPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.medicalCheckPage.stepperPages
                  )}
                  from={routerUrls.allianzPage.route}
                  to={routerUrls.apSystemsPage.route}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.apSystemsPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <APSystemsPage
                  data={mapCheckboxesToPage(routerUrls.apSystemsPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.apSystemsPage.stepperPages
                  )}
                  from={routerUrls.medicalCheckPage.route}
                  to={routerUrls.languageCoursePage.route}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.languageCoursePage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <LanguageCoursePage
                  data={mapCheckboxesToPage(
                    routerUrls.languageCoursePage.value
                  )}
                  stepperData={mapStepperDataToPage(
                    routerUrls.languageCoursePage.stepperPages
                  )}
                  from={routerUrls.apSystemsPage.route}
                  to={routerUrls.eventsPage.route}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.pocsPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <POCsPage
                  data={mapCheckboxesToPage(routerUrls.pocsPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.pocsPage.stepperPages
                  )}
                  from={routerUrls.introPage.route}
                  to={routerUrls.interviewQuestionsPage.route}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.personalDataPage.route}
            exact
            render={(props) => (
              <ErrorBoundary>
                <PersonalDataPage
                  data={mapCheckboxesToPage(routerUrls.personalDataPage.value)}
                  stepperData={mapStepperDataToPage(
                    routerUrls.personalDataPage.stepperPages
                  )}
                  toggleAlert={toggleAlert}
                  from={routerUrls.interviewQuestionsPage.route}
                  to={routerUrls.hardwareInfoPage.route}
                  userData={state.user}
                  updateCheckboxInfoData={updateCheckboxInfoData}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          <Route
            path={routerUrls.userProfilePage.route.replace(
              ":userId",
              state.user.id
            )}
            exact
            render={(props) => (
              <ErrorBoundary>
                <UserProfilePage
                  toggleAlert={toggleAlert}
                  userId={state.user.id}
                  isAdmin={state.user.userType === UserTypes.ADMIN}
                  {...props}
                />
                <CustomSnackbar
                  message={state.alertMessage}
                  closeAlert={closeAlert}
                  open={state.alertOpen}
                  severity={state.alertSeverity}
                />
              </ErrorBoundary>
            )}
          />
          {state.progress === 100 && !state.user.onboarded && (
            <>
              <Route
                path={routerUrls.checkboxesCheckedPage.route}
                exact
                render={(props) => (
                  <ErrorBoundary>
                    <CheckedCheckboxPage
                      handleClick={handleUserOnboarded}
                      handleHamburgerClick={handleHamburgerClick}
                    />
                  </ErrorBoundary>
                )}
              />
              <Redirect to={routerUrls.checkboxesCheckedPage.route} />
            </>
          )}
          <AdminRoute
            path={routerUrls.adminPage.route}
            isUserAdmin={state.isAdmin}
            component={AdminPage}
            render={(props) => (
              <ErrorBoundary>
                <AdminPage {...props} />
              </ErrorBoundary>
            )}
            handleUnauthorized={handleUnauthorized}
            handleHamburgerClick={handleHamburgerClick}
            userData={state.user}
          />
        </UserContext.Provider>
      ) : (
        <InfoLabel text="Loading..." />
      )}
    </Switch>
  );
};

export default withRouter(Onboarding);
