import styled from "styled-components";
import { Link } from "react-router-dom";
import { breakpoints } from "../../../utils/cssBreakpoints";

export const StyledLink = styled(Link)`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  max-width: 5.5em;
  padding: 0.25em 1em 0.25em 1em;

  span {
    font-size: 0.8em;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }
  box-shadow: 0px 4.60469px 10.3605px rgba(0, 0, 0, 0.25);
  border-radius: 0.35rem;
  ${(props) => {
    if (props.dir === 1) {
      return `
					background: linear-gradient(90deg, #2899C1 0%, #39BACE 38.08%, #40D4D1 80.34%, #44EFD0 100.17%);`;
    } else {
      return `
					background: linear-gradient(90deg, #44EFD0 0%, #40D4D1 15.65%, #39BACE 56.87%, #2899C1 100.17%);`;
    }
  }}

  justify-self: ${(props) => {
    if (props.dir === 1) {
      return `end;`;
    } else {
      return `start;`;
    }
  }};
  ${(props) => {
    if (props.dir === 1) {
      return `grid-column-end:13;`;
    }
  }}
  :hover {
    cursor: pointer;
  }
  img {
    margin: 0;
    margin-right: 1.5%;
    padding: 0;
    width: 20%;
    vertical-align: middle;
  }
  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
    width: 100%;
    height: 100%;
    font-size: 1.5em;
    img {
      width: 25%;
    }
  }
  @media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xlg}px) {
    width: 90%;
    font-size: 1.3em;
  }
`;
