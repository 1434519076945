import PropTypes from "prop-types";
import React from "react";
import { BaseLayout } from "../../components";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper";
import TextContainer from "../../components/text-editor/TextContainer.js";

/**
 * @component
 * @description Component renders AP Systems page
 * @param  {string} to path to next page
 * @param  {string} from  path to previous page
 * @param  {Array} data CheckboxInfo data for the current page
 * @param  {Object} stepperData stepper pages data; consist of lists of page titles and page routes
 * @param  {function} updateCheckboxInfoData send updated checkboxInfo data to BE
 */
const APSystemsPage = ({
  to,
  from,
  data,
  stepperData,
  updateCheckboxInfoData,
}) => {
  return (
    <BaseLayout to={to} from={from} hasTextEditor={true}>
      <HorizontalLinearStepper
        stepTitles={stepperData.titles}
        stepRoutes={stepperData.routes}
        currentStep={data[0].orderOnStepper - 2}
        stepperMargin={"0 -20% 2em -20%"}
      >
        <TextContainer
          data={data}
          updateCheckboxInfoData={updateCheckboxInfoData}
        />
      </HorizontalLinearStepper>
    </BaseLayout>
  );
};

APSystemsPage.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string,
  data: PropTypes.array.isRequired,
  stepperData: PropTypes.object.isRequired,
  updateCheckboxInfoData: PropTypes.func.isRequired,
};
export default APSystemsPage;
