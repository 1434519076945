import styled from "styled-components";
export const StyledAddUserInformationForm = styled.form`
margin-left: 5%;
	width: 35%;
	div {
		outline: none;
		box-sizing: border-box;
		display: grid;
		grid-template-columns: 1fr 50px;

		place-items: stretch;
		text-align: center;
		height: 50px;
		padding: 0px 10px;
		border: 3px solid #4fd5d7;
		background-color: white;
		border-radius: 10px;
	}
	label {
		padding: 15px;
		font-size: 1em;
	}
	input {
		display: block;
		font-size: 1em;
		margin: 0;
		background-color: transparent;
		border: 0;
		&::placeholder {
			color: black;
		}
		&:invalid {
			border: 0;
			box-shadow: none;
		}
	}

	button[type='submit'] {
		box-sizing: border-box;
		margin: 0;
		background-color: transparent;
		border: 0;
		font-size: 2em
		border-radius: 50%;
		place-items: center;
		display: grid;
		img {
			border-radius: 50%;
			padding: 5px;
			fill: black;
			width: 30px;
			height: 30px;
		}
		img:hover {
			background-color: #4fd5d7;
			cursor: pointer;
		}
	}
	
	button[type='submit']:focus {
	    img {
			animation: submitAnimation 2s 1;
		}
	}
	
	@keyframes submitAnimation
    {
    0%      {background: #4fd5d7;}
    50%     {background: #37db62;}
    100%    {background: white;}
    }
`;
