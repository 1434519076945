import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiOutlinePlus } from "react-icons/ai";
import BaseLayout from "../../components/base-layout/BaseLayout";
import { StyledUploadButton } from "../../components/buttons/StyledUploadButton";
import InterviewQuestion from "../../components/interview-question/InterviewQuestion";
import ModalWindow from "../../components/modal-window/ModalWindow";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper";
import TextContainer from "../../components/text-editor/TextContainer.js";
import { FileTypes } from "../../constants/FileTypes.js";
import { UserTypes } from "../../constants/UserTypes";
import { urls } from "../../utils/baseUrl";
import { UserContext } from "../../utils/context/UserContext";
import deleteDataFromEndpoint from "../../utils/deleteDataFromEndpoint";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint";
import postDataToEndpoint from "../../utils/postDataToEndPoint";
import putDataToEndpoint from "../../utils/putDataToEndPoint";
import uploadFileToEndpoint from "../../utils/uploadFileToEndpoint";
import FileUploader from "../application-docs-page/FileUploader";
import { StyledButtonsContainer } from "../buttons-container/StyledButtonsContainer";
import { ScrollContainer } from "../scroll-container/ScrollContainer";

/**
 * @component
 * @description Component renders Introduce yourself page
 * @param  {string} to path to next page
 * @param  {string} from  path to previous page
 * @param  {Array} data CheckboxInfo data for the current page
 * @param  {Object} stepperData stepper pages data; consist of lists of page titles and page routes
 * @param  {function} updateCheckboxInfoData send updated checkboxInfo data to BE
 * @param  {function} toggleAlert toggle alert message
 */
const InterviewQuestionsPage = ({
  from,
  to,
  data,
  stepperData,
  toggleAlert,
  updateCheckboxInfoData,
}) => {
  const userData = useContext(UserContext);
  const slackInput = useRef(null);
  const avatarInput = useRef(null);
  const seriousInput = useRef(null);

  const [answers, setAnswers] = useState([""]);
  const [error, setError] = useState();
  const [qAndA, setQAndA] = useState([]);
  const [sent, setSent] = useState(false);
  const [chosenUserType, setChosenUserType] = useState(
    userData.userType === UserTypes.ADMIN
      ? UserTypes.FULLTIME
      : userData.userType,
  );
  const { t } = useTranslation();

  const [slackPicture, setSlackPicture] = useState(null);
  const [avatarPicture, setAvatarPicture] = useState(null);
  const [seriousPicture, setSeriousPicture] = useState(null);

  const [slackPicturePreview, setSlackPicturePreview] = useState(null);
  const [avatarPicturePreview, setAvatarPicturePreview] = useState(null);
  const [seriousPicturePreview, setSeriousPicturePreview] = useState(null);

  const allowedImageFormats = ["image/jpeg", "image/png"];

  const [documentToDelete, setDocumentToDelete] = useState({});
  const [deleteModalIsOpen, setDeleteModalToOpen] = useState(false);

  const toggleDeleteModal = (document) => {
    document ? setDocumentToDelete(document) : setDocumentToDelete({});
    setDeleteModalToOpen(!deleteModalIsOpen);
  };

  const validate = () => {
    for (let i = 0; i < answers.length; i++) {
      if (answers[i].trim().length === 0) {
        return false;
      }
    }

    return true;
  };

  var validateImagesMessage = "";
  const validateImages = () => {
    if (!slackPicture || !avatarPicture) {
      validateImagesMessage = "All pictures should be uploaded";

      return false;
    }

    if (chosenUserType !== UserTypes.STUDENT && !seriousPicture) {
      validateImagesMessage = "All pictures should be uploaded";

      return false;
    }

    if (!allowedImageFormats.includes(slackPicture.type)) {
      validateImagesMessage = "Invalid slack picture format";

      return false;
    } else if (!allowedImageFormats.includes(avatarPicture.type)) {
      validateImagesMessage = "Invalid avatar picture format";

      return false;
    } else if (
      chosenUserType !== UserTypes.STUDENT &&
      !allowedImageFormats.includes(seriousPicture.type)
    ) {
      validateImagesMessage = "Invalid serious picture format";

      return false;
    }

    return true;
  };

  useEffect(() => {
    getSent();
    getQuestions();
    getImages();
  }, []);

  useEffect(() => {
    if (!slackPicture) return;

    if (!Array.isArray(slackPicture)) {
      setSlackPicturePreview(URL.createObjectURL(slackPicture));
    }
  }, [slackPicture]);

  useEffect(() => {
    if (!avatarPicture) return;

    if (!Array.isArray(avatarPicture)) {
      setAvatarPicturePreview(URL.createObjectURL(avatarPicture));
    }
  }, [avatarPicture]);

  useEffect(() => {
    if (!seriousPicture) return;

    if (!Array.isArray(seriousPicture)) {
      setSeriousPicturePreview(URL.createObjectURL(seriousPicture));
    }
  }, [seriousPicture]);

  const getQuestions = async () => {
    try {
      const { data, errFlag } = await getDataFromEndpoint(`${urls.answerUrl}`);

      if (errFlag) {
        throw new Error(t("interview-questions.data-error"));
      }
      setQAndA(data);
      const tempAnswers = [];
      for (const d of data) {
        tempAnswers.push(d.answer);
      }
      setAnswers(tempAnswers);
    } catch (err) {
      setError(true);
    }
  };

  const getImages = async () => {
    try {
      const { data, errFlag } = await getDataFromEndpoint(
        `${urls.documentsUrl.replace(":fileType", "SLACK_PICTURE")}`,
      );
      if (data && data.length) {
        setSlackPicture(data);
      }
    } catch (err) {
      setError(true);
    }

    try {
      const { data, errFlag } = await getDataFromEndpoint(
        `${urls.documentsUrl.replace(":fileType", "AVATAR_PICTURE")}`,
      );
      if (data && data.length) {
        setAvatarPicture(data);
      }
    } catch (err) {
      setError(true);
    }

    if (chosenUserType !== UserTypes.STUDENT) {
      try {
        const { data, errFlag } = await getDataFromEndpoint(
          `${urls.documentsUrl.replace(":fileType", "SERIOUS_PICTURE")}`,
        );
        if (data && data.length) {
          setSeriousPicture(data);
        }
      } catch (err) {
        setError(true);
      }
    }
  };

  const deletePicture = async () => {
    const { errFlag } = deleteDataFromEndpoint(
      urls.deleteDocumentUrl
        .replace(":fileType", documentToDelete.fileType)
        .replace(":fileName", documentToDelete.filename),
      {},
    );
    if (!errFlag) {
      toggleAlert(`Document ${documentToDelete.filename} deleted successfully`);
    } else {
      toggleAlert("Couldn't delete document!", "error");
    }
  };

  const getSent = async () => {
    try {
      const { data, errFlag } = await getDataFromEndpoint(
        `${urls.answersSentUrl}`,
      );
      if (errFlag) {
        throw new Error("Data error");
      }
      setSent(data.answersSentToAdmin);
    } catch (err) {
      setError(true);
    }
  };

  const handleAnswerChange = (newAnswer, index) => {
    answers[index] = newAnswer;
    setAnswers(answers);
  };

  const handleSave = () => {
    if (sent) {
      toggleAlert(t("interview-questions.already-sent"), "info");
    } else if (!validate()) {
      toggleAlert("All questions should be answered", "info");
    } else if (!validateImages()) {
      toggleAlert(validateImagesMessage, "info");
    } else {
      saveAnswers(true);
      saveImages();
    }
  };

  const handleDelete = (fileType) => {
    deletePicture();
    toggleDeleteModal();

    if (fileType === "SLACK_PICTURE") setSlackPicture(null);
    if (fileType === "AVATAR_PICTURE") setAvatarPicture(null);
    if (fileType === "SERIOUS_PICTURE") setSeriousPicture(null);
  };

  const saveImages = async () => {
    // Slack picture upload
    let fileType = FileTypes.SLACK_PICTURE;
    let formData = new FormData();
    formData.append("file", slackPicture, slackPicture.name);
    formData.append("fileType", fileType);

    uploadFileToEndpoint(urls.uploadPictureUrl, formData)
      .then(() => {
        setSlackPicturePreview(null);
        toggleAlert(`${fileType} uploaded successfully`);
      })
      .catch(() => {
        toggleAlert(`Error while uploading documents ${fileType}`, "error");
      });

    if (chosenUserType !== UserTypes.STUDENT) {
      // Serious picture upload
      fileType = FileTypes.SERIOUS_PICTURE;
      formData = new FormData();
      formData.append("file", seriousPicture, seriousPicture.name);
      formData.append("fileType", fileType);
      uploadFileToEndpoint(urls.uploadPictureUrl, formData)
        .then(() => {
          setSeriousPicturePreview(null);
          toggleAlert(`${fileType} uploaded successfully`);
        })
        .catch(() => {
          toggleAlert(`Error while uploading documents ${fileType}`, "error");
        });
    }

    // Avatar picture upload
    fileType = FileTypes.AVATAR_PICTURE;
    formData = new FormData();
    formData.append("file", avatarPicture, avatarPicture.name);
    formData.append("fileType", fileType);
    uploadFileToEndpoint(urls.uploadPictureUrl, formData)
      .then(() => {
        setAvatarPicturePreview(null);
        toggleAlert(`${fileType} uploaded successfully`);
        getImages();
      })
      .catch(() => {
        toggleAlert(`Error while uploading documents ${fileType}`, "error");
      });
  };

  const saveAnswers = async (showAlert) => {
    const tempAnswers = prepareAnswersToSend();
    try {
      const { data, errFlag } = await postDataToEndpoint(
        `${urls.answerUrl}`,
        JSON.parse(tempAnswers),
      );
      if (!errFlag) {
        if (showAlert) {
          toggleAlert(t("interview-questions.saved"));
        }
        return data;
      } else {
        toggleAlert(data.data.message, "error");
        throw new Error(t("interview-questions.data-error"));
      }
    } catch (err) {
      setError(err);
    }
  };

  const prepareAnswersToSend = () => {
    const tempAnswers = [];
    let i = 0;
    for (const q of qAndA) {
      tempAnswers.push({ questionId: q.questionId, answerText: answers[i] });
      i++;
    }
    return JSON.stringify(tempAnswers);
  };

  const handleAdmins = () => {
    if (sent) {
      toggleAlert(t("interview-questions.already-sent"), "info");
    } else {
      saveAnswers(false).then((res) => {
        if (res.filter((aAndQ) => aAndQ.answerText === "").length > 0) {
          toggleAlert(t("interview-questions.all-answered"), "info");
        } else if (!validateImages()) {
          toggleAlert(validateImagesMessage, "info");
        } else {
          sendAnswersToAdmin();
        }
      });
    }
  };

  const sendAnswersToAdmin = async () => {
    try {
      const { data, errFlag } = await putDataToEndpoint(
        `${urls.answersSentUrl}`,
        {},
      );
      if (!errFlag) {
        setSent(true);
        toggleAlert(t("interview-questions.answers-sent"));
      } else {
        toggleAlert(data.data.message, "warning");
      }
    } catch (err) {
      setError(err);
    }
  };

  const fetchChosenUserType = (userType) => {
    setChosenUserType(userType);
  };

  return (
    <BaseLayout from={from} to={to} hasTextEditor={true}>
      <HorizontalLinearStepper
        stepTitles={stepperData.titles}
        stepRoutes={stepperData.routes}
        currentStep={data[0].orderOnStepper - 1}
      >
        <h2
          style={{ color: "#57ADDD", fontWeight: "bold", marginBottom: "1%" }}
        >
          {data[0].pageTitle}
          <br />
        </h2>
        <TextContainer
          data={data}
          updateCheckboxInfoData={updateCheckboxInfoData}
          fetchChosenUserType={fetchChosenUserType}
        >
          <React.Fragment>
            <ScrollContainer>
              {qAndA.map((q, index) => {
                return (
                  <InterviewQuestion
                    readOnly={sent}
                    marginLeft={"6vw"}
                    marginRight={"20vw"}
                    key={index}
                    questionText={index + 1 + ". " + q.question}
                    answer={q.answer}
                    onChangeAnswer={(newAnswer) =>
                      handleAnswerChange(newAnswer, index)
                    }
                  />
                );
              })}
            </ScrollContainer>
            <br />
            <StyledUploadButton labelFontSize={"1.0em"}>
              <label>Your casual picture</label>
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/jpg, image/png"
                onChange={(e) => setSlackPicture(e.target.files[0])}
                ref={slackInput}
              />
              <button onClick={() => slackInput.current.click()}>
                <AiOutlinePlus />
                <span>Upload</span>
              </button>
            </StyledUploadButton>
            {slackPicturePreview && slackPicture ? (
              <>
                <img src={slackPicturePreview} width="100" />
                <br />
                <small>{slackPicture.name}</small>
                <br />
                <Button onClick={() => setSlackPicture(null)}>
                  Remove image
                </Button>
                <br />
                <br />
              </>
            ) : null}

            {slackPicture &&
            Array.isArray(slackPicture) &&
            slackPicture.length ? (
              <>
                <span
                  style={{
                    color: "#57ADDD",
                    marginLeft: "1em",
                    marginTop: "1em",
                  }}
                >
                  Uploaded:&nbsp;
                  <button
                    onClick={() => toggleDeleteModal(slackPicture[0])}
                    style={{
                      background: "transparent",
                      color: "#57ADDD",
                      borderColor: "transparent",
                    }}
                    title="Delete picture"
                  >
                    {slackPicture[0].filename} &times;
                  </button>
                </span>
                <br />
                <br />

                <ModalWindow
                  headerText={
                    <h4>
                      <strong>
                        Are you sure you want to DELETE this picture?
                      </strong>
                    </h4>
                  }
                  isOpen={deleteModalIsOpen}
                  hideModal={toggleDeleteModal}
                  toDoIfConfirmed={() =>
                    handleDelete(documentToDelete.fileType)
                  }
                  body={
                    <div
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {documentToDelete.name || documentToDelete.filename}
                    </div>
                  }
                  cancelText={"Cancel"}
                  confirmText={"Delete"}
                />
              </>
            ) : null}
          </React.Fragment>
          <React.Fragment>
            <StyledUploadButton labelFontSize={"1.0em"}>
              <label>Your avatar picture</label>
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/jpg, image/png"
                onChange={(e) => setAvatarPicture(e.target.files[0])}
                ref={avatarInput}
              />
              <button onClick={() => avatarInput.current.click()}>
                <AiOutlinePlus />
                <span>Upload</span>
              </button>
            </StyledUploadButton>
            {avatarPicturePreview && avatarPicture ? (
              <>
                <img src={avatarPicturePreview} width="100" />
                <br />
                <small>{avatarPicture.name}</small>
                <br />
                <Button onClick={() => setAvatarPicture(null)}>
                  Remove image
                </Button>
                <br />
                <br />
              </>
            ) : null}

            {avatarPicture &&
            Array.isArray(avatarPicture) &&
            avatarPicture.length ? (
              <>
                <span
                  style={{
                    color: "#57ADDD",
                    marginLeft: "1em",
                    marginTop: "1em",
                  }}
                >
                  Uploaded:&nbsp;
                  <button
                    onClick={() => toggleDeleteModal(avatarPicture[0])}
                    style={{
                      background: "transparent",
                      color: "#57ADDD",
                      borderColor: "transparent",
                    }}
                    title="Delete picture"
                  >
                    {avatarPicture[0].filename} &times;
                  </button>
                </span>

                <ModalWindow
                  headerText={
                    <h4>
                      <strong>
                        Are you sure you want to DELETE this picture?
                      </strong>
                    </h4>
                  }
                  isOpen={deleteModalIsOpen}
                  hideModal={toggleDeleteModal}
                  toDoIfConfirmed={() =>
                    handleDelete(documentToDelete.fileType)
                  }
                  body={
                    <div
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {documentToDelete.name || documentToDelete.filename}
                    </div>
                  }
                  cancelText={"Cancel"}
                  confirmText={"Delete"}
                />
              </>
            ) : null}

            <br />
            <br />
          </React.Fragment>
          <>
            {chosenUserType !== UserTypes.STUDENT ? (
              <React.Fragment>
                <StyledUploadButton labelFontSize={"1.0em"}>
                  <label>Your serious picture</label>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/jpg, image/png"
                    onChange={(e) => setSeriousPicture(e.target.files[0])}
                    ref={seriousInput}
                  />
                  <button onClick={() => seriousInput.current.click()}>
                    <AiOutlinePlus />
                    <span>Upload</span>
                  </button>
                </StyledUploadButton>
                {seriousPicturePreview && seriousPicture ? (
                  <>
                    <img src={seriousPicturePreview} width="100" />
                    <br />
                    <small>{seriousPicture.name}</small>
                    <br />
                    <Button onClick={() => setSeriousPicture(null)}>
                      Remove image
                    </Button>
                    <br />
                    <br />
                  </>
                ) : null}

                {seriousPicture &&
                Array.isArray(seriousPicture) &&
                seriousPicture.length ? (
                  <>
                    <span
                      style={{
                        color: "#57ADDD",
                        marginLeft: "1em",
                        marginTop: "1em",
                      }}
                    >
                      Uploaded:&nbsp;
                      <button
                        onClick={() => toggleDeleteModal(seriousPicture[0])}
                        style={{
                          background: "transparent",
                          color: "#57ADDD",
                          borderColor: "transparent",
                        }}
                        title="Delete picture"
                      >
                        {seriousPicture[0].filename} &times;
                      </button>
                    </span>
                    <br />
                    <br />

                    <ModalWindow
                      headerText={
                        <h4>
                          <strong>
                            Are you sure you want to DELETE this picture?
                          </strong>
                        </h4>
                      }
                      isOpen={deleteModalIsOpen}
                      hideModal={toggleDeleteModal}
                      toDoIfConfirmed={() =>
                        handleDelete(documentToDelete.fileType)
                      }
                      body={
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {documentToDelete.name || documentToDelete.filename}
                        </div>
                      }
                      cancelText={"Cancel"}
                      confirmText={"Delete"}
                    />
                  </>
                ) : null}
              </React.Fragment>
            ) : (
              <></>
            )}
          </>
        </TextContainer>
        <StyledButtonsContainer disabled={sent}>
          <Button variant={"outline-info"} onClick={handleSave} disabled={sent}>
            Save
          </Button>
          <Button
            variant={"outline-info"}
            onClick={handleAdmins}
            disabled={sent}
          >
            Send to Admins
          </Button>
        </StyledButtonsContainer>
      </HorizontalLinearStepper>
    </BaseLayout>
  );
};

InterviewQuestionsPage.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string,
  data: PropTypes.array.isRequired,
  updateCheckboxInfoData: PropTypes.func.isRequired,
  toggleAlert: PropTypes.func,
  stepperData: PropTypes.object.isRequired,
};
export default InterviewQuestionsPage;
