import styled from "styled-components";

export const AnswersContainer = styled.div`
  font-size: 1.3em;
  padding: 2em;
  padding-bottom: 0;

  div {
    grid-column: span
      ${({ hasAside }) => {
        return hasAside ? "10" : "12";
      }};
    display: grid;
    grid-gap: 30px;
    grid-auto-columns: 1fr;
    align-items: center;
    ${({ alignItemsTop }) => {
      return alignItemsTop
        ? "grid-auto-columns: auto;grid-gap:20px; align-content:start;"
        : null;
    }};
  }
`;
