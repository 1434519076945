import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints";

export const Navbar = styled.nav`
  height: 100%;
  width: 350px;

  display: grid;
  column-count: 2;
  grid-template-rows: 200px auto;

  background: #ffffff;
  box-shadow: 10px -23px 26px 5px rgba(0, 0, 0, 0.25);

  font-weight: 400;
  font-size: 20px;

  img {
    max-height: 90px;
    justify-self: center;
  }

  & > div {
    display: grid;
    min-height: 450px;
    grid-template-columns: 1fr auto auto;
  }

  div ul {
    width: 100%;
    margin: 0;
    padding-left: 30px;
    text-align: left;
    list-style-type: none;
  }

  li {
    display: block;
    margin: 0;
    padding: 0;
  }
  li a {
    padding-left: 20px;
    color: black;
    text-decoration: none;
    display: grid;
    height: 50px;
    align-items: center;
    &:hover {
      background-color: #b9dde2;
      width: auto;
    }
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
  }
  .heading-list-item {
    padding-left: 20px;
    color: black;
    text-decoration: none;
    display: grid;
    height: 50px;
    margin-bottom: 8px;
    align-items: center;
    &:hover {
      background-color: #b9dde2;
    }
    cursor: pointer;
    grid-template-columns: 1fr auto;
  }
  li label img {
    width: 30px;
    padding: 10px;
  }

  input[type="checkbox"] {
    display: none;
  }

  li input[type="checkbox"]:checked ~ label > img {
    transform: rotate(180deg);
  }
  li input[type="checkbox"] ~ ul {
    display: none;
  }
  li input[type="checkbox"]:checked ~ ul {
    display: list-item;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    width: 350px;
    div {
      width: 350px;
    }
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    width: 400px;
    div {
      width: 400px;
    }
    div ul {
      padding-left: 50px;
    }
    li a {
      height: 40px;
      font-size: 0.8em;
    }

    .heading-list-item {
      height: 40px;
      font-size: 0.9em;
    }
  }

  @media screen and (min-width: ${breakpoints.xlg}px) {
    width: 500px;
    div {
      width: 500px;
    }
    div ul {
      padding-left: 60px;
    }
    li a {
      height: 60px;
      font-size: 1.1em;
    }

    .heading-list-item {
      height: 60px;
      font-size: 1.2em;
    }
  }
`;
