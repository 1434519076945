import PropTypes from "prop-types";
import React, { useState } from "react";
import { StyledTextContainer } from "../../containers/text-container/StyledTextContainer";

/**
 * @component
 * @description Component renders Form fields questions and answers
 * @param  {string} marginLeft left margin
 * @param  {string} marginRight right margin
 * @param  {string} questionText question
 * @param  {string} formFieldLabel label
 * @param  {string} formFieldHint hint
 * @param  {string} answerFieldType type of answer field
 * @param  {string} answer answer
 * @param  {function} onChangeAnswer handler for changing answer
 */
const FormField = ({
  marginLeft,
  marginRight,
  formFieldLabel,
  formFieldHint,
  answerFieldType,
  answer,
  onChangeAnswer,
}) => {
  const [text, setText] = useState(answer);

  const handleChange = (e) => {
    setText(e.target.value);
    onChangeAnswer(e.target.value);
  };

  const handleCheckbox = (e) => {
    let checkboxState = "";
    if (e.target.checked) {
      checkboxState = "on";
    } else {
      checkboxState = "off";
    }
    setText(checkboxState);
    onChangeAnswer(checkboxState);
  };
  return (
    <StyledTextContainer marginLeft={marginLeft} marginRight={marginRight}>
      <label>{formFieldLabel}</label>

      {answerFieldType === "SHORT_TEXT" && (
        <textarea
          onChange={(event) => handleChange(event)}
          defaultValue={text}
          placeholder={formFieldHint}
          style={{ height: "40px" }}
        />
      )}
      {answerFieldType === "LONG_TEXT" && (
        <textarea
          onChange={(event) => handleChange(event)}
          defaultValue={text}
          placeholder={formFieldHint}
          style={{ height: "100px" }}
        />
      )}
      {answerFieldType === "DATETIME" && (
        <input
          type="datetime-local"
          onChange={(event) => handleChange(event)}
          defaultValue={text}
          placeholder={formFieldHint}
        />
      )}
      {answerFieldType === "EMAIL" && (
        <textarea
          onChange={(event) => handleChange(event)}
          defaultValue={text}
          placeholder={formFieldHint}
          style={{ height: "40px" }}
        />
      )}
      {answerFieldType === "CHECKBOX" && (
        <input
          type="checkbox"
          onChange={(event) => handleCheckbox(event)}
          defaultChecked={text === "on" ? true : false}
        />
      )}
    </StyledTextContainer>
  );
};

FormField.propTypes = {
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  formFieldLabel: PropTypes.string.isRequired,
  formFieldHint: PropTypes.string,
  answerFieldType: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  onChangeAnswer: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default FormField;
