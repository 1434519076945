import { FiTwitter } from "react-icons/fi";
import { AiOutlineLinkedin } from "react-icons/ai";
import React from "react";

export const footerInfo = [
  {
    columnItems: [
      {
        text: "Comsysto Reply Official website",
        link: "https://www.comsystoreply.com/",
      },
      {
        text: "Reply Group website",
        link: "https://www.reply.com/en/",
      },
      {
        text: "Contacts",
        link: "https://www.comsystoreply.com/offices/zagreb-hrv",
      },
    ],
  },
  {
    columnItems: [
      {
        text: "Follow us ",
        icons: (
          <>
            <a href={"https://twitter.com/comsysto"}>
              <FiTwitter size={30} />
            </a>
            <a href={"https://www.linkedin.com/company/comsysto-gmbh/"}>
              <AiOutlineLinkedin size={30} />
            </a>
          </>
        ),
      },
    ],
  },
];
