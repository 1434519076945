import React, { useEffect, useState } from "react";
import { ScrollContainer } from "..";
import InterviewQuestion from "../../components/interview-question/InterviewQuestion";
import { StyledRoundButton } from "../../components/buttons/StyledRoundButton";
import { AnswersContainer } from "../answers-container/AnswersContainer";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint";
import { adminUrls } from "../../utils/baseUrl";
import putDataToEndpoint from "../../utils/putDataToEndPoint";
import { VerticalButtonsContainer } from "../vertical-buttons-container/VerticalButtonsContainer";
import postDataToEndpoint from "../../utils/postDataToEndPoint";
import { Mention, MentionsInput } from "react-mentions";
import ModalWindow from "../../components/modal-window/ModalWindow";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EmployeeInterview = ({
  userId,
  introductionMessageValue,
  handleMentionChange,
  userMentionData,
  handleRejectTextChange,
  rejectMessageValue,
  toggleAlert,
}) => {
  const [qAndA, setQAndA] = useState([]);
  const [error, setError] = useState(null);
  const [sentToAdmin, setSentToAdmin] = useState(false);
  const [slackPicture, setSlackPicture] = useState();
  let [approveModalIsOpen, setApproveModalIsOpen] = useState(false);
  let [rejectModalIsOpen, setRejectModalIsOpen] = useState(false);
  const [isApproved, setApproved] = useState(null);
  const { t } = useTranslation();

  const toggleApproveModal = () => {
    if (isApproved) {
      toggleAlert(t("employee-interview.info-message"), "info");
    } else {
      setApproveModalIsOpen(!approveModalIsOpen);
    }
  };
  const toggleRejectModal = () => {
    if (isApproved) {
      toggleAlert(t("employee-interview.info-message"), "info");
    } else {
      setRejectModalIsOpen(!rejectModalIsOpen);
    }
  };

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const { data, errFlag } = await getDataFromEndpoint(
          `${adminUrls.answerUrl.replace(":userId", userId)}`
        );
        if (errFlag) {
          throw new Error(t("employee-interview.error"));
        }
        setQAndA(data);
      } catch (err) {
        setError(true);
      }
    };

    const getAreAnswersAndPictureSentToAdmin = async () => {
      try {
        const { data, errFlag } = await getDataFromEndpoint(
          `${adminUrls.answersSentToAdminUrl.replace("userId", userId)}`
        );
        if (errFlag) {
          throw new Error(t("employee-interview.error"));
        }
        setSentToAdmin(data.answersSentToAdmin);
      } catch (err) {
        setError(true);
      }
    };

    const getPicture = async () => {
      try {
        const { data, errFlag } = await getDataFromEndpoint(
          `${adminUrls.getSlackPictureUrl.replace("userId", userId)}`
        );
        if (errFlag) {
          throw new Error(t("employee-interview.error"));
        }
        setSlackPicture("data:image;base64," + data);
      } catch (err) {
        setError(true);
      }
    };

    const getAnswersApprovedFlag = async () => {
      try {
        const { data, errFlag } = await getDataFromEndpoint(
          `${adminUrls.answerApprovalUrl.replace("userId", userId)}`
        );
        if (errFlag) {
          throw new Error(t("employee-interview.error-be"));
        }
        setApproved(data.approved);
      } catch (err) {
        setError(true);
      }
    };

    getQuestions();
    getAnswersApprovedFlag();
    getAreAnswersAndPictureSentToAdmin().then(() => getPicture());
  }, []);

  const handleApprove = () => {
    handleApproval(true).then(() => {
      sendAnswersAndPictureToSlack(introductionMessageValue);
      setApproved(true);
      toggleAlert(t("employee-interview.approved-message"));
    });
    toggleApproveModal();
  };

  const handleReject = () => {
    handleApproval(false).then(() => {
      sendRejectMessageToSlack(rejectMessageValue);
      setApproved(false);
      toggleAlert(t("employee-interview.rejected-message"), "warning");
    });
    toggleRejectModal();
  };

  const handleApproval = async (approved) => {
    try {
      const { data, errFlag } = await putDataToEndpoint(
        `${adminUrls.answerApprovalUrl.replace("userId", userId)}`,
        {
          approved: approved,
        }
      );
      if (errFlag) {
        throw new Error(t("employee-interview.error"));
      }
    } catch (err) {
      setError(true);
    }
  };

  const sendAnswersAndPictureToSlack = (introductionMessage) => {
    try {
      const { data, errFlag } = postDataToEndpoint(
        `${adminUrls.sendAnswersAndPictureToSlackUrl.replace(
          "userId",
          userId
        )}`,
        {
          introductionMessage: introductionMessage,
        }
      );
      if (errFlag) {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  };

  const sendRejectMessageToSlack = (message) => {
    try {
      const { data, errFlag } = postDataToEndpoint(
        `${adminUrls.sendMessageToUserOnSlackUrl.replace("userId", userId)}`,
        {
          message: message,
        }
      );
      if (errFlag) {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  };

  const textAreaStyle = {
    backgroundColor: "white",
    borderColor: "rgba(161,221,217,0.62)",
    borderWidth: "3px",
    borderRadius: "15px",
    minWidth: "100%",
    fontSize: "1.2em",
  };

  const mentionStyle = {
    backgroundColor: "rgba(161,221,217,0.62)",
    borderColor: "rgba(161,221,217,0.62)",
    borderWidth: "3px",
    borderRadius: "15px",
    height: "10vh",
    fontSize: "1.0em",
  };

  const approveModalBody = (
    <MentionsInput
      value={introductionMessageValue}
      onChange={handleMentionChange}
      placeholder={t("employee-interview.placeholder")}
      className="mentions"
      style={textAreaStyle}
      singleLine={false}
    >
      <Mention
        type="user"
        trigger="@"
        data={userMentionData}
        markup="<@__display__>"
        style={mentionStyle}
      />
    </MentionsInput>
  );
  const rejectModalBody = (
    <textarea
      style={textAreaStyle}
      value={rejectMessageValue}
      onChange={handleRejectTextChange}
    />
  );

  return (
    <>
      <AnswersContainer hasAside={true}>
        <ScrollContainer
          mainColor={"rgb(28,149,197)"}
          backgroundColor={"#ffffff"}
          borderColor={"rgb(115,215,217)"}
          maxHeight={"55vh"}
        >
          {qAndA.map((q, index) => {
            return (
              <InterviewQuestion
                readOnly={true}
                showAsDisabled={isApproved}
                key={index}
                questionText={index + 1 + ". " + q.question}
                answer={q.answer}
              />
            );
          })}
          <div style={{ display: "center", verticalAlign: "middle" }}>
            {sentToAdmin && (
              <Image
                src={slackPicture}
                alt={"Slack pic"}
                style={{
                  border: "5px solid rgb(20,195,231)",
                  borderRadius: "15px",
                  maxHeight: "30em",
                  maxWidth: "30em",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            )}
          </div>
        </ScrollContainer>
      </AnswersContainer>
      <VerticalButtonsContainer>
        <StyledRoundButton
          marginTop={"2.5em"}
          onClick={toggleRejectModal}
          disabled={!sentToAdmin}
          checked={!sentToAdmin}
        >
          Reject
        </StyledRoundButton>
        <StyledRoundButton
          marginTop={"2.5em"}
          onClick={toggleApproveModal}
          disabled={!sentToAdmin}
          checked={!sentToAdmin}
        >
          Approve
        </StyledRoundButton>
      </VerticalButtonsContainer>
      <ModalWindow
        headerText={t("employee-interview.header-rejected-text")}
        isOpen={rejectModalIsOpen}
        hideModal={toggleRejectModal}
        toDoIfConfirmed={() => handleReject()}
        body={rejectModalBody}
        cancelText={t("employee-interview.cancel")}
        confirmText={t("employee-interview.confirm")}
      />
      <ModalWindow
        headerText={t("employee-interview.header-insert-text")}
        isOpen={approveModalIsOpen}
        hideModal={toggleApproveModal}
        toDoIfConfirmed={() => handleApprove()}
        body={approveModalBody}
        cancelText={t("employee-interview.cancel")}
        confirmText={t("employee-interview.confirm")}
      />
    </>
  );
};

export default EmployeeInterview;
