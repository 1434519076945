import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 3rem;

  img {
    width: 30px;
  }

  & button {
    margin-left: 2rem;
    margin-top: 1.5vh;
    height: 45px;
    width: 50px;
    cursor: pointer;
    padding: 0;
    border-radius: 15px;
  }
`;
