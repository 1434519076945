import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints";
export const StyledAdminHeader = styled.header`
  margin: 0 auto;
  width: 100%;
  padding: 10px 6%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-items: center;
  p {
    display: block;
    justify-self: end;
    margin-right: 0;
  }
  button {
    justify-self: start;
  }

  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
    img {
      height: 75px;
    }
  }
`;
