import routerUrls from "../utils/routerUrls";

export const navigationInfo = {
  list: [
    {
      listItem: {
        id: "li",
        text: "1. Welcome",
        heading: true,
        dropDownButton: true,
        list: [
          {
            listItem: {
              id: "li",
              link: routerUrls.introPage.route,
              text: "Intro",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.pocsPage.route,
              text: "Your POCs",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.interviewQuestionsPage.route,
              text: "Profile Setup",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.personalDataPage.route,
              text: "Personal Data",
            },
          },
        ],
      },
    },
    {
      listItem: {
        id: "li",
        link: routerUrls.hardwareInfoPage.route,
        text: "2. Hardware",
        heading: true,
      },
    },
    {
      listItem: {
        id: "li",
        link: routerUrls.docsPage.route,
        text: "3. Documentation",
        heading: true,
        dropDownButton: true,
        list: [
          {
            listItem: {
              id: "li",
              link: routerUrls.docsPage.route,
              text: "Application Docs",
              onlyForFulltime: true,
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.aorsPage.route,
              text: "AORs",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.otherDocsPage.route,
              text: "Other",
            },
          },
        ],
      },
    },
    {
      listItem: {
        id: "li",
        link: routerUrls.slackPage.route,
        text: "4. Tools",
        dropDownButton: true,
        heading: true,
        list: [
          {
            listItem: {
              id: "li",
              link: routerUrls.slackPage.route,
              text: "Slack",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.trelloPage.route,
              text: "Trello",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.gecoAccountPage.route,
              text: "Geco",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.tgPage.route,
              text: "Teamgeist",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.confluencePage.route,
              text: "Confluence",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.githubPage.route,
              text: "Github",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.seeVeePage.route,
              text: "SeeVee",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.tamTamyPage.route,
              text: "TamTamy",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.gDrivePage.route,
              text: "G-Drive",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.miroPage.route,
              text: "Miro",
            },
          },
        ],
      },
    },
    {
      listItem: {
        id: "li",
        link: routerUrls.personalDevelopmentDaysPage.route,
        text: "5. Benefits",
        dropDownButton: true,
        heading: true,
        list: [
          {
            listItem: {
              id: "li",
              link: routerUrls.personalDevelopmentDaysPage.route,
              text: "Personal Development Days",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.allianzPage.route,
              text: "Allianz",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.medicalCheckPage.route,
              text: "Sv. Rok",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.apSystemsPage.route,
              text: "AP System",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.languageCoursePage.route,
              text: "Language Course",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.eventsPage.route,
              text: "Events",
            },
          },
        ],
      },
    },
  ],
};

export const adminNavigationInfo = {
  list: [
    {
      listItem: {
        id: "li",
        text: "1. Welcome",
        dropDownButton: true,
        heading: true,
        list: [
          {
            listItem: {
              id: "li",
              link: routerUrls.introPage.route,
              text: "Intro",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.pocsPage.route,
              text: "Your POCs",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.interviewQuestionsPage.route,
              text: "Profile Setup",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.personalDataPage.route,
              text: "Personal Data",
            },
          },
        ],
      },
    },
    {
      listItem: {
        id: "li",
        link: routerUrls.hardwareInfoPage.route,
        text: "2. Hardware",
        heading: true,
      },
    },
    {
      listItem: {
        id: "li",
        link: routerUrls.docsPage.route,
        text: "3. Documentation",
        heading: true,
        dropDownButton: true,
        list: [
          {
            listItem: {
              id: "li",
              link: routerUrls.docsPage.route,
              text: "Application Docs",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.aorsPage.route,
              text: "AORs",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.otherDocsPage.route,
              text: "Other",
            },
          },
        ],
      },
    },
    {
      listItem: {
        id: "li",
        link: routerUrls.slackPage.route,
        text: "4. Tools",
        dropDownButton: true,
        heading: true,
        list: [
          {
            listItem: {
              id: "li",
              link: routerUrls.slackPage.route,
              text: "Slack",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.trelloPage.route,
              text: "Trello",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.gecoAccountPage.route,
              text: "Geco",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.tgPage.route,
              text: "Teamgeist",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.confluencePage.route,
              text: "Confluence",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.githubPage.route,
              text: "Github",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.seeVeePage.route,
              text: "SeeVee",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.tamTamyPage.route,
              text: "TamTamy",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.gDrivePage.route,
              text: "G-Drive",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.miroPage.route,
              text: "Miro",
            },
          },
        ],
      },
    },
    {
      listItem: {
        id: "li",
        link: routerUrls.personalDevelopmentDaysPage.route,
        text: "5. Benefits",
        dropDownButton: true,
        heading: true,
        list: [
          {
            listItem: {
              id: "li",
              link: routerUrls.personalDevelopmentDaysPage.route,
              text: "Personal Development Days",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.allianzPage.route,
              text: "Allianz",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.medicalCheckPage.route,
              text: "Medical Check",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.apSystemsPage.route,
              text: "AP System",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.languageCoursePage.route,
              text: "Language Course",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.eventsPage.route,
              text: "Events",
            },
          },
        ],
      },
    },
    {
      listItem: {
        id: "li",
        text: "Admin Page Setup",
        dropDownButton: true,
        heading: true,
        list: [
          {
            listItem: {
              id: "li",
              link: routerUrls.adminUsersPage.route,
              text: "Users",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.editInterviewQuestionsPage.route,
              text: "Interview Questions",
            },
          },
          {
            listItem: {
              id: "li",
              link: routerUrls.editFormFieldsPage.route,
              text: "Personal Data Fields",
            },
          },
        ],
      },
    },
  ],
};
