import React, { useEffect, useRef, useState } from "react";
import { styles } from "../../constants/Constants";
import { ContentContainerData } from "../content-container/ContentContainerData";
import BlueCheckbox from "../../components/checkbox/BlueCheckbox";
import { CheckboxContainer } from "../checkbox-container/CheckboxContainer";
import putDataToEndpoint from "../../utils/putDataToEndPoint";
import { urls } from "../../utils/baseUrl";
import ErrorMessage from "../../components/error-message/ErrorMessage";
import copy_icon from "../../assests/images/copy_documents_icon.png";
import { BasicRoundButton } from "../../components/buttons/BasicRoundButton";
import { CopyButtonContainer } from "../copy-button-container/CopyButtonContainer";
import { PopUpMessageContainer } from "../copy-button-container/PopUpMessageContainer";
import { useTranslation } from "react-i18next";

const CheckBoxElements = {
  AVATAR: 1,
  GITHUB: 2,
  CELLPHONETARIFF: 3,
};

const EmployeeData = ({ userId, inputData }) => {
  const { githubAccountLink, avatarLink, cellPhoneTariff } = inputData;
  const [githubAccountLinkChecked, setGithubAccountLinkChecked] = useState(
    inputData.githubAccountLinkChecked
  );
  const [avatarLinkChecked, setAvatarLinkChecked] = useState(
    inputData.avatarLinkChecked
  );
  const [cellPhoneTariffChecked, setCellPhoneTariffChecked] = useState(
    inputData.cellPhoneTariffChecked
  );
  const [avatarPopUp, setAvatarPopUp] = useState(false);
  const [githubPopUp, setGithubPopUp] = useState(false);
  const [error, setError] = useState(null);
  const firstRender = useRef(true);
  const { t } = useTranslation();

  useEffect(() => {
    const updateUserAccountInfo = async () => {
      try {
        await putDataToEndpoint(
          `${urls.userAccountInfoUrl.replace(":userId", userId)}`,
          {
            githubAccountLinkChecked,
            avatarLinkChecked,
            cellPhoneTariffChecked,
          }
        );
      } catch (err) {
        setError(err);
      }
    };

    if (firstRender.current) {
      firstRender.current = false;
    } else {
      updateUserAccountInfo();
    }
  }, [
    userId,
    githubAccountLinkChecked,
    avatarLinkChecked,
    cellPhoneTariffChecked,
  ]);

  const handleCheckBoxAdmin = (id) => {
    if (id === CheckBoxElements.AVATAR) {
      inputData.avatarLinkChecked = !avatarLinkChecked;
      setAvatarLinkChecked(!avatarLinkChecked);
    } else if (id === CheckBoxElements.GITHUB) {
      inputData.githubAccountLinkChecked = !githubAccountLinkChecked;
      setGithubAccountLinkChecked(!githubAccountLinkChecked);
    } else if (id === CheckBoxElements.CELLPHONETARIFF) {
      inputData.cellPhoneTariffChecked = !cellPhoneTariffChecked;
      setCellPhoneTariffChecked(!cellPhoneTariffChecked);
    }
  };

  /**
   * @function
   * @description Copies checkbox data to clipboard and toggles a pop up window as a message that data has been copied
   * @param  {any} {copiedText} Copied text data that is represented in the checkbox next to the copy button
   * @param  {any} {popUp} Id of the copy button that was clicked
   *
   */

  const copyToClipboard = (copiedText, popUpId) => {
    navigator.clipboard.writeText(copiedText);
    togglePopUpWindow(popUpId);
  };

  const togglePopUpWindow = (popUpId) => {
    if (popUpId === CheckBoxElements.AVATAR) {
      setAvatarPopUp(!avatarPopUp);
      setTimeout(() => {
        setAvatarPopUp(false);
      }, 2000);
    } else if (popUpId === CheckBoxElements.GITHUB) {
      setGithubPopUp(!githubPopUp);
      setTimeout(() => {
        setGithubPopUp(false);
      }, 2000);
    }
  };

  return (
    <div>
      {!!error ? (
        <ErrorMessage fullscreen={false} />
      ) : (
        <div>
          {avatarLink ? (
            <CheckboxContainer>
              <BlueCheckbox
                id={CheckBoxElements.AVATAR}
                checkedState={avatarLinkChecked}
                handleClick={handleCheckBoxAdmin}
              />
              <ContentContainerData
                checkedState={avatarLinkChecked}
                borderColor={styles.container.color.lightBlue}
                onClick={() => handleCheckBoxAdmin(CheckBoxElements.AVATAR)}
              >
                <p>{t("employee-data.avatar-link")}</p>
                <p>
                  <b>{avatarLink}</b>
                </p>
              </ContentContainerData>
              <CopyButtonContainer>
                <BasicRoundButton
                  backgroundColor={"transparent"}
                  onClick={() =>
                    copyToClipboard(avatarLink, CheckBoxElements.AVATAR)
                  }
                >
                  <img src={copy_icon} alt="" />
                </BasicRoundButton>
                <PopUpMessageContainer
                  visibile={avatarPopUp}
                  borderColor={styles.button.border.color.darkerBlue}
                >
                  <p>
                    <b>{t("employee-data.text-copied")}</b>
                  </p>
                </PopUpMessageContainer>
              </CopyButtonContainer>
            </CheckboxContainer>
          ) : null}
          {githubAccountLink ? (
            <CheckboxContainer>
              <BlueCheckbox
                id={CheckBoxElements.GITHUB}
                checkedState={githubAccountLinkChecked}
                handleClick={handleCheckBoxAdmin}
              />
              <ContentContainerData
                checkedState={githubAccountLinkChecked}
                borderColor={styles.container.color.lightBlue}
                onClick={() => handleCheckBoxAdmin(CheckBoxElements.GITHUB)}
              >
                <p>{t("employee-data.github")}</p>
                <p>
                  <b>{githubAccountLink}</b>
                </p>
              </ContentContainerData>
              <CopyButtonContainer>
                <BasicRoundButton
                  backgroundColor={"transparent"}
                  onClick={() =>
                    copyToClipboard(githubAccountLink, CheckBoxElements.GITHUB)
                  }
                >
                  <img src={copy_icon} alt="" />
                </BasicRoundButton>
                <PopUpMessageContainer
                  visibile={githubPopUp}
                  borderColor={styles.button.border.color.darkerBlue}
                >
                  <p>
                    <b>{t("employee-data.text-copied")}</b>
                  </p>
                </PopUpMessageContainer>
              </CopyButtonContainer>
            </CheckboxContainer>
          ) : null}
          {cellPhoneTariff ? (
            <CheckboxContainer>
              <BlueCheckbox
                id={CheckBoxElements.CELLPHONETARIFF}
                checkedState={cellPhoneTariffChecked}
                handleClick={handleCheckBoxAdmin}
              />
              <ContentContainerData
                checkedState={cellPhoneTariffChecked}
                borderColor={styles.container.color.lightBlue}
                onClick={() =>
                  handleCheckBoxAdmin(CheckBoxElements.CELLPHONETARIFF)
                }
              >
                <p>{t("employee-data.cellphone")}</p>
                <p>
                  <b>{cellPhoneTariff}</b>
                </p>
              </ContentContainerData>
            </CheckboxContainer>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default EmployeeData;
