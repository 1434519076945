import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BaseLayout } from "../../components";
import { StyledUploadButton } from "../../components/buttons/StyledUploadButton.js";
import ModalWindow from "../../components/modal-window/ModalWindow";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper";
import TextContainer from "../../components/text-editor/TextContainer.js";
import { FileTypes } from "../../constants/FileTypes.js";
import { urls } from "../../utils/baseUrl";
import deleteDataFromEndpoint from "../../utils/deleteDataFromEndpoint";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint";
import FileUploader from "./FileUploader";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders Application documentation page
 * @param  {string} to path to next page
 * @param  {string} from path to previous page
 * @param  {Array} data checkboxInfo data for the current page
 * @param  {Object} stepperData stepper pages data; consist of lists of page titles and page routes
 * @param  {function} toggleAlert toggle alert component with message
 * @param  {function} updateCheckboxInfoData send updated CheckboxInfo data to BE
 */

const ApplicationDocsPage = ({
  to,
  from,
  data,
  stepperData,
  toggleAlert,
  updateCheckboxInfoData,
}) => {
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [documentToDelete, setDocumentToDelete] = useState({});
  const [deleteModalIsOpen, setDeleteModalToOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDeleteModal = (document) => {
    document ? setDocumentToDelete(document) : setDocumentToDelete({});
    setDeleteModalToOpen(!deleteModalIsOpen);
  };

  useEffect(() => {
    getUploadedDocuments();
  }, []);

  const getUploadedDocuments = async () => {
    const { data, errFlag } = await getDataFromEndpoint(
      urls.documentsUrl.replace(":fileType", FileTypes.APPLICATION_DOCUMENT)
    );
    if (!errFlag) {
      setUploadedDocuments(data);
    } else {
      throw new Error(t("application-docs-page.error-fetch"));
    }
  };

  const deleteDocument = () => {
    const { errFlag } = deleteDataFromEndpoint(
      urls.deleteDocumentUrl
        .replace(":fileType", documentToDelete.fileType)
        .replace(":fileName", documentToDelete.filename),
      {}
    );
    if (!errFlag) {
      setUploadedDocuments(
        uploadedDocuments.filter(
          (doc) => doc.filename !== documentToDelete.filename
        )
      );
      toggleAlert(
        t("application-docs-page.delete-success", { documentToDelete })
      );
    } else {
      toggleAlert(t("application-docs-page.delete-reject"), "error");
    }
  };

  const handleDelete = () => {
    deleteDocument();
    toggleDeleteModal();
  };

  return (
    <BaseLayout from={from} to={to} hasTextEditor={true}>
      <HorizontalLinearStepper
        stepTitles={stepperData.titles}
        stepRoutes={stepperData.routes}
        currentStep={data[0].orderOnStepper - 1}
      />
      <TextContainer
        data={data}
        updateCheckboxInfoData={updateCheckboxInfoData}
        onlyFullTimeView={true}
      />
      <StyledUploadButton labelFontSize={"1.0em"}>
        <FileUploader
          toggleAlert={toggleAlert}
          url={urls.uploadDocumentUrl}
          refetchDocuments={getUploadedDocuments}
          label={t("application-docs-page.label")}
          fileType={FileTypes.APPLICATION_DOCUMENT}
        />
      </StyledUploadButton>
      <span style={{ color: "#57ADDD", marginLeft: "1em", marginTop: "1em" }}>
        Uploaded:&nbsp;
        {uploadedDocuments.map((doc, i) => {
          return (
            <button
              onClick={() => toggleDeleteModal(doc)}
              style={{
                background: "transparent",
                color: "#57ADDD",
                borderColor: "transparent",
              }}
              title="Delete this document"
            >
              {doc.filename} &times;
              {uploadedDocuments.length > i + 1 ? "," : null}
            </button>
          );
        })}
      </span>

      <ModalWindow
        headerText={
          <h4>
            <strong>{t("application-docs-page.question")}</strong>
          </h4>
        }
        isOpen={deleteModalIsOpen}
        hideModal={toggleDeleteModal}
        toDoIfConfirmed={() => handleDelete()}
        body={
          <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {documentToDelete.name || documentToDelete.filename}
          </div>
        }
        cancelText={t("application-docs-page.cancel")}
        confirmText={t("application-docs-page.delete")}
      />
    </BaseLayout>
  );
};

ApplicationDocsPage.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  data: PropTypes.array.isRequired,
  stepperData: PropTypes.object.isRequired,
  toggleAlert: PropTypes.func,
  updateCheckboxInfoData: PropTypes.func.isRequired,
  avatarPicture: PropTypes.string,
};

export default ApplicationDocsPage;
