import styled from "styled-components";

export const BlueCheckboxWrapper = styled.div`
  input {
    appearance: none;
    margin-top: 4vh;
    padding: 2rem 3rem;
    cursor: pointer;
  }
  input::before {
    content: "";
    margin: 0;
    display: block;
    width: 30px;
    padding: 10%;
    height: 25px;
    border-radius: 40%;
    background-color: #fff;
    border: 3px solid rgb(11, 158, 206);
  }
  input:checked:before {
    background-color: rgb(11, 158, 206);
  }
`;
