import styled from "styled-components";

export const TableContainer = styled.div`
  background-color: rgba(161, 221, 217, 0.62);
  border: 3px solid rgb(36, 133, 156, 0.2);
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: left;
  width: 100%;
  padding: 5px 0px;
  margin: 0 auto;

  textarea {
    background: transparent;
    border-radius: 10px;
    max-height: 3em;
    border: 2px solid rgb(36, 133, 156, 0.3);
    background-color: rgba(161, 221, 217, 0.62);
    font-size: 0.8em;
  }

  & div {
    display: grid;
    max-height: 500px;
    overflow-y: scroll;
    scrollbar-color: white transparent;
    scrollbar-shadow-color: none;
  }
  & div::-webkit-scrollbar {
    background: transparent;
  }
  & div::-webkit-scrollbar-thumb {
    width: thin;
    background-color: white;
    border-radius: 6px;
    border: none;
  }
  tr,
  td {
    padding: 15px;
    font-size: 0.9em;
  }
  th {
    font-size: 1em;
    padding: 15px;
  }
  select {
    outline: none;
    box-sizing: border-box;
    display: block;
    text-align: center;
    padding: 5px 10px;
    border: 2px solid rgb(36, 133, 156, 0.3);
    background-color: rgba(161, 221, 217, 0.62);
    border-radius: 10px;
    :active,
    :focus-within {
      border-radius: 10px;
    }
    :hover {
      outline: none;
    }
  }
  select option {
    display: block;
    text-align: center;
    padding: 5px 10px;
    border: 0;
    background-color: rgba(161, 221, 217, 0.62);
    border-radius: 10px;
  }
`;
