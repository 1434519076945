import { StyledTextContainer } from "../text-container/StyledTextContainer.js";
import { Dropdown } from "react-bootstrap";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import React from "react";
import { StyledRoundButton } from "../../components/buttons/StyledRoundButton.js";
import { styles } from "../../constants/Constants.js";
import delete_icon from "../../assests/images/delete_icon.png";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders editable Interview Question along with delete button
 * @param  {Object} question interview question
 * @param  {number} index question index
 * @param  {boolean} editMode is question editable or not
 * @param  {function} handleQuestionChange handles question text editing
 * @param  {function} handleActivation handles setting question to active or inactive state
 * @param  {function} handleDeleteClick handles question deletion
 */
const QuestionContainer = ({
  question,
  index,
  editMode,
  handleQuestionChange,
  handleActivation,
  handleDeleteClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledTextContainer
        readOnly={!editMode}
        margin={"0.5em 2em 0.5em 0.5em"}
      >
        <div style={{ display: "flex" }}>
          <label>
            {t("question-container.question")} {++index}
          </label>
          <Dropdown>
            <Dropdown.Toggle
              style={{
                color: "rgb(0,0,0)",
                backgroundColor: "#f6f6f6",
                border: "0px",
              }}
            >
              {question?.active ? (
                <AiOutlineCheckCircle style={{ color: "green" }} />
              ) : (
                <AiOutlineCloseCircle style={{ color: "red" }} />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleActivation(question)}>
                {question?.active
                  ? t("question-container.inactive")
                  : t("question-container.active")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <textarea
          key={index}
          readOnly={!editMode}
          onChange={(e) => handleQuestionChange(e.target.value, index - 1)}
          defaultValue={question.text}
        ></textarea>
      </StyledTextContainer>
      <StyledRoundButton
        borderWidth={"2px"}
        backgroundColor={styles.button.color.lightBlue}
        borderColor={"rgb(36,133,156, 0.3)"}
        onClick={() => handleDeleteClick(question)}
      >
        <img src={delete_icon} alt="" />
      </StyledRoundButton>
    </>
  );
};

QuestionContainer.prototype = {
  question: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  editMode: PropTypes.bool.isRequired,
  handlesQuestionChange: PropTypes.func.isRequired,
  handleActivation: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
};

export default QuestionContainer;
