import { StyledTextContainer } from "../text-container/StyledTextContainer.js";
import { Dropdown } from "react-bootstrap";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { StyledRoundButton } from "../../components/buttons/StyledRoundButton.js";
import { styles } from "../../constants/Constants.js";
import delete_icon from "../../assests/images/delete_icon.png";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders editable Form fields along with delete button
 * @param  {Object} field form field
 * @param  {number} index form field index
 * @param  {boolean} editMode is field editable or not
 * @param  {function} handleFieldLabelChange handles field label text editing
 * @param  {function} handleFieldHintChange handles field hint text editing
 * @param  {function} handleDeleteClick handles form field deletion
 * @param  {string} fieldTypes list of all existing field types
 * @param  {function} handleFieldTypeChange handles changing field type
 */
const FormFieldsContainer = ({
  field,
  index,
  editMode,
  handleFieldLabelChange,
  handleFieldHintChange,
  handleDeleteClick,
  fieldTypes,
  handleFieldTypeChange,
}) => {
  const [changedFiledType, setChangedFieldType] = useState(
    fieldTypes[field.fieldType]
  );
  const handleChangingFieldTypeInContainer = (value, i) => {
    handleFieldTypeChange(value, index);
    setChangedFieldType(value);
  };
  const { t } = useTranslation();

  return (
    <>
      <StyledTextContainer
        readOnly={!editMode}
        margin={"0.5em 2em 0.5em 0.5em"}
      >
        <div style={{ display: "flex" }}>
          <label>Field {++index}</label>
          <p style={{ fontSize: "13px" }}>Choose answer's format:</p>
          <Dropdown>
            <Dropdown.Toggle
              style={{
                color: "rgb(0,0,0)",
                backgroundColor: "#f6f6f6",
                border: "0px",
              }}
            ></Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.values(fieldTypes).map((value, i) => (
                <Dropdown.Item
                  onClick={() =>
                    handleChangingFieldTypeInContainer(value, index)
                  }
                  disabled={!editMode}
                  key={i}
                >
                  <div>{value}</div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div key={index}>
          <textarea
            readOnly={!editMode}
            onChange={(e) => handleFieldLabelChange(e.target.value, index)}
            defaultValue={field.label}
            placeholder={t("form-field-container.label")}
            style={{ height: "50px" }}
          ></textarea>
          <textarea
            readOnly={!editMode}
            onChange={(e) => handleFieldHintChange(e.target.value, index)}
            defaultValue={field.hint}
            placeholder={t("form-field-container.hint")}
            style={{ height: "50px" }}
          ></textarea>
          <div style={{ fontSize: "13px" }}>
            {t("form-field-container.answer")} {changedFiledType}
          </div>
        </div>
      </StyledTextContainer>
      <StyledRoundButton
        borderWidth={"2px"}
        backgroundColor={styles.button.color.lightBlue}
        borderColor={"rgb(36,133,156, 0.3)"}
        onClick={() => handleDeleteClick(field)}
      >
        <img src={delete_icon} alt="" />
      </StyledRoundButton>
    </>
  );
};

FormFieldsContainer.prototype = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  editMode: PropTypes.bool.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
};

export default FormFieldsContainer;
