import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BaseLayout } from "../../components";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper";
import TextContainer from "../../components/text-editor/TextContainer.js";
import { UserTypes } from "../../constants/UserTypes";
import { urls } from "../../utils/baseUrl.js";
import { UserContext } from "../../utils/context/UserContext";
import getDataFromEndpoint from "../../utils/getDataFromEndpoint.js";
import putDataToEndpoint from "../../utils/putDataToEndPoint.js";
import { StyledButtonsContainer } from "../buttons-container/StyledButtonsContainer";
import { StyledTextContainer } from "../text-container/StyledTextContainer";

/**
 * @component
 * @description Component renders Hardware-info Page
 * @param  {string} to path to next page
 * @param  {string} from path to previous page
 * @param  {Array} data CheckboxInfo data for the current page
 * @param  {Object} stepperData stepper pages data; consist of lists of page titles and page routes
 * @param  {function} updateCheckboxInfoData send updated checkboxInfo data to BE
 * @param {function} toggleAlert toggles alert message
 */
const HardwareInfoPage = ({
  to,
  from,
  data,
  stepperData,
  updateCheckboxInfoData,
  toggleAlert,
}) => {
  const userData = useContext(UserContext);
  const { t } = useTranslation();

  const [chosenUserType, setChosenUserType] = useState(
    userData.userType === UserTypes.ADMIN
      ? UserTypes.FULLTIME
      : userData.userType,
  );
  const [userAccountInfo, setUserAccountInfo] = useState(null);

  const hardwareChoicesForFulltime = {
    laptopChoice: t("hardware-info.laptop"),
    cellphoneChoice: t("hardware-info.cellphone"),
    headphonesChoice: t("hardware-info.headphones"),
    keyboardAndMouseChoice: t("hardware-info.keyboard"),
  };

  const getAccountInfoData = useCallback(
    async (userId) => {
      try {
        const { data, errFlag } = await getDataFromEndpoint(
          urls.userAccountInfoUrl.replace(":userId", userId),
        );
        if (!errFlag) {
          setUserAccountInfo(data);
        }
      } catch (error) {
        toggleAlert(t("hardware-info.error-fetch"), "error");
      }
    },
    [toggleAlert],
  );

  useEffect(() => {
    getAccountInfoData(userData.id);
  }, [userData, getAccountInfoData]);

  const handleAccountInfoChange = (hardwareChoice, value) => {
    const updatedAccountInfo = userAccountInfo;
    updatedAccountInfo[hardwareChoice] = value;
    setUserAccountInfo(updatedAccountInfo);
  };

  const updateAccountInfoData = async (userId) => {
    try {
      const { errFlag } = await putDataToEndpoint(
        urls.userAccountInfoUrl.replace(":userId", userId),
        JSON.stringify({
          cellphoneChoice: userAccountInfo["cellphoneChoice"],
          headphonesChoice: userAccountInfo["headphonesChoice"],
          keyboardAndMouseChoice: userAccountInfo["keyboardAndMouseChoice"],
        }),
      );
      if (!errFlag) {
        toggleAlert(t("hardware-info.success-update"));
      }
    } catch (error) {
      toggleAlert(t("hardware-info.error-update"), "error");
    }
  };

  const updateLaptop = async (userId) => {
    try {
      const { errFlag } = await putDataToEndpoint(
        urls.userAccountInfoUrl.replace(":userId", userId),
        JSON.stringify({ laptopChoice: userAccountInfo["laptopChoice"] }),
      );
      if (!errFlag) {
        toggleAlert("Account info updated successfully.");
      }
    } catch (error) {
      toggleAlert("Error updating account info ", "error");
    }
  };

  const validate = (key) => {
    if (userAccountInfo[key] && userAccountInfo[key].length) {
      return true;
    }

    return false;
  };

  const validateLaptop = () => {
    if (userAccountInfo["laptopChoice"].length) {
      return true;
    }

    return false;
  };

  const fetchChosenUserType = (userType) => {
    setChosenUserType(userType);
  };

  function isValid(key) {
    return (key !== "laptopChoice" && validate(key)) || key === "laptopChoice";
  }

  const handleSave = (choice) => {
    if (choice === "laptopChoice") {
      if (validateLaptop()) {
        updateLaptop(userData.id);
      } else {
        toggleAlert("Invalid laptop choice", "error");
      }
    } else {
      updateAccountInfoData(userData.id);
    }
  };

  return (
    <BaseLayout
      from={from}
      to={to}
      hasTextEditor={true}
      onBack={handleSave}
      onNext={handleSave}
    >
      <HorizontalLinearStepper
        stepRoutes={stepperData.routes}
        stepTitles={stepperData.titles}
      />
      <TextContainer
        data={data}
        updateCheckboxInfoData={updateCheckboxInfoData}
        fetchChosenUserType={fetchChosenUserType}
      >
        {chosenUserType === UserTypes.FULLTIME
          ? Object.keys(hardwareChoicesForFulltime).map((choice, index) => {
              return (
                <React.Fragment key={index}>
                  <StyledTextContainer
                    labelFontSize={"1.1em"}
                    textFontSize={"0.9em"}
                    padding={"1em 1em 0em"}
                  >
                    <label>{hardwareChoicesForFulltime[choice]}</label>
                    <textarea
                      placeholder={t("hardware-info.choice")}
                      maxLength={150}
                      onChange={(e) =>
                        handleAccountInfoChange(choice, e.target.value)
                      }
                      defaultValue={userAccountInfo?.[choice]}
                    />
                  </StyledTextContainer>
                  {choice === "laptopChoice" ? (
                    <StyledButtonsContainer>
                      <Button
                        variant={"outline-info"}
                        onClick={(e) => handleSave("laptopChoice")}
                      >
                        Save
                      </Button>
                    </StyledButtonsContainer>
                  ) : null}
                  {userAccountInfo?.[choice]?.length > 0 ? (
                    <ul style={{ color: "#57ADDD" }}>
                      <li>{userAccountInfo?.[choice]}</li>
                    </ul>
                  ) : null}
                </React.Fragment>
              );
            })
          : null}
      </TextContainer>
      <StyledButtonsContainer>
        <Button variant={"outline-info"} onClick={(e) => handleSave(e)}>
          Save
        </Button>
      </StyledButtonsContainer>
      <br />
      <br />
    </BaseLayout>
  );
};

HardwareInfoPage.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string,
  data: PropTypes.array.isRequired,
  updateCheckboxInfoData: PropTypes.func.isRequired,
  stepperData: PropTypes.object.isRequired,
  toggleAlert: PropTypes.func,
};
export default HardwareInfoPage;
