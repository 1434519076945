import styled from "styled-components";
import checkbox from "../../assests/images/check_solid.svg";
import { breakpoints } from "../../utils/cssBreakpoints";
export const CheckBoxWrapper = styled.div`
  grid-column: span 10;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
  justify-items: center;
  align-content: start;
  input {
    appearance: none;
    margin: 0;
    margin-top: 5px;
    cursor: pointer;
    align-self: start;
  }
  input::before {
    content: "";
    margin: 0;
    display: block;
    width: 50px;
    padding: 10%;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid rgb(11, 158, 206);
  }
  input:checked:before {
    content: url(${checkbox});
    margin: 0;
    fill: blueviolet;
    display: block;
    width: 50px;
    padding: 10%;
    height: 50px;
    color: rgb(11, 158, 206);
    border-radius: 50%;
    border: 1px solid rgb(11, 158, 206);
    background-color: #fff;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    input {
      transform: scale(0.9);
    }
    input::before {
      transform: scale(0.9);
    }
  }
`;
