import PropTypes from "prop-types";
import React from "react";
import { BaseLayout } from "../../components";
import { FullNameLabel } from "../../components/label/FullNameLabel";
import MilestoneList from "../../components/milestone/MilestoneList";
import { styles } from "../../constants/Constants";
import { MilestoneContainer } from "../milestone-container/MilestoneContainer";
import { useTranslation } from "react-i18next";

/**
 * @Component Renders admin milestones page
 * @param  {function} deleteMilestone handler for deleting milestone
 * @param  {Array} milestones milestones for all users
 */

const AdminMilestones = ({ deleteMilestone, milestones }) => {
  const { t } = useTranslation();

  return (
    <BaseLayout hasTextEditor={true}>
      <div>
        <FullNameLabel whiteColor={true} font={"28px"}>
          {t("admin-milestones-page.message")}
        </FullNameLabel>
        <MilestoneContainer
          backgroundColor={styles.container.backgroundColor.cloudBlue}
        >
          <MilestoneList
            milestones={milestones}
            deleteMilestone={deleteMilestone}
          />
        </MilestoneContainer>
      </div>
    </BaseLayout>
  );
};

AdminMilestones.propTypes = {
  milestones: PropTypes.array.isRequired,
  deleteMilestone: PropTypes.func.isRequired,
};

export default AdminMilestones;
