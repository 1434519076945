import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd-cjs";
import PropTypes from "prop-types";

/**
 * @component
 * @description Component renders Drag and Drop Item for Drag and Drop Canvas
 * @param  {number} index index of the DragAndDrop Item
 * @param  {function} moveListItem callback that replaces dragged and hovered items
 * @param  {element} children component that will be wrapped as Drag and Drop Item
 */
export const DragAndDropItem = ({ index, moveListItem, children }) => {
  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    item: { index, type: "item" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [spec, dropRef] = useDrop({
    accept: "item",
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      moveListItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  // Make items being dragged transparent, so it's easier to see where we drop them
  const opacity = isDragging ? 0 : 1;
  return (
    <div
      ref={dragDropRef}
      style={{
        opacity,
        display: "grid",
        gridTemplateColumns: " 10fr 1fr",
        padding: "0.5em",
        borderRadius: "30px",
      }}
    >
      {children}
    </div>
  );
};

DragAndDropItem.propTypes = {
  index: PropTypes.number.isRequired,
  moveListItem: PropTypes.func.isRequired,
  children: PropTypes.element,
};

export default DragAndDropItem;
