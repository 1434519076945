export const FileTypes = {
  APPLICATION_DOCUMENT: "APPLICATION_DOCUMENT",
  AOR_DOCUMENT: "AOR_DOCUMENT",
  OTHER_DOCUMENT: "OTHER_DOCUMENT",
  SLACK_PICTURE: "SLACK_PICTURE",
  AVATAR_PICTURE: "AVATAR_PICTURE",
  SERIOUS_PICTURE: "SERIOUS_PICTURE",
  DOCUMENT_TEMPLATE: "DOCUMENT_TEMPLATE",
  GECO_INSTRUCTIONS: "GECO_INSTRUCTIONS",
  TG_INSTRUCTIONS: "TG_INSTRUCTIONS",
};
