const introPage = {
  route: "/onboarding",
  value: 1,
  stepperPages: [1, 27, 15, 13],
};

const userProfilePage = { route: "/onboarding/profile/:userId", value: 26 };
const pocsPage = {
  route: "/onboarding/pocs",
  value: 27,
  stepperPages: [1, 27, 15, 13],
};

const personalDataPage = {
  route: "/onboarding/personal-data",
  value: 13,
  stepperPages: [1, 27, 15, 13],
};

const docsPage = {
  route: "/onboarding/application-docs",
  value: 16,
  stepperPages: [16, 28, 29],
};
const aorsPage = {
  route: "/onboarding/aors",
  value: 28,
  stepperPages: [16, 28, 29],
};
const otherDocsPage = {
  route: "/onboarding/other-docs",
  value: 29,
  stepperPages: [16, 28, 29],
};

const gDrivePage = {
  route: "/onboarding/g-drive",
  value: 12,
  stepperPages: [5, 18, 3, 4, 7, 6, 10, 11, 12, 14],
};

const miroPage = {
  route: "/onboarding/miro",
  value: 14,
  stepperPages: [5, 18, 3, 4, 7, 6, 10, 11, 12, 14],
};

const tamTamyPage = {
  route: "/onboarding/tamtamy",
  value: 11,
  stepperPages: [5, 18, 3, 4, 7, 6, 10, 11, 12, 14],
};

const seeVeePage = {
  route: "/onboarding/seevee",
  value: 10,
  stepperPages: [5, 18, 3, 4, 7, 6, 10, 11, 12, 14],
};

const gecoAccountPage = {
  route: "/onboarding/geco",
  value: 3,
  stepperPages: [5, 18, 3, 4, 7, 6, 10, 11, 12, 14],
};
const tgPage = {
  route: "/onboarding/tg",
  value: 4,
  stepperPages: [5, 18, 3, 4, 7, 6, 10, 11, 12, 14],
};
const trelloPage = {
  route: "/onboarding/trello",
  value: 18,
  stepperPages: [5, 18, 3, 4, 7, 6, 10, 11, 12, 14],
};
const slackPage = {
  route: "/onboarding/slack",
  value: 5,
  stepperPages: [5, 18, 3, 4, 7, 6, 10, 11, 12, 14],
};

const githubPage = {
  route: "/onboarding/github",
  value: 6,
  stepperPages: [5, 18, 3, 4, 7, 6, 10, 11, 12, 14],
};

const confluencePage = {
  route: "/onboarding/confluence",
  value: 7,
  stepperPages: [5, 18, 3, 4, 7, 6, 10, 11, 12, 14],
};

//up
const eventsPage = {
  route: "/onboarding/events",
  value: 8,
  stepperPages: [20, 21, 22, 23, 24, 8],
};
const hardwareInfoPage = { route: "/onboarding/hardwareinfo", value: 9 };

const personalDevelopmentDaysPage = {
  route: "/onboarding/personal-development-days",
  value: 20,
  stepperPages: [20, 21, 22, 23, 24, 8],
};
const allianzPage = {
  route: "/onboarding/allianz",
  value: 21,
  stepperPages: [20, 21, 22, 23, 24, 8],
};
const medicalCheckPage = {
  route: "/onboarding/medical-check",
  value: 22,
  stepperPages: [20, 21, 22, 23, 24, 8],
};
const apSystemsPage = {
  route: "/onboarding/ap-systems",
  value: 23,
  stepperPages: [20, 21, 22, 23, 24, 8],
};
const languageCoursePage = {
  route: "/onboarding/language-course",
  value: 24,
  stepperPages: [20, 21, 22, 23, 24, 8],
};

//straight
const interviewQuestionsPage = {
  route: "/onboarding/interview-questions",
  value: 15,
  stepperPages: [1, 27, 15, 13],
};

const checkboxesCheckedPage = { route: "/onboarding/final" };
const adminPage = { route: "/onboarding/admin" };
const adminUsersPage = { route: "/onboarding/admin/users" };
const adminEmployeePage = { route: "/onboarding/admin/users/:userId" };
const editInterviewQuestionsPage = {
  route: "/onboarding/admin/edit-interview-questions",
};

const editFormFieldsPage = {
  route: "/onboarding/admin/edit-form-fields",
};

const userProfilePageAdminView = {
  route: "/onboarding/admin/profile/:userId",
  value: 26,
};

export const onboardingurls = [
  introPage.route,
  userProfilePage.route,
  pocsPage.route,

  docsPage.route,
  aorsPage.route,
  otherDocsPage.route,

  gecoAccountPage.route,
  tgPage.route,
  trelloPage.route,
  slackPage.route,
  githubPage.route,
  confluencePage.route,
  seeVeePage.route,

  eventsPage.route,
  hardwareInfoPage.route,
  personalDevelopmentDaysPage.route,
  allianzPage.route,
  medicalCheckPage.route,
  apSystemsPage.route,
  languageCoursePage.route,

  interviewQuestionsPage.route,
  checkboxesCheckedPage.route,

  adminPage.route,
  adminUsersPage.route,
  adminEmployeePage.route,
  editInterviewQuestionsPage.route,
  editFormFieldsPage.route,
  userProfilePageAdminView.route,
  tamTamyPage.route,
  gDrivePage.route,
  personalDataPage.route,
  miroPage.route,
];

export default {
  introPage,
  userProfilePage,
  pocsPage,

  docsPage,
  aorsPage,
  otherDocsPage,

  gecoAccountPage,
  tgPage,
  trelloPage,
  slackPage,
  githubPage,
  confluencePage,

  eventsPage,
  hardwareInfoPage,
  personalDevelopmentDaysPage,
  allianzPage,
  medicalCheckPage,
  apSystemsPage,
  languageCoursePage,

  interviewQuestionsPage,
  checkboxesCheckedPage,

  adminPage,
  adminUsersPage,
  adminUsersProgressPage: adminEmployeePage,
  editInterviewQuestionsPage,
  editFormFieldsPage,
  userProfilePageAdminView,
  seeVeePage,
  tamTamyPage,
  gDrivePage,
  personalDataPage,
  miroPage,
};
