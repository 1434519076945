import React from "react";
import Hamburger from "../hamburger-menu/Hamburger";
import { StyledAdminHeader } from "./StyledAdminHeader";
import PropTypes from "prop-types";

/**
 * @component
 * @description Component renders AdminHeader with hamburger
 * @param  {function} handleHamburgerClick Hamburger click handler
 * @param usersName Name of current user whose progress is shown
 */
const AdminHeader = ({ handleHamburgerClick }) => (
  <StyledAdminHeader>
    <Hamburger handleClick={handleHamburgerClick} />
  </StyledAdminHeader>
);

AdminHeader.propTypes = {
  handleHamburgerClick: PropTypes.func.isRequired,
};

export default AdminHeader;
