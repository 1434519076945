import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Image } from "react-bootstrap";
import GecoPicture from "../../assests/images/geco_page.png";
import { BaseLayout } from "../../components";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper";
import TextContainer from "../../components/text-editor/TextContainer.js";
import { UserTypes } from "../../constants/UserTypes";
import { UserContext } from "../../utils/context/UserContext";

/**
 * @component
 * @description Component renders Geco page
 * @param  {string} to path to next page
 * @param  {string} from  path to previous page
 * @param  {Array} data checkboxInfo data for the current page
 * @param  {Object} stepperData stepper pages data; consist of lists of page titles and page routes
 * @param  {function} updateCheckboxInfoData send updated checkboxInfo data to BE
 * @param  {function} toggleAlert handler for showing alert message
 */
const GecoPage = ({ to, from, data, stepperData, updateCheckboxInfoData }) => {
  const userData = useContext(UserContext);

  const [chosenUserType, setChosenUserType] = useState(
    userData.userType === UserTypes.ADMIN
      ? UserTypes.FULLTIME
      : userData.userType
  );

  const fetchChosenUserType = (userType) => {
    setChosenUserType(userType);
  };

  return (
    <BaseLayout to={to} from={from} hasTextEditor={true}>
      <HorizontalLinearStepper
        stepTitles={stepperData.titles}
        stepRoutes={stepperData.routes}
        currentStep={data[0].orderOnStepper - 1}
      >
        <TextContainer
          data={data}
          updateCheckboxInfoData={updateCheckboxInfoData}
          fetchChosenUserType={fetchChosenUserType}
        >
          <React.Fragment>
            <Image
              src={GecoPicture}
              alt={"Geco page"}
              style={{
                border: "1px solid rgb(0, 0, 0, 0.2)",
                width: "90%",
                margin: "1em 2.5em 1.5em 2.5em",
              }}
            />
          </React.Fragment>
        </TextContainer>
      </HorizontalLinearStepper>
    </BaseLayout>
  );
};

GecoPage.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string,
  data: PropTypes.array.isRequired,
  stepperData: PropTypes.object.isRequired,
  updateCheckboxInfoData: PropTypes.func.isRequired,
  toggleAlert: PropTypes.func,
};
export default GecoPage;
