import PropTypes from "prop-types";
import React, { useState } from "react";
import { StyledTextContainer } from "../../containers/text-container/StyledTextContainer";

/**
 * @component
 * @description Component renders Employees interview questions and answers
 * @param  {string} marginLeft left margin
 * @param  {string} marginRight right margin
 * @param  {string} questionText question
 * @param  {string} answer answer
 * @param  {function} onChangeAnswer handler for changing answer
 * @param  {boolean} readOnly is answer editable or not
 */
const InterviewQuestion = ({
  marginLeft,
  marginRight,
  questionText,
  answer,
  onChangeAnswer,
  readOnly,
}) => {
  const [text, setText] = useState(answer);

  const handleChange = (e) => {
    setText(e.target.value);
    onChangeAnswer(e.target.value);
  };
  return (
    <StyledTextContainer
      marginLeft={marginLeft}
      marginRight={marginRight}
      readOnly={readOnly}
    >
      <label>{questionText}</label>
      <textarea
        readOnly={readOnly}
        onChange={(event) => handleChange(event)}
        defaultValue={text}
      />
    </StyledTextContainer>
  );
};

InterviewQuestion.propTypes = {
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  questionText: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  onChangeAnswer: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default InterviewQuestion;
