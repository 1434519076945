import styled from "styled-components";
import { StyledMilestone } from "./StyledMilestone";

export const StyledMilestoneText = styled.div`
  font-size: 20px;
  color: black;
  ${StyledMilestone}:hover & {
    display: block;
    color: white;
  }
  a:hover {
    cursor: pointer;
  }
`;
