import React from "react";
import ReactDOM from "react-dom";
import "./GloablStyles.js";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Helmet } from "react-helmet";
import favicon from "./assests/images/running-man-logo-no-blank-space.png";
import "./i18n";
// import favicon from './assests/images/running-man-logo.png';
import * as serviceWorker from "./serviceWorker";
const app = (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Onboarding App</title>
      <link rel="icon" type="image/png" href={favicon} />
      <meta
        name="description"
        content="Onboarding is the onboarding app for comsysto"
      />
      <meta name="language" content="EN" />
    </Helmet>
    <BrowserRouter initialEntries={["/checkboxes"]}>
      <App />
    </BrowserRouter>
  </>
);
ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
