import styled from "styled-components";
import { breakpoints } from "../../utils/cssBreakpoints.js";

export const StyledUploadButton = styled.div`
  background: rgba(196, 196, 196, 0.15);
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-top: 1em;
  margin-bottom: 0.75em;
  padding: 1em;

  label {
    color: black;
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) =>
      props.labelFontSize ? props.labelFontSize : "0.9em"};
    line-height: 127.2%;
    letter-spacing: -0.025em;
  }

  button,
  input {
    background-color: ${(props) =>
      props.showAsDisabled ? "rgb(255,255,255, 0.5)" : "#FFFFFF"};
    border: 0 solid white;
    height: 7vh;
    width: 100%;
    text-align: start;
    font-style: normal;
    font-size: ${(props) =>
      props.textFontSize ? props.textFontSize : "0.8em"};
    padding-left: 0.5em;
    color: black;
    span {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    button,
    input {
      height: 3em;
      font-size: 0.9em;
    }
    label {
      font-size: 1em;
    }
  }
  @media screen and (min-width: ${breakpoints.xlg}px) {
    button,
    input {
      height: 3em;
      font-size: 1em;
    }
    label {
      font-size: 1.1em;
    }
  }
`;
