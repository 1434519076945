import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./TextEditorStyles.css";
import { convertToRaw, EditorState } from "draft-js";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import PropTypes from "prop-types";

/**
 * @component
 * @description Component renders Text Editor
 * @param  {string} data CheckboxInfo html text
 * @param  {function} handleTextChange handles checkbox text change
 * @param  {number} index index of text editor fragment (in case when child elements are present, they divide text editor into multiple fragments)
 */
const TextEditor = ({ data, handleTextChange, index }) => {
  const blocksFromHtml = htmlToDraft(data);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(contentState)
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    handleTextChange(
      DOMPurify.sanitize(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      ),
      index
    );
  };

  const uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div className="App">
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          fontFamily: {
            options: [
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana",
            ],
          },
          image: {
            uploadEnabled: true,
            previewImage: true,
            alt: { present: true, mandatory: false },
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            uploadCallback: uploadCallback,
          },
        }}
      />
    </div>
  );
};

TextEditor.prototype = {
  data: PropTypes.string,
  handleTextChange: PropTypes.func,
};

export default TextEditor;
