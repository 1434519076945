import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { ScrollContainer } from "../";
import { BaseLayout } from "../../components";
import AddUserInformationForm from "../../components/add-user-information-form/AddUserInformationForm";
import HorizontalLinearStepper from "../../components/stepper/HorizontalLinearStepper";
import putDataToEndPoint from "../../utils/putDataToEndPoint";
import TextContainer from "../../components/text-editor/TextContainer.js";
import { urls } from "../../utils/baseUrl";
import { UserContext } from "../../utils/context/UserContext";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @description Component renders GitHub page
 * @param  {string} to path to next page
 * @param  {string} from  path to previous page
 * @param  {Array} data CheckboxInfo data for the current page
 * @param  {function} toggleAlert toggle alert message
 * @param  {function} updateCheckboxInfoData send updated checkboxInfo data to BE
 */
const GithubPage = ({
  to,
  from,
  data,
  stepperData,
  toggleAlert,
  updateCheckboxInfoData,
}) => {
  const userData = useContext(UserContext);
  const infoType = "githubAccountLink";

  const [inputText, setInputText] = useState("");
  const { t } = useTranslation();

  /**
   * @function
   * @description Gets inserted user information text from input text field and sends that data do BE
   *
   * If response is 401(Unauthorized) it redirects the user to login page
   *
   */
  const addUserInformationHandler = async () => {
    const { errFlag, data } = await putDataToEndPoint(
      urls.userAccountInfoUrl.replace(":userId", userData.id),
      {
        [infoType]: inputText,
      }
    );
    if (errFlag) {
      toggleAlert(t("github-page.error-add"), "error");
      setInputText("");
      if (data === 401) {
        handleUnauthorized();
      }
    } else {
      toggleAlert(t("github-page.success-add"));
    }
  };

  const handleSave = () => addUserInformationHandler();

  return (
    <BaseLayout
      to={to}
      from={from}
      hasTextEditor={true}
      onBack={handleSave}
      onNext={handleSave}
    >
      <HorizontalLinearStepper
        stepTitles={stepperData.titles}
        stepRoutes={stepperData.routes}
        currentStep={data[0].orderOnStepper - 1}
      ></HorizontalLinearStepper>
      <ScrollContainer>
        <TextContainer
          data={data}
          updateCheckboxInfoData={updateCheckboxInfoData}
        />
        <AddUserInformationForm
          title={t("github-page.title")}
          infoType={infoType}
          inputText={inputText}
          setInputText={setInputText}
          addUserInformationHandler={addUserInformationHandler}
          toggleAlert={toggleAlert}
        />
      </ScrollContainer>
    </BaseLayout>
  );
};

GithubPage.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string,
  data: PropTypes.array.isRequired,
  stepperData: PropTypes.object.isRequired,
  updateCheckboxInfoData: PropTypes.func.isRequired,
  toggleAlert: PropTypes.func,
};
export default GithubPage;
